module.exports = {
  messages: {
    ES: {
      // Enviar 
      Enviar: 'Enviar',
      Intercambiar: 'Intercambiar',
      Desde: 'Desde',
      Disponible: 'Disponible',
      Dirección: 'Dirección',
      Requerido: 'El campo es requerido',
      messageAmount: "El monto no puede ser mayor al saldo actual",
      messageAmount2: "Solo se admiten datos numericos",
      messageAddress: "No puede enviar a su mismo usuario",
      messageAddress2: "El usuario deFix3 no existe",
      messageAddress3: "No puede enviar a su misma dirección",
      alertTitulo: "Envio Completado",
      alertTitulo2: "Advertencia",
      alertTitulo3: "Error al Enviar",
      alertAlerta: "Se ha enviado con éxito.",
      alertAlerta2: "Ocurrio un error al enviar la transacción, intente nuevamente",
      'Defix User': 'Usuario deFix3',
      Address: 'Dirección',
      'Fee defix3': 'Tarifa deFix3',
      Fee: 'Tarifa',
      'Total fee': 'Tarifa Total',
      'No cuenta con suficiente ': 'No cuenta con suficiente ',
      ' para cubrir la tarifa': ' para cubrir la tarifa',
      'No cuenta con suficiente ETH para cubrir la tarifa': 'No cuenta con suficiente ETH para cubrir la tarifa',
      'This address is not a valid ': 'Esta dirección, no es una deireccion valida ',
      ' address': '.',

      // intercambio
      Intercambio: 'Intercambio',
      alertTitulo4: "Intercambio Completado",
      alertAlerta3: "Se ha intercambiado con éxito.",
      De: 'De',
      Para: 'Para',
      'Vista previa swap': 'Vista previa swap',
      "Conversión fee": "Tarifa conversión",
      "Solo se permiten valores numericos": "Solo se permiten valores numericos",
      "Debe introducir un monto": "Debe introducir un monto",
      Actualizar: 'Actualizar',
      'dExchange completed successfully.': 'dExchange completado con éxito.',
      Advertencia: 'Advertencia',
      'Su codigo 2fa no es valido': 'Su codigo 2fa no es valido',
      'Error al realizar el swap': 'Error al realizar el swap',
      'Ha ocurrido un error inesperado, vuelva a intentarlo mas tarde': 'Ha ocurrido un error inesperado, vuelva a intentarlo mas tarde',
      messagenogas: 'No cuenta con suficiente ETH para cubrir la tarifa del swap',
      'DEX aggregator protocol optimizing most efficient exchange route.': 'Protocolo de agregador DEX optimizando ruta mas eficiente de intercambio.',
      'Fee discounted from ETH origin balance': 'Fee descontado de balance ETH.',
      // modal user
      SelectUser: 'Seleccionar usuario',
      'Quitar usuario de la lista': 'Quitar usuario de la lista',
      'Esta seguro/a que desea quitar al usuaario de la lista ?': 'Esta seguro/a que desea quitar al usuaario de la lista ?',
      Si: 'Si',
      // modal crypto
      SelectCrypto: 'Seleccione Crypto',
    },
    US: {
      // Enviar 
      Enviar: 'Send',
      Intercambiar: 'dExchange',
      Desde: 'From',
      Disponible: 'Available',
      Dirección: 'Address',
      Requerido: 'Field required',
      messageAmount: "The amount can't be greater than the current balance",
      messageAmount2: "Only numeric data is accepted",
      messageAddress: "You can't send to your own user",
      messageAddress2: "deFix3 user does not exist",
      messageAddress3: "You cannot ship to your same address",
      alertTitulo: "Send Complete",
      alertTitulo2: "Warning",
      alertTitulo3: "Error sending",
      alertAlerta: "It has been sent successfully.",
      alertAlerta2: "An error occurred while sending the transaction, try again",
      'Defix User': 'deFix3 User',
      Address: 'Address',
      'Fee defix3': 'Fee deFix3',
      Fee: 'Fee',
      'Total fee': 'Total Fee',
      'No cuenta con suficiente ': 'You do not have enough ',
      ' para cubrir la tarifa': ' to cover the fee',
      'No cuenta con suficiente ETH para cubrir la tarifa': 'You do not have enough ETH to cover the fee',
      'This address is not a valid ': 'This address is not a valid ',
      ' address': ' address.',
      // intercambio
      Intercambio: 'Exchange',
      'Vista previa swap': 'Swap preview',
      alertTitulo4: "Exchange Completed",
      alertAlerta3: "has been exchanged successfully.",
      De: 'From',
      Para: 'To',
      "Conversión fee": "Conversion fee",
      "Solo se permiten valores numericos": "Only numeric values are allowed",
      "Debe introducir un monto": "You must enter an amount",
      Actualizar: 'Refresh',
      'dExchange completed successfully.': 'dExchange completed successfully.',
      Advertencia: 'Advertencia',
      'Su codigo 2fa no es valido': 'Your 2fa code is not valid',
      'Error al realizar el swap': 'Error performing the swap',
      'Ha ocurrido un error inesperado, vuelva a intentarlo mas tarde': 'An unexpected error has occurred, please try again later',
      messagenogas: 'You do not have enough ETH to cover the swap fee',
      'DEX aggregator protocol optimizing most efficient exchange route.': 'DEX aggregator protocol optimizing most efficient exchange route.',
      'Fee discounted from ETH origin balance': 'Fee discounted from ETH origin balance.',
      // modal user
      SelectUser: 'Select user',
      'Quitar usuario de la lista': 'Remove user from list',
      'Esta seguro/a que desea quitar al usuaario de la lista ?': 'Are you sure you want to remove the user from the list?',
      Si: 'Yes',
      // modal crypto
      SelectCrypto: 'Select Crypto',
      
    }
  }
}
    
<template>
  <section>
    <!-- modal crypto A -->
    <v-navigation-drawer
      id="modalQR"
      :class="{ fix: Fix }"
      v-model="ModalCryptoA"
      :absolute="positionModal"
      :fixed="!positionModal"
      temporary
      overlay-color="black"
      overlay-opacity="0.5"
      color="#FFFFFF"
    >
      <v-col
        cols="10"
        class="containerLineas margintop"
        style="
          margin-inline: auto;
          padding-block: 15px;
          display: flex;
          justify-content: space-between
          align-items: center
        "
      >
        <span class="titulo h6-em">{{ $t("SelectCrypto") }}</span>
        <v-btn icon @click="ModalCryptoA = false">
          <v-icon color="#6667AB" medium>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="10" class="divcol margintop paddlateralheight" style="margin-inline: auto">
        <section class="cardCrypto">
          <v-card
            v-for="(item, index) in dataCrypto"
            v-bind:key="index"
            color="transparent"
            style="margin-block: 12px; display: flex; justify-content: start"
          >
            <div class="containerTokens" @click="selectTokenA(item)">
              <img class="token" :src="item.token" alt="token" />
              <aside class="divcol" style="justify-content: center">
                <span class="desc h8-em">{{ item.title }}</span>
                <span class="h9-em descStyle">{{ item.desc }}</span>
              </aside>
            </div>
          </v-card>
        </section>
      </v-col>
    </v-navigation-drawer>

    <!-- modal crypto B -->
    <v-navigation-drawer
      id="modalQR"
      :class="{ fix: Fix }"
      v-model="ModalCryptoB"
      absolute
      temporary
      overlay-color="black"
      overlay-opacity="0.5"
      color="#FFFFFF"
    >
      <v-col
        cols="10"
        class="containerLineas margintop"
        style="
          margin-inline: auto;
          padding-block: 15px;
          display: flex;
          justify-content: space-between
          align-items: center
        "
      >
        <span class="titulo h6-em">{{ $t("SelectCrypto") }}</span>
        <v-btn icon @click="ModalCryptoB = false">
          <v-icon color="#6667AB" medium>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="10" class="divcol margintop paddlateralheight" style="margin-inline: auto">
        <section class="cardCrypto">
          <v-card
            v-for="(item, index) in dataCrypto"
            v-bind:key="index"
            color="transparent"
            style="margin-block: 12px; display: flex; justify-content: start"
          >
            <div class="containerTokens" @click="selectTokenB(item)">
              <img class="token" :src="item.token" alt="token" />
              <aside class="divcol" style="justify-content: center">
                <span class="desc h8-em">{{ item.title }}</span>
                <span class="h9-em descStyle">{{ item.desc }}</span>
              </aside>
            </div>
          </v-card>
        </section>
      </v-col>
    </v-navigation-drawer>

    <!-- modal crypto C -->
    <v-navigation-drawer
      id="modalQR"
      :class="{ fix: Fix }"
      v-model="ModalCryptoC"
      absolute
      temporary
      overlay-color="black"
      overlay-opacity="0.5"
      color="#FFFFFF"
    >
      <v-col
        cols="10"
        class="containerLineas margintop"
        style="margin-inline: auto;
          padding-block: 15px;
          display: flex;
          justify-content: space-between
          align-items: center
        "
      >
        <span class="titulo h6-em">{{ $t("SelectCrypto") }}</span>
        <v-btn icon @click="ModalCryptoC = false">
          <v-icon color="#6667AB" medium>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="10" class="divcol margintop paddlateralheight" style="margin-inline: auto">
        <section class="cardCrypto">
          <v-card
            v-for="(item, index) in dataCrypto"
            v-bind:key="index"
            color="transparent"
            style="margin-block: 12px; display: flex; justify-content: start"
          >
            <div class="containerTokens" @click="selectTokenC(item)">
              <img class="token" :src="item.token" alt="token" />
              <aside class="divcol" style="justify-content: center">
                <span class="desc h8-em">{{ item.title }} {{ item.red }}</span>
                <span class="h9-em descStyle">{{ item.desc }}</span>
              </aside>
            </div>
          </v-card>
        </section>
      </v-col>
    </v-navigation-drawer>

    <!-- modal red -->
    <!-- <v-navigation-drawer
      id="modalQR"
      :class="{ fix: Fix }"
      v-model="ModalRed"
      absolute
      temporary
      overlay-color="black"
      overlay-opacity="0.5"
      color="#FFFFFF"
    >
      <v-col
        cols="10"
        class="containerLineas margintop"
        style="
          margin-inline: auto;
          padding-block: 15px;
          display: flex;
          justify-content: space-between
          align-items: center
        "
      >
        <div class="center">
          <span class="desc h9-em" style="text-align: center; margin-inline: 10%; line-height: 2ch"
            >Asegurese que la red coincida con la dirección. De lo contrario puede
            perder sus activos</span
          >
        </div>
        <v-btn icon @click="ModalRed = false">
          <v-icon color="#6667AB" medium>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="11" class="paddlateral margintop" style="margin-inline: auto">
        <section class="cardCrypto2">
          <aside
            v-for="(item, index) in dataRed"
            v-bind:key="index"
            style="margin-block: 12px"
            class="containerRed"
            @click="selectRed(item)"
          >
            <span class="desc h7">{{ item.red }}</span>
            <aside class="divrow" style="gap: 5px">
              <span class="h10-em">{{ item.comision }}</span>
              <span class="h10-em">{{ item.tiempo }}</span>
            </aside>
          </aside>
        </section>
      </v-col>
    </v-navigation-drawer> -->
  </section>
</template>

<script>
export default {
  name: "ModalCrypto",
  i18n: require("./i18n"),
  data() {
    return {
      Fix: false,
      ModalCryptoA: false,
      ModalCryptoB: false,
      ModalCryptoC: false,
      ModalRed: false,
      dataCrypto: [],
      positionModal: true,
      /*dataCrypto: [
        {
          token: require("@/assets/app/token/bitcoin.svg"),
          title: "Bitcoin",
          desc: "BTC",
        },
        {
          token: require("@/assets/app/token/ethereum.svg"),
          title: "Ethereum",
          desc: "ETH",
        },
        {
          token: require("@/assets/app/token/solana.svg"),
          title: "Solana",
          desc: "SOL",
        },
        {
          token: require("@/assets/app/token/tron.svg"),
          title: "Tron",
          desc: "TRX",
        },
      ],*/
      dataRed: [
        {
          red: "Ethereum (ERC20)",
          comision: "Comisión 1 ETH (~$ 2,549)",
          tiempo: "Tiempo de llegada = 5 min"
        },
        {
          red: "Solana",
          comision: "Comisión 1 ETH (~$ 2,549)",
          tiempo: "Tiempo de llegada = 5 min"
        },
        {
          red: "Tron",
          comision: "Comisión 1 ETH (~$ 2,549)",
          tiempo: "Tiempo de llegada = 5 min"
        },
        {
          red: "BNB",
          comision: "Comisión 1 ETH (~$ 2,549)",
          tiempo: "Tiempo de llegada = 5 min"
        }
      ]
    };
  },
  mounted() {
    if (window.innerWidth <= 880) {
      this.positionModal = false
    } else {
      this.positionModal = true
    }
  },
  methods: {
    selectTokenA(item) {
      this.$parent.selectTokenA(item);
    },
    selectTokenB(item) {
      this.$parent.selectTokenB(item);
    },
    selectTokenC(item) {
      this.$parent.selectTokenC(item);
    },
    selectRed(item) {
      this.$parent.selectRed(item);
    },
  },
};
</script>

<style src="./Right.scss" lang="scss"></style>
<style scoped>
.containerLineas {
  position: relative;
}
.containerLineas::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
}
</style>

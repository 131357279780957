module.exports = {
  messages: {
    ES: {
      dataLoginWallet: [
        {
          texto: "Cerrar sesión",
          show: false,
        },
        {
          texto: "Registro",
          show: true,
        },
      ],
      correo: "Correo",
      connect_signin: 'Importar billetera',
      bm1:'Activos',
      bm2:'Blockchain ',
      bm3:'Fiat',
      bm4:'Acerca de',
      bm5:'Idioma',
      bm6:'Iniciar sesión',
      closeOnClick: true,
      rule2: "Campo requerido.",
      rule3: "Frase secreta requerida para continuar.",
      importAccount: 'Importar cuenta desde',
      defixIdImportError: "Ocurrio un error al tratar de importar su cuenta, verifique su frase y vuelva a intentarlo.",
      importWalletNearIdError: "Datos incorrectos, verifique su nearId y vuelva a intentarlo.",
      importWalletPhraseError: "Datos incorrectos, verifique su frase y vuelva a intentarlo.",
      writePhrase: 'Frase Secreta',
      'Testnet only': 'Solo red de prueba',


      savePhrase: 'Esto genera una frase secreta de 12 palabras que debe guardarse en un lugar seguro. Esta será su contraseña para acceder a la plataforma y debe almacenarse sin conexión. No comparta con nadie.',
      connect: 'Conectar billetera',
      titleHeader1: 'Monedero multicadena',
      titleHeader2: 'descentralizado en web3',
      textHeader: 'Compre, almacene, intercambie, administre su criptografía, intercambie y conéctese con dApps. '+
      'Otras funciones disponibles, como comercio P2P, nombres de usuario para realizar transacciones fácilmente y mucho más! Interactúe con la tecnología blockchain a través de nuestra interfaz amigable.',
      text: 'Web basada en PC y/o dispositivo móvil.',
      text1: 'Apple próximamente',
      text2:'Android próximamente',
      title2:'Compatibilidad con varias cadenas de bloques',
      bhero:'Registro',
      createAccount: 'crear cuenta desde:',
      copy: 'Copiar',
      warningPhrase: 'Escriba las siguientes palabras en orden y guárdelas en un lugar seguro. ¡Cualquiera que tenga acceso a tu frase también tendrá acceso a tu cuenta! Se le pedirá en el siguiente paso que verifique su frase de contraseña.',
      continue: 'Continuar',
      close: 'Cerrar',
      idnear: 'Introduzca su nombre de usuario deseado',
      accept: 'Registrarse',
      idAccount: 'Nombre de usuario',
      phrase: 'Frase secreta',
      id_usuario: 'ID de usuario',
      defixIdError: "El usuario ya existe, intente de nuevo.",
      rule: "Nombre de usuario requerido para continuar.",
      ruleEmail1: "El correo es requerido",
      ruleEmail2: "El formato de correo es invalido",
      EnvioCorreoOpcional: "Enviar frase a correo electrónico como copia de seguridad (opcional)",
      copyClipBoardError: "*debe copiar las frases para continuar.",
      verifyphrase: 'Verificar frase',
      warningverifyphrase: 'Ingrese la siguiente palabra de su frase de recuperación para completar el proceso.',
      Back: 'Atras',
      word: 'Palabra #',
      wordInputError: "Ingresaste la palabra incorrecta",
    },
    US: {
      dataLoginWallet: [
        {
          texto: "Logout",
          show: false,
        },
        {
          texto: "Register",
          show: true,
        },
      ],
      correo: "Email",
      connect_signin: 'Import wallet',
      bm1:'Assets',
      bm2:'Blockchain',
      bm3:'Fiat',
      bm4:'About',
      bm5:'Language',
      bm6:'Sign in',
      rule2: "Field required.",
      rule3: "Secret phrase required to continue.",
      importAccount: 'Import account from:',
      defixIdImportError: "An error occurred while trying to import your account, please check your phrase and try again.",
      importWalletNearIdError: "Incorrect data, please check your nearId and try again.",
      importWalletPhraseError: "Incorrect data, please check your phrase and try again.",
      writePhrase: 'Secret Phrase',
      'Testnet only': 'Testnet only',


      connect: 'Create wallet',
      savePhrase: 'This generates a 12-word secret phrase that must be kept in a safe place. This will be your password to gain access to the platform and should be stored offline. Do not share with anyone.',
      titleHeader1: 'Decentralized multi-chain',
      titleHeader2: 'wallet on web3',
      textHeader: 'Buy, store, exchange, manage your crypto, trade, and connect with dApps. '+
      'Other features available such as, P2P trading, usernames for easy transacting, and much more! Interact with blockchain technology through our friendly interface.',
      text: 'Web based on PC and/or mobile device.',
      text1:'Apple coming soon',
      text2:'Android coming soon',
      title2:'Multi Blockchain Support',
      bhero:'Register',
      createAccount: 'Create account from:',
      copy: 'Copy',
      warningPhrase: 'Write the following words in order and keep them in a safe place. Anyone who has access to your phrase will also have access to your account! You will be prompted in the next step to verify your passphrase.',
      close: 'Close',
      continue: 'Continue',
      accept: 'Register',
      idnear: 'Enter your desired username',
      idAccount: 'Username',
      phrase: 'Secret phrase',
      id_usuario: 'User ID',
      defixIdError: "User already exists, try again.",
      rule: "Username required to continue.",
      ruleEmail1: "Email is required",
      ruleEmail2: "The email format is invalid",
      EnvioCorreoOpcional: "Send phrase to email as backup (optional)",
      copyClipBoardError: "*you must copy the sentences to continue.",
      verifyphrase: 'Verify phrase',
      warningverifyphrase: 'Enter the next word of your recovery phrase to complete the process.',
      Back: 'Back',
      word: 'Word #',
      wordInputError: "You entered the incorrect word",

    }
  }
}
  
module.exports = {
  messages: {
    ES: {
      savePhrase: 'Este registro genera una frase de 12 palabras que debe guardar en un lugar seguro',
      Próximamente: 'Próximamente',
      Portafolio: 'Portafolio',
      Perfil: 'Ajustes',
      Dapps: 'dApps',
    },
    US: {
      savePhrase: 'This record generates a 12-word phrase that you must keep in a safe place',
      Próximamente: 'Coming soon',
      Portafolio: 'Portfolio',
      Perfil: 'Settings ',
      Dapps: 'dApps'

    }
  }
}

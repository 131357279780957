import { render, staticRenderFns } from "./ModalNotificaciones.vue?vue&type=template&id=1d0e7ca9&scoped=true&"
import script from "./ModalNotificaciones.vue?vue&type=script&lang=js&"
export * from "./ModalNotificaciones.vue?vue&type=script&lang=js&"
import style0 from "../Right/Right.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "./ModalNotificaciones.vue?vue&type=style&index=1&id=1d0e7ca9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d0e7ca9",
  null
  
)

export default component.exports
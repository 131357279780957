<template>
  <section>
    <!-- modal crypto A -->
    <v-navigation-drawer
      id="modalQR"
      :class="{ fix: Fix }"
      v-model="ModalUsers"
      :absolute="positionModal"
      :fixed="!positionModal"
      temporary
      overlay-color="black"
      overlay-opacity="0.5"
      color="#FFFFFF"
    >
      <v-col
        cols="10"
        class="containerLineas margintop"
        style="
          margin-inline: auto;
          padding-block: 15px;
          display: flex;
          justify-content: space-between
          align-items: center
        "
      >
        <span class="titulo h6-em">{{ $t("SelectUser") }}</span>
        <v-btn icon @click="ModalUsers = false">
          <v-icon color="#6667AB" medium>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="10" class="divcol margintop paddlateralheight" style="margin-inline: auto">
        <section class="cardCrypto">
          <v-card
            v-for="(item, index) in dataUsers"
            v-bind:key="index"
            color="transparent"
            style="margin-block: 12px; display: flex; justify-content: start"
          >
            <table width="90%">
              <tr>
                <td width="95%">
                  <div class="containerTokens" @click="selectUsers(item)">
                    <img class="token" :src="item.user" alt="token" />
                    <aside class="divcol" style="justify-content: center">
                      <span class="desc h8-em">{{ item.userDefix }}</span>
                      <!--<span class="h9-em descStyle">{{ item.userDefix }}</span>-->
                    </aside>
                  </div>
                </td>
                <td>
                  <v-icon @click="DeleteUser(item)" color="red">mdi-trash-can-outline</v-icon>
                </td>
              </tr>
            </table>
            
          </v-card>
        </section>
      </v-col>
    </v-navigation-drawer>
    <template>
      <v-dialog
        v-model="dialogDeleteUser"
        persistent
        overlay-opacity="0.6"
        overlay-color="black"
        max-width="550"
      >
        <v-card style="border-radius: 30px !important">
          <v-card-title class="text-h5" style="color: #6667AB">
            {{ $t('Quitar usuario de la lista') }}
          </v-card-title>
          <v-card-text>{{ $t('Esta seguro/a que desea quitar al usuaario de la lista ?') }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="EjecutarDeleteUser(userDeleteId)"
            >
              {{ $t('Si') }}
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="dialogDeleteUser = false"
            >
              No
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalUsers",
  i18n: require("./i18n"),
  data() {
    return {
      Fix: false,
      dialogDeleteUser: false,
      ModalUsers: false,
      dataUsers: [],
      positionModal: true,
      userDeleteId: null,
      /*dataCrypto: [
        {
          token: require("@/assets/app/token/bitcoin.svg"),
          title: "Bitcoin",
          desc: "BTC",
        },
        {
          token: require("@/assets/app/token/ethereum.svg"),
          title: "Ethereum",
          desc: "ETH",
        },
        {
          token: require("@/assets/app/token/solana.svg"),
          title: "Solana",
          desc: "SOL",
        },
        {
          token: require("@/assets/app/token/tron.svg"),
          title: "Tron",
          desc: "TRX",
        },
      ],*/
    };
  },
  mounted() {
    if (window.innerWidth <= 880) {
      this.positionModal = false
    } else {
      this.positionModal = true
    }
  },
  methods: {
    selectUsers(item) {
      this.$parent.selectUsers(item);
    },
    DeleteUser(item){
      this.userDeleteId = item.id;
      this.dialogDeleteUser = true;
    },
    EjecutarDeleteUser(userDeleteId) {
      axios.post(process.env.VUE_APP_API_BASE_URL + "delete-frequent", {id_frequent: parseInt(userDeleteId)})
        .then(() => {
          this.dialogDeleteUser = false;
        }).catch((err) => {
          console.log(err);
          this.dialogDeleteUser = false;
        });
    },  
  },
};
</script>

<style src="./Right.scss" lang="scss"></style>
<style scoped>
.containerLineas {
  position: relative;
}
.containerLineas::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
}
</style>

<template>
  <section id="miPerfil" class="containerCartas">
    <!--ModalLeft ref="menu"></!--ModalLeft-->
        <Alert ref="alert"></Alert>
        <ModalDosFa ref="modaldosfa"></ModalDosFa>
        <ModalNotificaciones ref="modalnotificaciones"></ModalNotificaciones>
        <ModalCrypto ref="modalcrypto"></ModalCrypto>   
        <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center" class="containerTitulo">
          <v-col class="paddvertical divcol">
            <span class="titulo h6-em">{{ $t("MiPerfil") }}</span>
            <span class="desc h7-em">{{ $parent.dataUser.defixId }}</span>
          </v-col>
        </v-col>
      
        <v-card class="cartaInside cartaIE">
          <v-col md="12" lg="10" style="margin-inline: auto">
            <v-card class="containerSwap2  mt-5" style="position: relative">
              <span class="flotingText h8-em">{{ $t("Correo") }}</span>
              <v-text-field v-model="editedItem.email" type="text" background-color="transparent" :rules="[rules.email]"
                hide-details solo flat @keyup="cambios()">
                <template v-slot:label>
                  <span class="h8-em">{{ $t('EjemploCorreo') }}</span>
                </template>
      
                <template v-slot:append>
                  <span id="botonEditar" class="h9-em">
                    <v-icon medium color="#ffff">mdi-email</v-icon>
                  </span>
                </template>
              </v-text-field>
            </v-card>
      
            <v-btn id="boton2Fa" elevation="2" x-small :loading="loading" style="background-color: #6667AB; color: #FFFF; font-weight: bold; font-size: 12.5px !important;
                      border-radius: 30px; padding: .8em; text-transform: none !important" @click="Activar2Fa()">
              <span v-if="dosfa">{{$t('desactivar 2fa')}}</span>
              <span v-else>{{$t('activar 2fa')}}</span>
            </v-btn>
      
            <v-btn elevation="2" x-small class="mr-5" :loading="loading" style="background-color: #6667AB; color: #FFFF; font-weight: bold; float:right;
                    font-size:12.5px !important; border-radius: 30px; padding: .8em; text-transform: none !important"
              @click="OpenNotificaciones()">
              <span style="margin: 0">{{$t("Notificaciones")}}</span>
            </v-btn>
           
            <span class="start mt-5">
              <v-card class="containerSwap2 mt-10" style="position: relative">
                <span class="flotingText h8-em">{{ $t("View private key") }}</span>
                <v-col cols="5" class="paddvertical">
                  <div class="divrow" style="justify-content: space-between; align-items: center; margin-bottom: 5px">
                    <aside id="containerTokens" class="divrow center" @click="OpenCrypto()">
                      <img class="token2" :src="dataCoin.token" alt="token">
                      <span class="desc h8-em" style="margin-left: 5px">{{ dataCoin.title }}</span>
                      <v-icon small>mdi-chevron-down</v-icon>
                    </aside>
                    <v-btn elevation="2" small class="ml-5" :loading="loading" style="background-color: #6667AB; color: #FFFF; font-weight: bold; float:right;
                            font-size:12.5px !important; border-radius: 30px; padding: .8em; text-transform: none !important"
                      @click="dialogKey = true">
                      <span style="margin: 0">{{$t("Ver")}}</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-card>
            </span>
            <v-col class="containerLineas"
              style="width: 80% !important; position: absolute; bottom: 25%; left: 0; right: 0; margin: auto;">
              <v-col class="space" style="justify-content: space-between; align-items: center;gap:1em;flex-wrap:wrap">
                <v-btn style="font-size: clamp(.8, .9vw, .96875em) !important;
                              border-radius: 30px; padding: .8em; text-transform: none !important;margin-inline:auto"
                  @click="AbrirDialogCierre(2)">
                  <span class="h8-em" style="color: #C2185C; font-weight: bold; height:max-content">{{
                    $t("Cerrartodaslassesiones") }}</span>
                  <v-icon color="#6667AB" medium>mdi-chevron-right</v-icon>
                </v-btn>
      
                <v-btn style="font-size: clamp(.8, .9vw, .96875em) !important;
                              border-radius: 30px; padding: .8em; text-transform: none !important;margin-inline:auto"
                  @click="AbrirDialogCierre(1)">
                  <span class="h8-em" style="color: #C2185C; font-weight: bold; height:max-content">{{ $t("CerrarSesión")
                    }}</span>
                  <v-icon color="#6667AB" medium>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-col>
      
            <v-btn class="botonSwap h7-em" :loading="FormCargando" :disabled="FormDisabled" @click="guardarEmail()">
              {{ $t("Guardar") }}
            </v-btn>  
      
            <!-- cambio idioma -->
            <v-select v-model="value" :items="lang" item-value="value" item-text="title" hide-details prepend-icon="language"
              single-line @change="changeLanguaje(value)" class="languageMobile">
              <template v-slot:label>
                <span class="h8-em">{{ $t("Lenguage") }}</span>
              </template>
            </v-select>
          </v-col>
        </v-card>
      
      
        <v-dialog id="eliminarDialogo" v-model="dialogCerrarSesion" persistent hide-overlay max-width="550" content-class="close-sesion-modal">
          <v-card>
            <v-card-title class="text-h5" style="color: #6667AB">
              {{ $t(tituloDialog) }}
            </v-card-title>
            <v-card-text>{{ $t(descDialog) }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="EjecutarCierre()">
                {{ $t('Si') }}
              </v-btn>
              <v-btn color="red darken-1" text @click="dialogCerrarSesion = false">
                No
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--Dilaog view private key-->
        <template>
          <v-dialog id="eliminarDialogo" v-model="dialogKey" persistent overlay-opacity="0.6" overlay-color="black"
            :max-width="responsive" max-height="80%">
            <v-card style="border-radius: 30px !important" class="modalWallet">
              <v-card-title class="text-h5">
                <span id="tituloContainerWallet" class="text-h4" style="color: #6667AB !important">
                  {{ $t("View private key") }}
                </span>
                <v-btn id="colorCloseWallet" style="top: 10px; right: 10px; position: absolute" icon :disabled="FormCargando"
                  @click="closeDialogKey()">
                  <v-icon medium>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-container>
                <v-stepper v-model="e2">
                  <v-stepper-header>
                    <v-stepper-step :complete="e2 > 1" step="1">
                      {{ $t("verifyphrase") }}
                    </v-stepper-step>
      
                    <v-divider></v-divider>
      
                    <v-stepper-step step="2">
                      {{ $t("Private key") }}
                    </v-stepper-step>
                  </v-stepper-header>
      
                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-card class="mb-12">
                        <aside class="containerChangeWallet">
                          <p>
                            {{ $t("phrase") }}
                          </p>
                        </aside>
                        <aside class="mb-12">
                          <v-text-field v-model="defixIdPhrase" :rules="rules" :label="$t('phrase')" :color="ColorValidate"
                            :messages="MessageValidate" :error-messages="phraseError" @blur="validatePhrase()"
                            @keyup="validatePhrase()" outlined required solo></v-text-field>
      
                        </aside>
                      </v-card>
      
                      <v-btn id="buttonStyle" @click="continuarPrivateKey()">
                        {{ $t("continue") }}
                      </v-btn>
      
                      <v-btn id="buttonStyleCancel" class="ml-3" text @click="closeDialogKey()">
                        {{ $t("close") }}
                      </v-btn>
                    </v-stepper-content>
      
                    <v-stepper-content step="2">
                      <v-card class="mb-12">
                        <aside class="containerChangeWallet" style="cursor: pointer">
                          <p id="textoWallet" class="p text-h7">
                            {{ $t("Private key") }} {{ this.dataCoin.title }}
                          </p>
      
                        </aside>
                        <div class="pt-3">
                          <v-text-field v-model="privateKey" :label="$t('Private key')" disabled outlined solo></v-text-field>
                        </div>
                        <v-card class="center">
                          <v-btn id="botonCopiar" class="h8-em" @click="copyToClipboard()">
                            <span>{{ $t("CCopiar") }}</span>
                          </v-btn>
                          <span class="ml-3" style="color:green;" v-show="copiado">{{ $t('Copiado') }}!</span>
                        </v-card>
                      </v-card>
      
                      <v-btn id="buttonStyleCancel" class="ml-3" text @click="closeDialogKey()">
                        {{ $t("close") }}
                      </v-btn>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-container>
            </v-card>
          </v-dialog>
        </template>
      
  </section>
</template>

<script>
import Alert from "@/pages/app/Alert/Alert.vue"
import ModalDosFa from "@/pages/app/ModalDosFa/ModalDosFa.vue"
import ModalNotificaciones from "@/pages/app/Left/ModalNotificaciones.vue"
import ModalCrypto from "@/pages/app/Left/ModalCrypto.vue"
import axios from "axios";
import { i18n } from "@/plugins/i18n";
import {
  mdiAccountPlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiClose,
  mdiPencil,
  mdiDelete,
  mdiTextBoxPlusOutline  ,
  mdiEarthPlus ,
  mdiCashPlus ,
  mdiFolderPlusOutline,
  mdiBankPlus,
  mdiTimerSand ,
  mdiThumbUpOutline,
  mdiFingerprintOff,
  mdiCropFree ,
  } from '@mdi/js'

export default {
  name: "Perfil",
  i18n: require("./i18n"),
   components: {
    Alert,
    ModalDosFa,
    ModalNotificaciones,
    ModalCrypto,
  },
  data() {
    return {
      tituloDialog:'',
      descDialog:'',
      data:[],
      //token: require("@/assets/app/token/usdt.svg"),
     
      item:[],
      id:'',
      tipo:'',
      url:'',
      llaves: {
        accion: "",
        pais: "",
        banco: "",
        crypto: ""
      },
      step:1,
      kycCabeceraId:' ',
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiAccountPlus,
        mdiClose,
        mdiPencil,
        mdiDelete,
        mdiTextBoxPlusOutline  ,
        mdiEarthPlus ,
        mdiCashPlus ,
        mdiFolderPlusOutline,
        mdiBankPlus,
        mdiTimerSand ,
        mdiThumbUpOutline,
        mdiFingerprintOff,
        mdiCropFree ,
      },
      messages: 0,
      hideDetails: true,
      margen: true,
      email: null,
      editedItemPerfil: {TypeDocument: 'v'},
      editedItem: {email: null},
      messageError: null,
      FormCargando: false,
      loading: false,
      itemsTypeDocument: ['v', 'j'],
      TypeDocument: 'v',
      FormDisabled: true,
      dataCoin: {},
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t("EmailValido")
        },
      },
      Fix:true,
      dosfa: false,
      dialogCerrarSesion: false,
      dialogKey: false,
      dialogKyc: false,
      responsive: "60%",
      privateKey: null,
      phraseError: null,
      ColorValidate: null,
      MessageValidate: null,
      defixIdPhrase: null,
      e2: 1,
      copiado: false,
      lang: [
        {
          title: "English",
          to: "#",
          value: "US",
        },
        {
          title: "Español",
          to: "#",
          value: "ES",
        },
      ],
    }
  },
  mounted() {
    this.Responsive()
    window.onresize = ()=>this.Responsive();
    this.cargarPerfil();
    this.dataCoin = this.$parent.dataCartera[0];
    this.changeLanguaje(localStorage.language);
  },
  methods: {
    changeLanguaje(lang) {
      if (lang === "ES") {
        localStorage.setItem('language', lang)
        //localStorage.language = lang;
        i18n.locale = lang;
      } else {
        localStorage.setItem('language', lang)
        //localStorage.language = lang;
        i18n.locale = lang;
      }
    },
    Responsive() {
      if (window.innerWidth <= 880) {
        this.responsive = "100%"
      } else {
        this.responsive = "60%"
      }
    },
    cambios(){
      this.FormDisabled = false;
    },
    OpenNotificaciones() {
      this.$refs.modalnotificaciones.Notificaciones = true;
      this.$refs.modalnotificaciones.Fix = true;
      setTimeout(() => {
        this.$refs.modalnotificaciones.Fix = false;
      }, 1000);
    },
    OpenModalDosFa(accion) {
      this.$refs.modaldosfa.ModalDosfa = true;
      this.$refs.modaldosfa.Fix = true;
      this.$refs.modaldosfa.accion = accion;
      this.$refs.modaldosfa.code = null;
      setTimeout(() => {
        this.$refs.modaldosfa.Fix = false;
      }, 1000);
    },
    OpenCrypto(){
      this.$refs.modalcrypto.Fix = true;
      this.$refs.modalcrypto.ModalCryptoC = true;
      setTimeout(() => {
        this.$refs.modalcrypto.Fix = false;
      }, 1000);
    },

    selectTokenC(item) {
      this.dataCoin = this.$parent.dataCartera.filter(data =>  data.title === item.title)[0];
      this.$refs.modalcrypto.ModalCryptoC = false;
    },
    validatePhrase(){
      let data = JSON.parse(localStorage.getItem('dataUser'));

      if (this.defixIdPhrase == null) {
        //this.defixIdHin = "Example.defix3";
        this.phraseError = null;
        this.phraseError = this.$t("Frase secreta requerida para continuar.");
        return false;
      } else if (this.defixIdPhrase.trim() == "" || this.defixIdPhrase.length == 0) {
        //this.defixIdHin = "Example.defix3";
        this.phraseError = null;
        this.phraseError = this.$t("Frase secreta requerida para continuar.");
        return false;
      } else {
        if(this.defixIdPhrase.trim() == data.mnemonic.trim()) {
          //this.nearidAvalible = true;
          //this.defixIdHin = defixId;
          this.MessageValidate = this.$t("La frase es correcta");
          this.ColorValidate = "success";
          this.phraseError = false;
          return true;
        } else {
          //this.nearidAvalible = false;
          //this.defixIdHin = defixId;
          this.phraseError = null;
          this.phraseError = this.$t("La frase secreta es incorrecta");
          return false;
        }
      }
    },
    continuarPrivateKey(){
      if(this.validatePhrase()){
        let data = JSON.parse(localStorage.getItem('dataUser'));
        this.e2 = 2;
        this.privateKey = data[this.dataCoin.title.toLowerCase() + '_credentials'].privateKey;
      }
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.privateKey);
      this.copiado = true;
    },
    closeDialogKey(){
      this.dialogKey = false;
      this.e2 = 1;
      this.copiado = false;
      this.defixIdPhrase = null;
      this.privateKey = null;
      this.phraseError = null;
      this.MessageValidate = null;
    },
    guardarEmail() {
      //let rul_requid = !!this.email || this.$t("EmailRequerido");
      let rul_format = false;
      if(this.editedItem.email == null || this.editedItem.email.trim() == '') {
        this.editedItem = {email: null};
      } else {
        rul_format = /.+@.+\..+/.test(this.editedItem.email) || this.$t("EmailValido");
      }
      //let valid_document = true;
      //if(this.editedItemPerfil.documento.length == 0) this.editedItemPerfil.documento = null;
      /*if(this.editedItemPerfil.documento != null && this.editedItemPerfil.TypeDocument == null) {
        valid_document = false;
        this.$refs.alert.alertWarning = true;
        this.$refs.alert.Titulo = this.$t("Advertencia");
        this.$refs.alert.Alerta = this.$t("debe seleccionar un tipo de documento !!!");

      }*/
      //if(rul_requid == true && rul_format == true) {
      //if((rul_format == true || this.editedItem.email == null) && valid_document == true) {
      if(rul_format == true || this.editedItem.email == null) {
        this.FormCargando = true;
        axios.post(process.env.VUE_APP_API_BASE_URL + "status-2fa", {defixId: this.$parent.dataUser.defixId})
          .then((response) => {
            switch (response.data) {
              case true: {
                this.FormCargando = false;
                this.OpenModalDosFa("guardarPerfil");
              }
                break;
              case false: this.EjecutarguardarEmail();
                break;
              default: {
                this.$refs.alert.alertCancelled = true;
                this.$refs.alert.Titulo = this.$t("Error");
                this.$refs.alert.Alerta = this.$t("Ocurrio un error inesperado por favor vuelva a intentarlo mas tarde !!!");
                this.FormCargando = false;
              }
                break;
            }
          })
      } else {
        //if(rul_requid == true) {
        this.messageError = rul_format;
        /*} else {
          this.messageError = rul_requid;
        }*/
      }
    },
    EjecutarguardarEmail(code) {
      let data = {
        defixId: this.$parent.dataUser.defixId,
        mnemonic: this.$parent.dataUser.mnemonic,
        name: this.editedItemPerfil.nombre ? this.editedItemPerfil.nombre : null,
        last_name: this.editedItemPerfil.apellido ? this.editedItemPerfil.apellido : null,
        legal_document: this.editedItemPerfil.documento ? this.editedItemPerfil.documento : null,
        type_document: this.editedItemPerfil.documento ? this.editedItemPerfil.TypeDocument : null,
        email: this.editedItem.email,
        flag_send: this.editedItem.flag_send ? true : false,
        flag_receive: this.editedItem.flag_receive ? true : false,
        flag_dex: this.editedItem.flag_dex ? true : false,
        flag_fiat: this.editedItem.flag_fiat ? true : false,
        code: code ? code : null,
      }
      this.messageError = null
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "set-email-data", data)
        .then((response) => {
          switch (response.data.respuesta) {
            case "ok": {
              localStorage.email=this.editedItem.email
              this.cargarPerfil();
              this.FormDisabled = true;
              this.$refs.alert.alertSuccess = true;
              this.$refs.alert.Titulo = this.$t("alertTitulo");
              this.$refs.alert.Alerta = this.$t("alertAlerta");
              this.$refs.modaldosfa.ModalDosfa = false;
            }
              break;
            case "code": {
              this.$refs.alert.alertWarning = true;
              this.$refs.alert.Titulo = this.$t("Advertencia");
              this.$refs.alert.Alerta = this.$t("Su codigo 2fa no es valido");
            }
              break;
          } 
          this.FormCargando = false;
          this.$refs.modaldosfa.loading = false;
        })
        .catch(() => {
          this.$refs.alert.alertCancelled = true;
          this.$refs.alert.Titulo = this.$t("alertTitulo2");
          this.$refs.alert.Alerta = this.$t("alertAlerta2");
          this.FormCargando = false;
          this.$refs.modaldosfa.loading = false;
        });       
    },
    cargarPerfil() {
      return axios
        .post(process.env.VUE_APP_API_BASE_URL + "get-email-data", {defixId: JSON.parse(localStorage.getItem('dataUser')).defixId})
        .then((response) => {
          if (response.data) {
            let type_document = 'v';
            if(type_document != null) {
              type_document = response.data.type_document
            }
            this.email = response.data.email == null ? "" : response.data.email;
            localStorage.email = this.email
            this.editedItem.email = /*this.$parent.$refs.perfil.dataItems.email =*/ response.data.email == null ? "" : response.data.email;
            this.editedItemPerfil.nombre = /*this.$parent.$refs.perfil.dataItems.nombre =*/ response.data.name == null ? "" : response.data.name,
            this.editedItemPerfil.apellido = /*this.$parent.$refs.perfil.dataItems.apellido =*/ response.data.last_name == null ? "" : response.data.last_name,
            //this.$parent.$refs.perfil.dataItems.documento = response.data.legal_document == null ? "" : response.data.type_document + '-' + response.data.legal_document,
            this.editedItemPerfil.documento = response.data.legal_document,
            this.editedItemPerfil.TypeDocument = type_document,
            this.editedItem.flag_send = /*this.$parent.$refs.perfil.editedItem.flag_send =*/ response.data.flag_send;
            this.editedItem.flag_receive = /*this.$parent.$refs.perfil.editedItem.flag_receive =*/ response.data.flag_receive;
            this.editedItem.flag_dex = /*this.$parent.$refs.perfil.editedItem.flag_dex =*/ response.data.flag_dex;
            this.editedItem.flag_fiat = /*this.$parent.$refs.perfil.editedItem.flag_fiat =*/ response.data.flag_fiat;
            this.dosfa = response.data.dosfa;

            this.$parent.$refs.fiat.emaillogin.email  = this.email
            
            return response;
          }
        })
        .catch((error) => {
          console.log(error);
          this.email = null;
          this.editedItem = {};
          return error;
        });
    },
    async Activar2Fa() {
      if(this.dosfa) {
        this.loading = true;
        axios.post(process.env.VUE_APP_API_BASE_URL + "status-2fa", {defixId: this.$parent.dataUser.defixId})
          .then((response) => {
            switch (response.data) {
              case true: {
                this.OpenModalDosFa("desactivar-2fa");
                this.loading = false;
              }
                break;
              default: {
                this.$refs.alert.alertCancelled = true;
                this.$refs.alert.Titulo = this.$t("Error");
                this.$refs.alert.Alerta = this.$t("Ocurrio un error inesperado por favor vuelva a intentarlo mas tarde !!!");
                this.loading = false;
              }
                break;
            }
          })
      } else {
        let data = {
          defixId: this.$parent.dataUser.defixId,
          mnemonic: this.$parent.dataUser.mnemonic,
        }
        axios.post(process.env.VUE_APP_API_BASE_URL + "generar-2fa", data)
        .then((response) => {
          switch (response.data.respuesta) {
            case "ok": {
              this.$parent.$refs.dosfa.qr = response.data.qr
              this.$parent.$refs.dosfa.codigo = response.data.codigo
              this.ir2fa();
            }
                break;
            case "dosfa": {
                this.$refs.alert.alertWarning = true;
                this.$refs.alert.Titulo = this.$t("Advertencia");
                this.$refs.alert.Alerta = this.$t("Su 2fa ya esta activo");
              }
                break;
            default: {
                this.$refs.alert.alertCancelled = true;
                this.$refs.alert.Titulo = this.$t("Error");
                this.$refs.alert.Alerta = this.$t("Ocurrio un error inesperado por favor vuelva a intentarlo mas tarde !!!");
              }
                break;
          }
          this.$refs.modaldosfa.loading = false;
        })
        .catch(() => {
          this.$refs.alert.alertCancelled = true;
          this.$refs.alert.Titulo = this.$t("Error");
          this.$refs.alert.Alerta = this.$t("Ocurrio un error inesperado por favor vuelva a intentarlo mas tarde !!!");
          this.$refs.modaldosfa.loading = false;
        });
      }
      
    },
    desactivar_2fa(code) {
      
      let data = {
        defixId: this.$parent.dataUser.defixId,
        code: code,
      }
      axios.post(process.env.VUE_APP_API_BASE_URL + "desactivar-2fa", data)
        .then((response) => {
          switch (response.data.respuesta) {
            case "ok": {
              this.dosfa = false;
              this.$refs.alert.alertSuccess = true;
              this.$refs.alert.Titulo = this.$t("Exito");
              this.$refs.alert.Alerta = this.$t("Su 2fa ha sido desactivado");
              this.$refs.modaldosfa.ModalDosfa = false;
            }
                break;
            case "code": {
              this.$refs.alert.alertWarning = true;
              this.$refs.alert.Titulo = this.$t("Advertencia");
              this.$refs.alert.Alerta = this.$t("Su codigo 2fa no es valido");
            }
              break;
            default: {
              this.$refs.alert.alertCancelled = true;
              this.$refs.alert.Titulo = this.$t("Error");
              this.$refs.alert.Alerta = this.$t("Ocurrio un error inesperado por favor vuelva a intentarlo mas tarde !!!");
            }
                break;
          }
          this.$refs.modaldosfa.loading = false;
        })
        .catch(() => {
          this.$refs.alert.alertCancelled = true;
          this.$refs.alert.Titulo = this.$t("Error");
          this.$refs.alert.Alerta = this.$t("Ocurrio un error inesperado por favor vuelva a intentarlo mas tarde !!!");
          this.$refs.modaldosfa.loading = false;
        });
    },
    ir2fa() {
      this.clearCard()
      if (window.innerWidth <= 880) {
        //this.$store.dispatch("ClearNavigation")
        this.$parent.Perfil = false;
        this.$parent.dosfa = true;
      } else {
        //const item = {key: "perfil"}
        //this.$store.dispatch("Navigation", {item})
        this.$parent.Perfil = false;
        this.$parent.dosfa = true;
      }
    },

    clearCard() {
      this.$parent.Dapps = false;
      this.$parent.Historico = false;
      this.$parent.Fiat = false;
      this.$parent.EstadoTransaccion = false;
      this.$parent.Perfil = false;
      this.$parent.EditarPerfil = false;
      this.$parent.Cartera = false;
      this.$parent.Portafolio = false;
      this.$parent.ExchangeCard = false;
    },
    volverAtras() {
      this.clearCard()
      if (window.innerWidth <= 880) {
        this.$store.commit("ClearNavigation")
        this.$parent.Perfil = true;
        //
        this.$parent.leftCard = true;
        this.$parent.centerCard = false;
        this.$parent.rightCard = false;
      } else {
        this.$parent.Perfil = true;
        //
        this.$parent.leftCard = true;
        this.$parent.centerCard = false;
        this.$parent.rightCard = false;
      }
    },
    AbrirDialogCierre(tipo) {
      this.tipoCierre = tipo;
      switch (tipo) {
        case 1: {
          this.tituloDialog = "CerrarSesión";
          this.descDialog = "Esta seguro/a que desea cerrar esta sesiones ?."
        }
          break;
        case 2: {
          this.tituloDialog = "Cerrartodaslassesiones";
          this.descDialog = "Esta seguro/a que desea cerrar todas las sesiones ?."
        }
          break;
      }
      this.dialogCerrarSesion = true;
    },  
    EjecutarCierre(){
      switch (this.tipoCierre) {
        case 1:
          this.cerrarSesion();
          break;
        case 2:
          this.cerrarTodo();
          break;
      }
    },
    cerrarSesion() {
      // localStorage.getItem('dataUser')
      localStorage.clear();
      this.$router.push('/')
    },
    cerrarTodo() {
      let data = {
        defixId: JSON.parse(localStorage.getItem('dataUser')).defixId,
        mnemonic: JSON.parse(localStorage.getItem('dataUser')).mnemonic
      }
      axios.post(process.env.VUE_APP_API_BASE_URL + "close-all-sessions", data)
      .then(() => {
        localStorage.clear();
        this.$router.push('/')
      })
      .catch((error) => {console.log(error)});
    }
  }
};
</script>

<style src="./Left.scss" lang="scss"></style>

<template>
  <section class="containerCartas">
    <v-row>
      <v-col cols="12" md="12" lg="12" style="margin-inline: auto; padding: 7% 0% 0% 6%;" class="divcol">
        <span class="h4-em" style="color:#6667AB">{{ $t("Próximamente") }}!</span>
      </v-col>
      <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
        <div class="divrow" style="padding: 20% 8% 0% 8%; justify-content: space-between; align-items: center">
          <p>{{ $t('Estamos trabajando en las nuevas funcionalidades, está atento a nuestro servidor Discord para que te enteres de las noticias, eventos y mucho más!') }}</p>
        </div>
        <div style="padding: 0% 0% 0% 8%; align-items: center">
          <a href="https://discord.gg/bbhW42NV" target="_blank"><img class="token" :src="imgdiscord" /></a>
        </div>
      </v-col>
    </v-row>
    <!--<v-col cols="11" md="11" lg="10" style="justify-content: space-between" class="containerTitulo">
      <v-col class="paddvertical divcol">
        <span class="titulo h6-em">Dapps</span>-->
      <!--</v-col>-->
    <!--</v-col>

    <v-card class="cartaInside cartaIE">
      <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
        <div class="divrow" style="justify-content: space-between; align-items: center">
          <span class="titulo h6-em paddtop paddbottom">Links</span>
          <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB"
          @click="volverAtras()">
            <v-icon medium>mdi-arrow-left-thin</v-icon>
          </v-btn>
        </div>-->
        <!--<span class="desc h8-em">Ordenado por: Popularidad</span>-->
      <!--</v-col>

      <v-col cols="11" sm="12" md="12" lg="11" class="cardHistorico cardH1" style="margin-inline: auto;">
        <v-col v-for="(item, index) in dataDapps" v-bind:key="index"
          class="containerSwap divrow" style="margin-bottom: 20px !important; align-items: center; gap: 10px">
            <img class="token" :src="item.logo" alt="logo">
            <aside class="divcol">
              <span class="h7-em">{{ item.title }}</span>
              <a :href="item.url" target="_blank" id="h7-5" class="desc h8-em">{{ item.url }}</a>
            </aside>
        </v-col>
      </v-col>
    </v-card>-->
  </section>
</template>

<script>
export default {
  name: "Historico",
  i18n: require("./i18n"),
  mounted() {
    if (window.innerWidth <= 880) {
      this.user = true
    }
  },
  data() {
    return {
      user: false,
      messages: 0,
      dataDapps: [
        {
          logo: require("@/assets/app/Dapps/dapp1.png"),
          title: "PancakeSwap",
          url: "https://pancakeswap.finance/",
        },
        {
          logo: require("@/assets/app/Dapps/dapp2.png"),
          title: "Uniswap",
          url: "https://app.uniswap.org/#/swap?chain=mainnet",
        },
        {
          logo: require("@/assets/app/Dapps/dapp1.png"),
          title: "PancakeSwap",
          url: "https://pancakeswap.finance/",
        }
      ],
      imgdiscord: require('@/assets/app/discord.png'),
    };
  },
  methods: {
    clearCard() {
      this.$parent.Dapps = false;
      this.$parent.Historico = false;
      this.$parent.Fiat = false;
      this.$parent.EstadoTransaccion = false;
      this.$parent.Perfil = false;
      this.$parent.Cartera = false;
      this.$parent.Portafolio = false;
      this.$parent.ExchangeCard = false;
    },
    volverAtras() {
      this.clearCard()
      if (window.innerWidth <= 880) {
        this.$store.commit("ClearNavigation")
        this.$parent.Cartera = true;
        //
        this.$parent.leftCard = true;
        this.$parent.centerCard = false;
        this.$parent.rightCard = false;
      } else {
        const item = {key: "portafolio"}
        this.$store.dispatch("Navigation", {item})
        this.$parent.Cartera = true;
        this.$parent.Portafolio = true;
        this.$parent.ExchangeCard = true;
        this.$parent.Exchange = false;
        //
        this.$parent.leftCard = true;
        this.$parent.centerCard = true;
        this.$parent.rightCard = true;
      }
    },
  }
};
</script>

<style src="./Left.scss" lang="scss"></style>

<template>
  <section>
    <v-menu
      :close-on-click="true"
      activator=".moreBtn"
      offset-y offset-x
      top
    >
      <v-list id="modalHeader" color="#FFFFFF">
        <v-list-item v-for="(item,i) in $store.state.dataMore" :key="i"
          :class="{ navbarButtonActive: item.color }"
          @click="Navigation(item)">
          <v-list-item-title>
            <v-icon large>{{ item.icon }}</v-icon>
            <span>{{ item.title }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      :close-on-click="true"
      activator=".moreBtn2"
      offset-x top
    >
      <v-list id="modalHeader" color="#FFFFFF">
        <v-list-item v-for="(item,i) in $store.state.dataMore" :key="i"
          :class="{ navbarButtonActive: item.color }"
          @click="Navigation(item)">
          <v-list-item-title>
            <v-icon x-large>{{ item.icon }}</v-icon>
            <span class="h7-em">{{ item.title }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </section>
</template>

<script>
export default {
  name: "ModalHeader",
  i18n: require("./i18n"),
  mounted() {
  },
  data() {
    return {
    };
  },
  methods: {
    Navigation(item) {
      if (window.innerWidth<=880) {this.$parent.handleButtonMobile(item)}
      else {this.$parent.handleButton(item)}
    }
  },
};
</script>

<style src="./Header.scss" lang="scss"></style>

module.exports = {
  messages: {
    ES: {
      // portafolio
      Ptitulo:'Mercados', 
      Phistorial: 'Historial Transacciones',
      Pdia: 'Día',
      PSemana: 'Semana',
      PMes: 'Mes',
      PAno: 'Año',
      // historico
      Htitulo: 'Histórico de transacciones',
      HActividad: 'Actividad',
      Moneda: 'Moneda',
      Descargar: 'Descargar',
      Todos: 'Todos',
      Todas: 'Todas',
      enero: 'Enero',
      febrero: 'Febrero',
      marzo: 'Marzo',
      abril: 'Abril',
      mayo: 'Mayo',
      junio: 'Junio',
      julio: 'Julio',
      agosto: 'Agosto',
      septiembre: 'Septiembre',
      octubre: 'Octubre',
      noviembre: 'Noviembre',
      diciembre: 'Diciembre',
      todo: 'todo',
      envio: 'envío',
      recibido: 'Recibido',
      Enviado: 'Enviado',
      Recibido: 'Recibido',
      confirmado: 'confirmado',
      Explorador: 'Explorador',
      // perfil
      MiPerfil: 'Mi Perfil',
      DocumentosKYC: 'KYC',
      CuentaBancoKYC: 'Detalles de datos bancarios',
      Notificaciones: 'Notificaciones',
      AlRecibir: 'Al recibir',
      AlEnviar: 'Al enviar',
      AlRealizarDexchange: 'DEX',
      AlRealizarFiat: 'Intercambio de fiat',
      CerrarSesión: 'Cerrar sesión',
      Cerrartodaslassesiones: 'Cerrar todas las sesiones',
      Guardar: 'Guardar',
      Correo: 'Correo',
      Datos: 'Datos',
      EmailRequerido: 'E-mail es requerido',
      EmailValido: 'E-mail no es válido',
      alertTitulo: 'Guardado',
      alertTitulo2: 'Error al guardar',
      alertAlerta: 'Se han guardado con éxito los cambios',
      alertAlerta2: 'Ocurrió un error al guardar, intente nuevamente',
      EjemploCorreo: 'Ejemplo@defix3.com',
      'Datos Perfil': 'Datos Perfil',
      Nombre: 'Nombre',
      Apellido: 'Apellido',
      Documento: 'Documento',
      'desactivar 2fa': 'Desactivar 2FA',
      'activar 2fa': 'Activar 2FA',
      Advertencia: 'Advertencia',
      'Su 2fa ya esta activo': 'Su 2fa ya esta activo',
      Error: 'Error',
      'Ocurrio un error inesperado por favor vuelva a intentarlo mas tarde !!!': 'Ocurrió un error inesperado por favor vuelva a intentarlo mas tarde !!!',
      Exito: 'Éxito',
      'Su 2fa ha sido desactivado': 'Su 2fa ha sido desactivado',
      'Su codigo 2fa no es valido': 'Su codigo 2fa no es válido',
      footer: 'defiX3, 2022. Versión aplicación 1.0.0',
      Lenguage: 'Idioma',
      'debe seleccionar un tipo de documento !!!': 'debe seleccionar un tipo de documento !!!',
      "Esta seguro/a que desea cerrar esta sesiones ?.": "Esta seguro/a que desea cerrar esta sesión?",
      "Esta seguro/a que desea cerrar todas las sesiones ?.": "Esta seguro/a que desea cerrar todas las sesiones?",
      Si: 'Si',
      phrase: 'Frase secreta',
      verifyphrase: 'Verificar frase',
      continue: 'Continuar',
      close: 'Cerrar',
      'View private key': 'Ver llave privada',
      Ver: 'Ver',
      'Private key': 'Llave privada',
      CCopiar: 'Copiar',
      Copiado: 'Copiado',
      'La frase es correcta': 'La frase es correcta',
      'La frase secreta es incorrecta': 'La frase secreta es incorrecta',
      'Field required': 'Campo requerido',
      'Frase secreta requerida para continuar.': 'Frase secreta requerida para continuar.',

      // 2fa
      'Activacion 2FA': 'Activacion 2FA',
      Llave: 'Llave',
      'Si no tiene la App, puede descargarla aqui': 'Si no tiene la App, puede descargarla aqui',
      'Escanee el código QR con Google authenticator, luego ingrese el código que le aparece para': 'Escanee el código QR con Google authenticator, luego ingrese el código que le aparece para ',
      Activar: 'Activar',
      Codigo: 'Codigo',
      'Se activo con exito el 2FA': 'Se activo con éxito el 2FA',
      // fiat
      'Balance Total': 'Balance Total',

      'Selecciona el País': 'Selecciona el País',
      'Ordenado por: Popularidad': 'Ordenado por: Popularidad',
      'Selecciona una Acción': 'Selecciona una Acción',
      Comprar: 'Comprar',
      Vender: 'Vender',
      Bancos: 'Bancos',
      Banco: 'Banco',
      'Bancos disponibles': 'Bancos disponibles',
      'Selecciona el Banco': 'Selecciona el Banco',
      Frecuentes: 'Frecuentes',
      'Tasa por': 'Tasa por',
      Otros: 'Otros',
      'Fiat': 'FIAT',
      'Selecciona la criptomoneda': 'Selecciona la criptomoneda',
      Compra: 'Compra',
      'Monto a Depositar': 'Monto a Depositar',
      'Cantidad en criptomoneda': 'Cantidad en criptomoneda',
      Siguiente: 'Siguiente',
      'Cuenta Bancaria': 'Cuenta Bancaria',
      Selecciona: 'Selecciona',
      'Selecciona la cuenta': 'Selecciona la cuenta',
      'desde donde envias': 'desde donde envias',
      'Agregar Cuenta (Envio)': 'Agregar Cuenta (Envio)',
      'Agregar Cuenta': 'Agregar Cuenta',
      'Crear Cuenta': 'Crear Cuenta',
      Envio: 'Envio',
      'Coloque los datos como aparece en la identificación bancaria': 'Coloque los datos como aparece en la identificación bancaria',
      'Nombre completo del titulo': 'Nombre completo del titulo',
      'De no coincidir con el titular de la cuenta se reembolsaran los fondos': 'De no coincidir con el titular de la cuenta se reembolsaran los fondos',
      'Vista previa': 'Vista previa',
      'Confirma tu Compra': 'Confirma tu Compra',
      'Cripto a recibir': 'Cripto a recibir',
      'Total a recibir': 'Total a recibir',
      'Método de Pago': 'Método de Pago',
      'Nombre Titular': 'Nombre Titular',
      'Confirmar': 'Confirmar',
      'Id Titular':'Id Titular',
      'Telefono Titular':'Telefono Titular',
      'Nro. Cuenta': 'Nro. Cuenta',
      'Realiza el pago':'Realiza el pago',
      Transfiere: 'Transfiere',
      'Transfiere a la siguiente cuenta e ingresa el número de referencia': 'Transfiere a la siguiente cuenta e ingresa el número de referencia',
      'Monto a transferir': 'Monto a transferir',
      'Transferir a': 'Transferir a',
      Desde: 'Desde',
      'N° Cuenta': 'N° Cuenta',
      'Tipo de Cuenta': 'Tipo de Cuenta',
      Corriente: 'Corriente',
      Identificación: 'Identificación',
      'Transfiere dentro del tiempo establecido, de no ser así se cancelarala': 'Transfiere dentro del tiempo establecido, de no ser así se cancelarala',
      'transacción automáticamente': 'transacción automáticamente',
      'inicia sesión en tu cuenta de pago, realiza la transferencia': 'inicia sesión en tu cuenta de pago, realiza la transferencia',
      'coloca el número de referencia y confirma': 'coloca el número de referencia y confirma',
      Cancelar: 'Cancelar',
      Venta: 'Venta',
      'Cantidad en Fiat': 'Cantidad en Fiat',
      Cantidad:'Cantidad',
      'Monto a Vender': 'Monto a Vender',
      'Agregar Cuenta (Recibir)': 'Agregar Cuenta (Recibir)',
      Recibir: 'Recibir',
      'Confirma tu Venta': 'Confirma tu Venta',
      'Fiat a recibir': 'Fiat a recibir',
      'A cuenta': 'A cuenta',
      
      'Tu orden está en proceso': 'Tu orden está en proceso',
      'Puedes verificar el estado del proceso y sus detalles en la sección de Historial de Transacciones': 'Puedes verificar el estado del proceso y sus detalles en la sección de Historial de Transacciones',
      Entendido: 'Entendido',
      'Ver estado': 'Ver estado',
      "Tu orden ha sido cancelada": 'Tu orden ha sido cancelada',
      Próximamente: 'Próximamente',
      "Estamos trabajando en las nuevas funcionalidades, está atento a nuestro servidor Discord para que te enteres de las noticias, eventos y mucho más!": "Estamos trabajando en las nuevas funcionalidades, está atento a nuestro servidor Discord para que te enteres de las noticias, eventos y mucho más!",
      'Cuentas donde te depositaran tus Ventas': 'Cuentas donde te depositaran tus Ventas',
      "Cuentas donde pagaras tus Compras":"Cuentas donde pagarás tus Compras",
      "Historial de Transacciones":"Historial FIAT",
      "Detalle de la Transaccion":"Detalle de la Transacción",
      "Archivo Adjunto":"Archivo Adjunto",
      MontoaPagar:'Monto a Pagar',
      TotalaPagar:'Total a Pagar',
      comision:'Comisión',
      // modal fiat
      País:'País',
      Descripcion:'Descripción',
      Estatus: 'Estatus',
      Pagos:'Pagos',

      "Proceso de Revision":"Proceso de Revisión",
      "KYC Aprobado":"KYC Aprobado",
      "Carge sus datos para validar KYC":"Carge sus datos para validar KYC",
      "KYC NO aprobado":"KYC NO aprobado",
      Login:'ACCESAR',
      Natural:'Natural',
      Juridico:'Jurídico',
      Accesa:'ACCESAR',
      CreaClave:'Crea tu contraseña de ingreso al módulo FIAT.',
      'Falta eMail. debe agregarlo en la opción de perfil.':'Falta eMail. debe agregarlo en la opción de perfil.',
      'Edición de Cuenta FIAT por País':'Edición de Cuenta FIAT por País',
      'Bienvenidos a la opcion de FIAT. Le invitamos a gestionar el proceso de KYC para su Pais. Para ello puede dar clic aqui en el boton con la etiqueta KYC':'Bienvenidos a la opción de FIAT. Le invitamos a gestionar el proceso de KYC para su País. Para ello puede dar clic aquí en el botón con la etiqueta KYC.',
      


    },
    US: {
      // portafolio
      Ptitulo:'Markets',
      Phistorial: 'Transaction History',
      Pdia: 'Day',
      PSemana: 'Week',
      PMes: 'Month',
      PAno: 'Year',
      // historico
      Htitulo: 'Transaction history',
      HActividad: 'Activity',
      Moneda: 'Currency',
      Descargar: 'Download',
      Todos: 'All',
      Todas: 'All',
      enero: 'January',
      febrero: 'Febrero',
      marzo: 'March',
      abril: 'April',
      mayo: 'May',
      junio: 'June',
      julio: 'July',
      agosto: 'August',
      septiembre: 'September',
      octubre: 'October',
      noviembre: 'November',
      diciembre: 'December',
      todo: 'all',
      envio: 'send',
      recibido: 'Received',
      Enviado: 'Sent',
      Recibido: 'Received',
      confirmado: 'confirmed',
      Explorador: 'Explorer',
      // perfil
      MiPerfil: 'My Profile',
      DocumentosKYC: 'KYC',
      CuentaBancoKYC: 'Bank data details',
      Notificaciones: 'Notifications',
      AlRecibir: 'When receiving',
      AlEnviar: 'When sending',
      AlRealizarDexchange: 'DEX',
      AlRealizarFiat: 'Fiat Exchange',
      CerrarSesión: 'Close session',
      Cerrartodaslassesiones: 'Close all sessions',
      Guardar: 'Save',
      Correo: 'Email',
      Datos: 'Data',
      EmailRequerido: 'E-mail is required',
      EmailValido: 'E-mail must be valid',
      alertTitulo: "Saved",
      alertTitulo2: "Error saving",
      alertAlerta: "Changes have been saved successfully",
      alertAlerta2: "An error occurred while saving, try again",
      Ejemplo: 'Example@defix3.com',
      EjemploCorreo: 'Example@defix3.com',
      'Datos Perfil': 'Data Profile',
      Nombre: 'Name',
      Apellido: 'Last Name',
      Documento: 'Document',
      'desactivar 2fa': 'disable 2fa',
      'activar 2fa': 'enable 2fa',
      'Advertencia': 'Warning',
      'Su 2fa ya esta activo': 'Your 2fa is already active',
      Error: 'Error',
      'Ocurrio un error inesperado por favor vuelva a intentarlo mas tarde !!!': 'An unexpected error occurred, try again later !!!',
      Exito: 'Success',
      'Su 2fa ha sido desactivado': 'Your 2fa has been disabled',
      'Su codigo 2fa no es valido': 'Your 2fa code is not valid',
      footer: 'defiX3, 2022. Application version 1.0.0',
      Lenguage: 'Language',
      'debe seleccionar un tipo de documento !!!': 'you must select a document type !!!',
      "Esta seguro/a que desea cerrar esta sesiones ?.": "Are you sure you want to close this session?",
      "Esta seguro/a que desea cerrar todas las sesiones ?.": "Are you sure you want to close all sessions?",
      Si: 'Yes',
      phrase: 'Secret phrase',
      verifyphrase: 'Verify phrase',
      continue: 'Continue',
      close: 'Close',
      'View private key': 'View private key',
      Ver: 'View',
      'Private key': 'Private key',
      CCopiar: 'Copy',
      Copiado: 'copied',
      'La frase es correcta': 'The phrase is correct',
      'La frase secreta es incorrecta': 'The secret phrase is wrong',
      'Field required': 'Field required',
      'Frase secreta requerida para continuar.': 'Secret phrase required to continue.',

      // 2fa
      'Activacion 2FA': 'Activation 2FA',
      Llave: 'Key',
      'Si no tiene la App, puede descargarla aqui': 'If you do not have the app, you can download it here',
      'Escanee el código QR con Google authenticator, luego ingrese el código que le aparece para': 'Scan the QR code with Google authenticator, then enter the code that appears to ',
      Activar: 'Activate',
      Codigo: 'Code',
      'Se activo con exito el 2FA': 'Your 2fa has been activated',
      // fiat
      'Balance Total': 'Total Balance',

      'Selecciona el País': 'Select Country',
      'Ordenado por: Popularidad': 'Ordered by: Popularity',
      'Selecciona una Acción': 'Select an Action',
      Comprar: 'Buy',
      Vender: 'Sell',
      Bancos: 'Banks',
      Banco: 'Bank',
      'Bancos disponibles': 'Available Banks',
      'Selecciona el Banco': 'Select Bank',
      Frecuentes: 'Frequent',
      'Tasa por': 'Rate',
      Otros: 'Others',
      'Compra (Fiat)': 'Buy (Fiat)',
      'Selecciona la criptomoneda': 'Select Cryptocurrency',
      Compra: 'Buy',
      'Monto a Depositar': 'Amount to Deposit',
      'Cantidad en criptomoneda': 'Amount in Cryptocurrency',
      Siguiente: 'Next',
      'Cuenta Bancaria': 'Bank Account',
      Selecciona: 'Select',
      'Selecciona la cuenta': 'Select Account',
      'desde donde envias': 'from where you send',
      'Agregar Cuenta (Envio)': 'Add Account (Send)',
      'Agregar Cuenta': 'Add Account',
      'Crear Cuenta': 'Create Account',
      Envio: 'Send',
      'Coloque los datos como aparece en la identificación bancaria': 'Enter the data as it appears in the bank identification',
      'Nombre completo del titulo': 'Full Name of the title',
      'De no coincidir con el titular de la cuenta se reembolsaran los fondos': 'If the account holder does not match the name of the account, the funds will be refunded',
      'Vista previa': 'Preview',
      'Confirma tu Compra': 'Confirm your Purchase',
      'Cripto a recibir': 'Crypto to receive',
      'Total a recibir': 'Total to receive',
      'Método de Pago': 'Payment Method',
      'Nombre Titular': 'Name of the Holder',
      'Confirmar': 'Confirm',
      'Id Titular':'Holder Id',
      'Telefono Titular':'Holder Telephone',
      'Nro. Cuenta': 'Account number',
      'Realiza el pago':'Make the payment',
      Transfiere: 'Transfer',
      'Transfiere a la siguiente cuenta e ingresa el número de referencia': 'Transfer to the next account and enter the reference number',
      'Monto a transferir': 'Amount to transfer',
      'Transferir a': 'Transfer to',
      Desde: 'From',
      'N° Cuenta': 'Account Number',
      'Tipo de Cuenta': 'Type of Account',
      Corriente: 'Current',
      Identificación: 'Identification',
      'Transfiere dentro del tiempo establecido, de no ser así se cancelarala': 'Transfer within the time established, if it is not, it will be cancelled',
      'transacción automáticamente': 'automatic transaction',
      'inicia sesión en tu cuenta de pago, realiza la transferencia': 'log in to your payment account, make the transfer',
      'coloca el número de referencia y confirma': 'place the reference number and confirm',
      Cancelar: 'Cancel',
      Venta: 'Sale',
      'Cantidad en Fiat': 'Amount in Fiat',
      Cantidad:'Amount',
      'Monto a Vender': 'Amount to Sell',
      'Agregar Cuenta (Recibir)': 'Add Account (Receive)',
      Recibir: 'Receive',
      'Confirma tu Venta': 'Confirm your Sale',
      'Fiat a recibir': 'Fiat to receive',
      'A cuenta': 'To Account',
      'Tu orden está en proceso': 'Your order is in process',
      'Puedes verificar el estado del proceso y sus detalles en la sección de Historial de Transacciones': 'You can verify the status of the process and its details in the Transactions History section',
      Entendido: 'Understood',
      'Ver estado': 'View Status',
      "Tu orden ha sido cancelada": 'Your order has been canceled',
      Próximamente: 'Coming soon',
      "Estamos trabajando en las nuevas funcionalidades, está atento a nuestro servidor Discord para que te enteres de las noticias, eventos y mucho más!": "We are working on new features, stay tuned to our Discord server for news, events and much more!",
      'Cuentas donde te depositaran tus Ventas': 'Accounts where your sales will be deposited',
      "Cuentas donde pagaras tus Compras":"Accounts where you will pay for your purchases",
      "Historial de Transacciones":"FIAT History",
      "Detalle de la Transaccion":"Transaction Detail",
      "Archivo Adjunto":"Attached file",
      MontoaPagar:'Amount payable',
      TotalaPagar:'Total payable',
      comision:'Commission',
      //mdal fiat
      País:'Country',
      Descripcion:'Description',
      Estatus: 'Status',
      Pagos:'Payments',
      "Proceso de Revision":"Review process",
      "KYC Aprobado":"KYC Approved",
      "Carge sus datos para validar KYC":"Upload your data to validate KYC",
      "KYC NO aprobado":"KYC NOT approved",
      Login:'LOGIN',
      Natural:'Natural',
      Juridico:'Company',
      Accesa:'LOGIN',
      CreaClave:'Create your password to enter the FIAT module.',
      'Falta eMail. debe agregarlo en la opción de perfil.':'Missing email. you must add it in the profile option.',
      'Edición de Cuenta FIAT por País':'Edition of FIAT Account by Country',
      'Bienvenidos a la opcion de FIAT. Le invitamos a gestionar el proceso de KYC para su Pais. Para ello puede dar clic aqui en el boton con la etiqueta KYC':'Welcome to the FIAT option. We invite you to manage the KYC process for your country. To do this you can click here on the button labeled KYC.',

    }
  }
}
    
module.exports = {
  messages: {
    ES: {
      // cartera 
      CCriptomonedas: 'Criptomonedas',
      CCarteras: 'Carteras',
      CRecibir: 'Recibir',
      CBalanceT: 'Balance Total',
      Cmodaltexto: 'Escanear la dirección para recibir el pago',
      CCopiar: 'Copiar',
      Explorador: 'Explorador',
    },
    US: {
      // cartera 
      CCriptomonedas: 'Cryptocurrencies',
      CCarteras: 'Wallets',
      CRecibir: 'Receive',
      CBalanceT: 'Total Balance',
      Cmodaltexto: 'Scan address to receive payment',
      CCopiar: 'Copy',
      Explorador: 'Explorer',
    }
  }
}
    
<template>
  <v-container fluid id="landing">
    <Hero></Hero>
  </v-container>
</template>

<script>
import Hero from '../Hero/Hero.vue'
export default {
  name: "Home",
  components: { Hero }
}
</script>

<style src="./Home.scss" lang="scss"/>

<template>
  <section id="fiat" class="containerCartas">
    <ModalLeft ref="menu"></ModalLeft>
    <v-window v-model="step" touchless>
      
      <!-- detalle de transaccion (compra) -->
      <v-window-item :value="1">
        <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center; padding-top: 30px" class="containerTitulo">
          <v-col class="paddvertical divcol">
            <span class="titulo h6-em">Detalle Transacción</span>
            <span class="desc h7-em">Compra (USDT)</span>
          </v-col>
          <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB"
            @click="VolverAtras()">
            <v-icon medium>mdi-arrow-left-thin</v-icon>
          </v-btn>
        </v-col>

        <v-card class="cartaInside cartaTransfiere contDetalleTransaccion">
          <v-col cols="11" md="11" lg="10" style="margin-inline: auto; padding-inline: 0; gap: 1em" class="divcol">
            <aside>
              <v-btn v-for="(item, index) in dataProgress_compra" :key="index" :ripple="false"
                :class="{activeProgress: item.active, passedProgress: item.passed}"
                class="h9-em">
                {{item.progress}}
              </v-btn>
            </aside>

            <span v-if="progress_compra.completada" class="h8-em light" style="text-align: center">La transacción ha sido completada</span>
            <span v-else-if="progress_compra.procesando" class="h8-em light" style="text-align: center">La transacción se esta procesando</span>
            <span v-else-if="progress_compra.asignada" class="h8-em light" style="text-align: center">La transacción fue asignada al cajero #3</span>

            <section class="detalleTransaccionReduccion">
              <div class="space">
                <span class="h7-em">Creada el</span>
                <span class="h7-em tend">Dic 13, 2021 7:23 am</span>
              </div>
              <div class="space">
                <span class="h7-em">Cantidad Fiat</span>
                <span class="h7-em tend" :class="{colorGreen: true}">{{datosCompra.cantidad}}</span>
              </div>
              <div class="space">
                <span class="h7-em">Precio</span>
                <span class="h7-em tend">4.735 Bs</span>
              </div>
              <div class="space">
                <span class="h7-em">Cantidad en Cripto</span>
                <span class="h7-em tend" :class="{colorGreen: true}">{{datosCompra_crypto}} USDT</span>
              </div>
            </section>

            <section class="detalleTransaccionReduccion">
              <div class="space">
                <span class="h7-em">Nombre del Titular</span>
                <span class="h7-em tend">{{datosCompra.titular}}</span>
              </div>
              <div class="space">
                <span class="h7-em">Banco</span>
                <span class="h7-em tend">{{llaves.banco}}</span>
              </div>
              <div class="space">
                <span class="h7-em">Cuenta Destino</span>
                <span class="h7-em tend">01231***87654</span>
              </div>
              <div class="space">
                <span class="h7-em">N° Referencia</span>
                <span class="h7-em tend">9876537465</span>
              </div>
              <div class="space">
                <span class="h7-em">Id. Transacción</span>
                <span class="h7-em tend">7891asd434</span>
              </div>
            </section>

            <div class="space">
              <span class="h7-em">Completada el</span>
              <span class="h7-em tend">Dic 13, 2021 7:33 am</span>
            </div>
          </v-col>

          <v-col cols="11" md="11" lg="10" class="botonFinalEnviar" style="margin-inline: auto">
            <v-btn v-if="progress_compra.completada" class="h7-em botonSwap" @click="Descargar()">
              Descargar
            </v-btn>
            <v-btn v-else class="h7-em botonSwap bold" @click="CancelTransaction()"
              style="background-color: #FFFFFF !important; border: 2px solid #6667aa !important">
              <span style="color: #6667aa !important">Solicitar Cancelación</span>
            </v-btn>
          </v-col>
        </v-card>
      </v-window-item>



      <!-- detalle de transaccion (venta) -->
      <v-window-item :value="2">
        <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center; padding-top: 30px" class="containerTitulo">
          <v-col class="paddvertical divcol">
            <span class="titulo h6-em">Detalle Transacción</span>
            <span class="desc h7-em">Venta (NEAR)</span>
          </v-col>
          <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB"
            @click="VolverAtras()">
            <v-icon medium>mdi-arrow-left-thin</v-icon>
          </v-btn>
        </v-col>

        <v-card class="cartaInside cartaTransfiere contDetalleTransaccion">
          <v-col cols="11" md="11" lg="10" style="margin-inline: auto; padding-inline: 0; gap: 1em" class="divcol">
            <aside>
              <v-btn v-for="(item, index) in dataProgress_venta" :key="index" :ripple="false"
                :class="{activeProgress: item.active, passedProgress: item.passed}"
                class="h9-em">
                {{item.progress}}
              </v-btn>
            </aside>

            <span v-if="progress_venta.completada" class="h8-em light" style="text-align: center">La transacción ha sido completada</span>
            <span v-else-if="progress_venta.procesando" class="h8-em light" style="text-align: center">La transacción se esta procesando</span>
            <span v-else-if="progress_venta.asignada" class="h8-em light" style="text-align: center">La transacción fue asignada al cajero #3</span>

            <section class="detalleTransaccionReduccion">
              <div class="space">
                <span class="h7-em">Creada el</span>
                <span class="h7-em tend">Dic 13, 2021 7:23 am</span>
              </div>
              <div class="space">
                <span class="h7-em">Cantidad Fiat</span>
                <span class="h7-em tend" :class="{colorGreen: true}">{{datosVenta.cantidad}}</span>
              </div>
              <div class="space">
                <span class="h7-em">Precio</span>
                <span class="h7-em tend">5.675 Bs</span>
              </div>
              <div class="space">
                <span class="h7-em">Cantidad en Cripto</span>
                <span class="h7-em tend" :class="{colorGreen: true}">{{datosVenta_crypto}} NEAR</span>
              </div>
            </section>

            <section class="detalleTransaccionReduccion">
              <div class="space" style="margin-bottom: 1em">
                <span class="h7-em">Nombre del Titular</span>
                <span class="h7-em tend">{{datosVenta.titular}}</span>
              </div>
              <div class="space">
                <span class="h7-em">Banco</span>
                <span class="h7-em tend">{{llaves.banco}}</span>
              </div>
              <div class="space">
                <span class="h7-em">Cuenta Destino</span>
                <span class="h7-em tend">01231***87654</span>
              </div>
              <div class="space">
                <span class="h7-em">Id. Transacción</span>
                <span class="h7-em tend">7891asd434</span>
              </div>
            </section>

            <div class="space">
              <span class="h7-em">Completada el</span>
              <span class="h7-em tend">Dic 13, 2021 7:33 am</span>
            </div>
          </v-col>

          <v-col cols="11" md="11" lg="10" class="botonFinalEnviar" style="margin-inline: auto">
            <v-btn v-if="progress_venta.completada" class="h7-em botonSwap" @click="Descargar()">
              Descargar
            </v-btn>
            <v-btn v-else class="h7-em botonSwap bold" @click="CancelTransaction()"
              style="background-color: #FFFFFF !important; border: 2px solid #6667aa !important">
              <span style="color: #6667aa !important">Solicitar Cancelación</span>
            </v-btn>
          </v-col>
        </v-card>
      </v-window-item>
    </v-window>
  </section>
</template>

<script>
// import axios from "axios";
import ModalLeft from "./ModalLeft";
export default {
  name: "EstadoTransaccion",
  components: {
    ModalLeft
  },
  computed: {
    datosCompra_crypto() {
      return this.datosCompra.cantidad / 2
    },
    datosVenta_crypto() {
      return this.datosVenta.cantidad * 4
    },
  },
  data() {
    return {
      step: 1,
      llaves: {
        accion: "",
        pais: "",
        banco: "",
        crypto: ""
      },
      datosCompra: {
        cantidad: 0,
        cuentaEnvio: null,
        titular: null,
      },
      datosVenta: {
        cantidad: 0,
        titular: null,
      },
      balanceTotal: 0,
      dataProgress_compra: [
        {
          progress: "Creada",
          active: false,
          passed: true,
        },
        {
          progress: "Asignada",
          active: false,
          passed: true,
        },
        {
          progress: "Procesando",
          active: false,
          passed: true,
        },
        {
          progress: "Completada",
          active: true,
        },
      ],
      progress_compra: {
        asignada: false,
        procesando: false,
        completada: true,
      },
      dataProgress_venta: [
        {
          progress: "Creada",
          active: false,
          passed: true,
        },
        {
          progress: "Asignada",
          active: false,
          passed: true,
        },
        {
          progress: "Procesando",
          active: false,
          passed: true,
        },
        {
          progress: "Completada",
          active: true,
        },
      ],
      progress_venta: {
        asignada: false,
        procesando: false,
        completada: true,
      },
    };
  },
  // mounted() {
  //   this.CargarHistorico();
  //   this.interval2 = setInterval(function () {
  //       this.CargarHistorico();
  //   }.bind(this), 1000 * 60);
  // },
  methods: {
    // CargarHistorico() {
    //   let json = {
    //     defixId: "prueba777.defix3",
    //     coin: "NEAR",
    //     date_year: "%",
    //     date_month: "%"
    //   }
    //   axios.post(process.env.VUE_APP_API_BASE_URL + "transaction-history", json)
    //     .then((response) => {
    //      if(response.data) {
    //         console.log(response.data)
    //         this.dataHistorico = response.data.map(data => {
    //           let img = null;
    //           let stateImg = "";
    //           if(data.from_defix === this.$parent.dataUser.defixId) {
    //             img = require("@/assets/app/enviado.png");
    //             stateImg = "Enviado";
    //           } else {
    //             img = require("@/assets/app/recibido.png");
    //             stateImg = "Recibido";
    //           }
    //           return {
    //             number: data.id,
    //             date: data.date_time,
    //             image: img,
    //             stateImage: stateImg,
    //             title: data.coin + " " + stateImg,
    //             state: "confirmado",
    //             amountDollar: "",
    //             amountToken: data.value + " " + data.coin,
    //             colorR: false,
    //             colorG: true
    //           }
    //         });
    //      }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.dataHistorico = [];
    //     });
    // },
    VolverAtras() {
      if (window.innerWidth <= 880) {
        this.$parent.centerCard = false
        this.$parent.leftCard = true
      } else {
        this.$parent.centerCard = false
      }
    },
    Descargar() {
      console.log("funcion")
    },
    CancelTransaction() {
      console.log("funcion")
    },
  }
};
</script>

<style src="./Left.scss" lang="scss"></style>
<style scoped>
.containerFinal::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 90%;
  background-color: #3333332a;
}
</style>

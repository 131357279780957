module.exports = {
  messages: {
    ES: {
      footer: 'deFix3, 2022. Versión aplicación 1.0.0',
      Lenguage: 'Idioma',
    },
    US: {
      footer: 'deFix3, 2022. Application version 1.0.0',
      Lenguage: 'Language',
    }
  }
}
  
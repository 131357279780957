<template>
  <section class="headerApp-app">
    <ModalHeader ref="menu"></ModalHeader>
    <!-- mobile -->
    <v-app-bar
      v-model="drawer"
      width="100%"
      permanent
      app
      fixed
      bottom
      color="#FFFFFF"
      class="verMobile padd"
      style="padding-bottom: 60px;"
    >
      <v-col
        v-for="(item, index) in $store.state.dataDrawer"
        v-bind:key="index"
        class="navbarButtonMobile padd divcol"
        :class="{ navbarButtonActive: item.color, moreBtn: item.key=='more' }"
        @click="handleButtonMobile(item);"
      >
        <v-icon x-large>{{ item.icon }}</v-icon>
        <span class="h9-em h10-responsive" style="text-align: center">{{
          item.title
        }}</span>
      </v-col>
    </v-app-bar>

    <!-- desktop -->
    <v-navigation-drawer
      height="100vh"
      v-model="drawer"
      :mini-variant="mini"
      permanent
      width="207px"
      mini-variant-width="77px"
      :color="colorDrawer"
      fixed
      style="backdrop-filter: blur(11px); z-index: 1000"
      class="eliminarMobile"
    >
      <v-row class="navbar">
        <v-col class="center divcol" style="padding: right: 0">
          <a href="#/">
            <img class="logo" src="@/assets/app/logo/logo.svg" alt="logo" />
          </a>
        </v-col>

        <v-list>
          <v-list-item
            v-for="item in $store.state.dataDrawer"
            :key="item.title"
            link
            class="navbarButton"
            :class="{ navbarButtonActive: item.color }"
            @click="handleButton(item)"
          >
            <v-list-item-icon>
              <div style="margin-left: 16px">
                <v-icon :style="item.style" large>{{ item.icon }}</v-icon>
              </div>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="h8-em">
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>


        <v-col class="navbarButton pl-8" :class="{ navbarButtonActive: this.color }">
          <p>
          <v-btn icon @click.stop="(mini = !mini), openDrawer()">
            <v-icon v-if="playIcon" x-large>mdi-play</v-icon>
            <v-icon v-else x-large style="transform: rotate(180deg)"
              >mdi-play</v-icon
            >
          </v-btn>
          </p>
          <p>
          <v-btn icon @click="$parent.$parent.$refs.home.$refs.perfil.AbrirDialogCierre(1)">
            <v-icon x-large>mdi-power</v-icon>
          </v-btn>
          </p>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </section>
</template>

<script>
import ModalHeader from './ModalHeader.vue'
export default {
  name: "Header",
  i18n: require("./i18n"),
  components: { ModalHeader },
  mounted() {
    var  sUsrAg = navigator.userAgent;
    if(sUsrAg.indexOf("Chrome") > -1 || sUsrAg.indexOf("Opera") > -1) {
      this.colorDrawer = "#ffffff8a"
    } else if (sUsrAg.indexOf("Firefox") > -1) {
      this.colorDrawer = "#ffffff"
    }
    this.$store.dispatch("Navigation", {item: {key:'cartera'}})
    this.$store.commit("SwitchNavBtn")
  },
  data() {
    return {
      colorDrawer: "",
      drawer: true,
      mini: true,
      // drawer: null,
      color: false,
      playIcon: true,
    };
  },
  methods: {
    clearCard() {
      this.$parent.$parent.$refs.home.Dapps = false;
      this.$parent.$parent.$refs.home.Historico = false;
      this.$parent.$parent.$refs.home.Fiat = false;
      this.$parent.$parent.$refs.home.EstadoTransaccion = false;
      this.$parent.$parent.$refs.home.Perfil = false;
      this.$parent.$parent.$refs.home.Portafolio = false;
      this.$parent.$parent.$refs.home.EditarPerfil = false;
      this.$parent.$parent.$refs.home.dosfa = false;
      this.$parent.$parent.$refs.home.Cartera = false;
      this.$parent.$parent.$refs.home.Market = false;
      this.$parent.$parent.$refs.home.ExchangeCard = false;
    },
    
    DeleteUpdatePerfil(){
      this.$parent.$parent.$refs.home.$refs.perfil.editedItem.email = this.$parent.$parent.$refs.home.$refs.perfil.email;
      this.$parent.$parent.$refs.home.$refs.perfil.FormDisabled = true;
    },

    // mobile methods
    handleButtonMobile(item) {
      this.DeleteUpdatePerfil();
      this.$store.dispatch("Navigation", {item})
      if (item.key!=='more') {this.clearCard()}
      // cartera
      if (item.key == "cartera") {
        this.$parent.$parent.$refs.home.Cartera = true;
        //
        this.$parent.$parent.$refs.home.leftCard = true;
        this.$parent.$parent.$refs.home.centerCard = false;
        this.$parent.$parent.$refs.home.rightCard = false;
      }
      //dapps
      if (item.key == "dapps") {
        this.$parent.$parent.$refs.home.Dapps = true;
        //
        this.$parent.$parent.$refs.home.leftCard = true;
        this.$parent.$parent.$refs.home.centerCard = false;
        this.$parent.$parent.$refs.home.rightCard = false;
      }
      // market
      if (item.key == "market") {
        this.$parent.$parent.$refs.home.Portafolio = true;
        //
        this.$parent.$parent.$refs.home.leftCard = false;
        this.$parent.$parent.$refs.home.centerCard = true;
        this.$parent.$parent.$refs.home.rightCard = false;
      }
      // exchange
      if (item.key == "exchange") {
        this.$parent.$parent.$refs.home.Exchange = true;
        this.$parent.$parent.$refs.home.ExchangeCard = true;
        //
        this.$parent.$parent.$refs.home.rightCard = true;
        this.$parent.$parent.$refs.home.centerCard = false;
        this.$parent.$parent.$refs.home.leftCard = false;
      }
      // usuario
      if (item.key == "perfil") {
        this.$parent.$parent.$refs.home.Perfil = true;
        //
        this.$parent.$parent.$refs.home.leftCard = true;
        this.$parent.$parent.$refs.home.centerCard = false;
        this.$parent.$parent.$refs.home.rightCard = false;
      }
      // fiat
      if (item.key == "fiat") {
        this.$parent.$parent.$refs.home.Fiat = true;
        //
        this.$parent.$parent.$refs.home.leftCard = true;
        this.$parent.$parent.$refs.home.centerCard = false;
        this.$parent.$parent.$refs.home.rightCard = false;
      }
    },


    // desktop methods
    handleButton(item) {
      this.DeleteUpdatePerfil();
      this.$store.dispatch("Navigation", {item})
      this.clearCard()
      // cartera
      if (item.key == "cartera") {
        this.$parent.$parent.$refs.home.Cartera = true;
        this.$parent.$parent.$refs.home.Portafolio = true;
        this.$parent.$parent.$refs.home.Market = true;
        this.$parent.$parent.$refs.home.ExchangeCard = true;
        this.$parent.$parent.$refs.home.Exchange = false;
        //
        this.$parent.$parent.$refs.home.leftCard = true;
        this.$parent.$parent.$refs.home.centerCard = true;
        this.$parent.$parent.$refs.home.rightCard = true;
      }
      // dapps
      if (item.key == "dapps") {
        this.$parent.$parent.$refs.home.Dapps = true;
        //
        this.$parent.$parent.$refs.home.leftCard = true;
        this.$parent.$parent.$refs.home.centerCard = false;
        this.$parent.$parent.$refs.home.rightCard = false;
      }
      // market
      if (item.key == "market") {
        this.$parent.$parent.$refs.home.Cartera = true;
        this.$parent.$parent.$refs.home.Market = true;
        this.$parent.$parent.$refs.home.ExchangeCard = true;
        this.$parent.$parent.$refs.home.Exchange = false;
        //
        this.$parent.$parent.$refs.home.leftCard = true;
        this.$parent.$parent.$refs.home.centerCard = true;
        this.$parent.$parent.$refs.home.rightCard = true;
      }
      // exchange
      if (item.key == "exchange") {
        this.$parent.$parent.$refs.home.Cartera = true;
        this.$parent.$parent.$refs.home.Portafolio = true;
        this.$parent.$parent.$refs.home.Market = true;
        this.$parent.$parent.$refs.home.ExchangeCard = true;
        this.$parent.$parent.$refs.home.Exchange = true;
        //
        this.$parent.$parent.$refs.home.leftCard = true;
        this.$parent.$parent.$refs.home.centerCard = true;
        this.$parent.$parent.$refs.home.rightCard = true;
      }
      // usuario
      if (item.key == "perfil") {
        this.$parent.$parent.$refs.home.Perfil = true;
        //
        this.$parent.$parent.$refs.home.leftCard = true;
        this.$parent.$parent.$refs.home.centerCard = false;
        this.$parent.$parent.$refs.home.rightCard = false;
      }
      // fiat
      if (item.key == "fiat") {
        this.$parent.$parent.$refs.home.Fiat = true;
        //
        this.$parent.$parent.$refs.home.leftCard = true;
        this.$parent.$parent.$refs.home.centerCard = false;
        this.$parent.$parent.$refs.home.rightCard = false;
      }
    },
    openDrawer() {
      this.playIcon = !this.playIcon;
    },
  },
};
</script>

<style src="./Header.scss" lang="scss"></style>

module.exports = {
  messages: {
    ES: {
      'Testnet only': 'Solo red de prueba',
    },
    US: {
      'Testnet only': 'Testnet only',
    }
  }
}

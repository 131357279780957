<template>
  <v-footer class="footer-app" color="transparent" absolute>
    <!--<v-row class="divcol center">-->
    <v-row>
      <v-col
        cols="8"
        md="8"
        class="pr-12"
      >
        <span class="desc2 h8-em paddbottom paddtop" style="float:right; justify-content: space-between; margin-inline: auto">
          &copy; {{ $t("footer") }}
        </span>
      </v-col>
      <v-col
        cols="2"
        md="2"
        class="mr-9"
      >
        <v-select
          v-model="value"
          :items="lang"
          item-value="value"
          item-text="title"
          hide-details
          prepend-icon="language"
          single-line
          @change="changeLanguaje(value)"
        >
          <template v-slot:label>
            <span class="h8-em">{{ $t("Lenguage") }}</span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <!--<v-row style="float:right">
      <v-col
        cols="2"
        md="2"
      >
        <v-select
          v-model="select"
          :items="items"
          :error-messages="errors"
          label="Select"
          data-vv-name="select"
          required
        ></v-select>
      </v-col>
    </v-row>-->
  </v-footer>
</template>

<script>
import { i18n } from "@/plugins/i18n";

export default {
  name: "Footer",
  i18n: require("./i18n"),
  data() {
    return {
      lang: [
        {
          title: "English",
          to: "#",
          value: "US",
        },
        {
          title: "Español",
          to: "#",
          value: "ES",
        },
      ],
      value: localStorage.getItem('language') !== "undefined" ? localStorage.getItem('language') : "US" ,
    };
  },
  mounted() {
    this.changeLanguaje(localStorage.language);
  },
  methods: {
    changeLanguaje(lang) {
      if (lang === "ES") {
        localStorage.setItem('language', lang)
        //localStorage.language = lang;
        i18n.locale = lang;
      } else {
        localStorage.setItem('language', lang)
        //localStorage.language = lang;
        i18n.locale = lang;
      }
    },
  }
};
</script>
<style src="./Footer.scss" lang="scss" />

import { render, staticRenderFns } from "./ModalSwap.vue?vue&type=template&id=58095c20&scoped=true&"
import script from "./ModalSwap.vue?vue&type=script&lang=js&"
export * from "./ModalSwap.vue?vue&type=script&lang=js&"
import style0 from "./Right.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "./ModalSwap.vue?vue&type=style&index=1&id=58095c20&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58095c20",
  null
  
)

export default component.exports
<template>
  <section>
    <v-navigation-drawer
      id="modalQR2"
      :class="{fix: Fix}"
      v-model="ModalQR"
      :absolute="positionModal"
      :fixed="!positionModal"
      temporary
      overlay-color="black"
      overlay-opacity="0.5"
      color="#FFFFFF"
    >
      <v-col cols="10" class="containerLineas margintop paddlateralheight" style="margin-inline: auto; padding-block: 15px">
        <span class="titulo h6-em">Recibir - QR</span>
      </v-col>
      <v-col cols="12" md="11" class="divcol center margintop" style="margin-inline: auto">
        <span class="h8-em">Token: {{ token }}</span>
        <!-- temporal -->
        <vue-qr v-if="wallet != null" :text="wallet" :size="200" style="margin-block: 12px"></vue-qr>
        <span class="h8-em" style="text-align: center">Escanear la dirección para recibir el pago</span>
        <v-card class="containerModal center">
          <v-btn id="botonCopiar" class="h9-em" @click="copyToClipboard()">
            <span>Copiar</span>
          </v-btn>
          <span class="h8-em">{{ wallet }}</span>
        </v-card>
      </v-col>
    </v-navigation-drawer>


    <v-navigation-drawer
      id="modalQR2"
      :class="{fix: Fix}"
      v-model="ModalTipe"
      :absolute="positionModal"
      :fixed="!positionModal"
      temporary
      overlay-color="black"
      overlay-opacity="0.5"
      color="#FFFFFF"
    >
      <v-col
        cols="10"
        class="containerLineas margintop"
        style="
          margin-inline: auto;
          padding-block: 15px;
          display: flex;
          justify-content: space-between
          align-items: center
        "
      >
        <span class="titulo h6-em">Tipo de Documentación</span>
        <v-btn icon @click="ModalTipe = false">
          <v-icon color="#6667AB" medium>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="10" class="divcol margintop paddlateralheight" style="margin-inline: auto">
        <section class="cardCrypto">
          <v-card
            v-for="(item, index) in dataTipe"
            v-bind:key="index"
            color="transparent"
            style="margin-block: 12px; display: flex; justify-content: start"
          >
            <div class="containerTokens" style="width: 100%" @click="SelectTipe(item)">
              <span class="desc h8-em">{{ item.tipe }}</span>
            </div>
          </v-card>
        </section>
      </v-col>
    </v-navigation-drawer>


    <v-navigation-drawer
      id="modalQR2"
      :class="{fix: Fix}"
      v-model="ModalBanco"
      :absolute="positionModal"
      :fixed="!positionModal"
      temporary
      overlay-color="black"
      overlay-opacity="0.5"
      color="#FFFFFF"
    >
      <v-col
        cols="10"
        class="containerLineas margintop"
        style="
          margin-inline: auto;
          padding-block: 15px;
          display: flex;
          justify-content: space-between
          align-items: center
        "
      >
        <span class="titulo h6-em">Bancos</span>
        <v-btn icon @click="ModalBanco = false">
          <v-icon color="#6667AB" medium>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="10" class="divcol margintop paddlateralheight" style="margin-inline: auto">
        <section class="cardCrypto">
          <v-card
            v-for="(item, index) in dataBanco"
            v-bind:key="index"
            color="transparent"
            style="margin-block: 12px; display: flex; justify-content: start"
          >
            <div class="containerTokens" style="width: 100%" @click="SelectBanco(item)">
              <span class="desc h8-em">{{ item.banco }}</span>
            </div>
          </v-card>
        </section>
      </v-col>
    </v-navigation-drawer>
  </section>
</template>

<script>
import VueQr from 'vue-qr'
export default {
  name: "ModalLeft",
  components: {
    VueQr
  },
  data() {
    return {
      Fix: false,
      ModalQR: false,
      wallet: null,
      token: null,
      positionModal: true,
      ModalBanco: false,
      ModalTipe: false,
      dataTipe: [
        {
          tipe: "V"
        },
        {
          tipe: "J"
        },
        {
          tipe: "E"
        },
      ],
      dataBanco: [
        {
          banco: "Banesco"
        },
        {
          banco: "Provincial"
        },
        {
          banco: "BOD"
        },
        {
          banco: "Banco de Venezuela"
        },
        {
          banco: "Mercantil"
        },
      ]
    };
  },
  mounted() {
    if (window.innerWidth <= 880) {
      this.positionModal = false
    } else {
      this.positionModal = true
    }
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.wallet);
    },
    SelectTipe(item) {
      this.$parent.crearCuenta_compra.tipe = item.tipe
      this.$parent.crearCuenta_venta.tipe = item.tipe
      this.ModalTipe = false
    },
    SelectBanco(item) {
      this.$parent.crearCuenta_venta.banco = item.banco
      this.ModalBanco = false
    }
  },
};
</script>

<style src="./Left.scss" lang="scss"></style>
<style scoped>
.containerLineas {
  position: relative;
}
.containerLineas::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
}
</style>
<template>
  <div id="app">
  <router-view />
  </div>
</template>

<script>
  export default {
    name: 'App',
  };
</script>

<style src="./styles/app.scss" lang="scss"/>

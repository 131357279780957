<template>
  <section class="containerCartas">
    <!--<v-col cols="11" md="11" lg="10" style="place-items: center" class="containerTitulo">
      <img class="token" :src="dataCoinHistorico.token" alt="token" />
      <v-col class="paddvertical">
        <aside style="display: flex; justify-content: space-between; align-items: center">
          <span class="titulo h6-em">$ {{ dataCoinHistorico.price }}</span>
        </aside>
        <aside style="display: flex; align-items: center; gap: 5px">
          <span class="desc h7-em">{{ dataCoinHistorico.title }}</span>
          <span class="h8-em" :class="{ colorGreen: dataCoinHistorico.colorG, colorRed: dataCoinHistorico.colorR }">{{ dataCoinHistorico.state }}</span>
        </aside>
      </v-col>
    </v-col>-->
    <!--
    <v-col class="padd" style="position: relative">
      <Chart ref="chart"></Chart>
      <div class="center-absolute divisor"></div>
    </v-col> -->

    <v-col cols="11" md="11" lg="10" style="justify-content: space-between" class="containerTitulo mb-5">
      <v-col class="paddvertical divcol">
        <span class="titulo h6-em">{{ $t("Htitulo") }}</span>
        <div class="space">
          <span class="desc h7-em">{{ $t("HActividad") }}</span>
          <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB"
            @click="volverAtras()">
            <v-icon medium>mdi-arrow-left-thin</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-col>

    <!--<v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="paddvertical divcol mt-12 mb-5">
      <aside class="divcol" style="width: 100%; justify-content: center">
        <div style="display: flex; justify-content: space-between">
          <span class="titulo h7-em">$ {{ dataCoinHistorico.amountDollar }}</span>
        </div>
        <div style="display: flex; justify-content: space-between">
          <span id="h7-5" class="h9-em">{{ dataCoinHistorico.amountToken }} {{ dataCoinHistorico.title }}</span>
        </div>
      </aside>
      <span class="desc h8-em">Disponibilidad BTC</span>
      <span class="titulo h8-em paddtop paddbottom"
        >Histórico de transacciones</span
      >
    </v-col>-->
    <v-card class="cartaInside cartaHistorico">
      <v-col class="filtrosHistorico">
        <aside class="center divwrap">
          <v-chip-group
            mandatory
            show-arrows
            active-class="activeFiltro"
          >
            <v-chip
              v-for="filtro in filtros"
              :key="filtro"
              class="h8-em btnFiltros semibold"
              style="height: max-content"
              @click="Funfiltros(filtro)"
            >
              <span>{{ $t(filtro) }}</span>
            </v-chip>
          </v-chip-group>
        </aside>

        <aside class="center">
          <!-- filter año -->
          <div class="center">
            <label class="h8-em bold" for="year">{{ $t("PAno") }}</label>
            <v-select
              v-model="year"
              id="year"
              :items="filter.year"
              :menu-props="{ bottom: true, offsetY: true }"
              solo
              hide-details
              @change="CargarHistorico()"
            >
              <!-- seleccion -->
              <template v-slot:selection="slotProps">
                <span class="h8-em">{{slotProps.item}}</span>
              </template>
              <!-- lista -->
              <template v-slot:item="slotProps">
                <span class="h8-em">{{slotProps.item}}</span>
              </template>
            </v-select>
          </div>

          <!-- filter mes -->
          <div class="center">
            <label class="h8-em bold" for="month">{{ $t("PMes") }}</label>
            <v-select
              v-model="month"
              id="month"
              :items="filter.month"
              :menu-props="{ bottom: true, offsetY: true }"
              solo
              hide-details
              @change="CargarHistorico()"
            >
              <!-- seleccion -->
              <template v-slot:selection="slotProps">
                <span class="h8-em" style="text-transform: capitalize">{{ $t(slotProps.item) }}</span>
              </template>
              <!-- lista -->
              <template v-slot:item="slotProps">
                <span class="h8-em" style="text-transform: capitalize">{{ $t(slotProps.item) }}</span>
              </template>
            </v-select>
          </div>

          <!-- filter moneda -->
          <div class="center">
            <label class="h8-em bold" for="coin">{{ $t("Moneda") }}</label>
            <v-select
              v-model="coin"
              id="coin"
              :items="filter.coin"
              :menu-props="{ bottom: true, offsetY: true }"
              solo
              hide-details
              @change="CargarHistorico()"
            >
              <!-- seleccion -->
              <template v-slot:selection="slotProps">
                <span class="h8-em">{{$t(slotProps.item)}}</span>
              </template>
              <!-- lista -->
              <template v-slot:item="slotProps">
                <span class="h8-em">{{$t(slotProps.item)}}</span>
              </template>
            </v-select>
          </div>
        </aside>
      </v-col>
      
      <div id="datahistoricohtml2">
      <v-col md="12" lg="10" class="cardHistorico pt-5 pb-5" style="margin-inline: auto; padding-top: 0">
        <v-col
          v-for="(item, index) in dataHistorico"
          v-bind:key="index"
          style="margin-inline: auto"
          class="divcol"
        >
          <div class="divrow">
            <span class="h9-em marginbottom">
              <!--<span>#{{ item.number }}</span> - <span>{{ item.date }}</span>-->
              <span>{{ new Date(item.date.replace(/-/g, "/") + " GMT+0000").toLocaleString(MyTimeZone.locale, {timeZone: MyTimeZone.timeZone}) }}</span>
            </span>
          </div>
          <div class="divrow" style="gap: 10px">
            <img class="token" :src="item.image" :alt="item.stateImage" />
            <aside class="divcol" style="width: 100%; justify-content: center">
              <div style="display: flex; justify-content: space-between">
                <span class="h8-em">{{ item.title }} {{  $t(item.stateImage) }} </span> 
                <span class="h7-em"
                  :class="{colorGreen: item.stateImage == 'compra', retiro: item.stateImage == 'retiro'}">
                  <!--<span v-if="item.action == 'compra'">+</span>
                  <span v-if="item.action == 'retiro'">-</span>
                  <span v-if="item.action == 'enviado' || 'recibido'">$</span> 2.00-->
                  {{ item.amountToken }}
                </span>
              </div>
              <div style="display: flex; justify-content: space-between">
                <span
                  class="h9-em"
                  :class="{ colorGreen: item.colorG, colorRed: item.colorR }"
                  >{{ $t(item.state) }}</span
                >
                <span class="h9-em"><a :href="item.explorer" target="_blank">{{ $t('Explorador') }}</a> </span>
                <!--<span class="h9-em">{{ item.amountToken }}</span>-->
              </div>
            </aside>
          </div>
        </v-col>
      </v-col>
      </div>


      <span v-show="ocultar">
      <div id="datahistoricohtml">
      <h1> hola mundo </h1>
      <v-col md="12" lg="10" class="cardHistorico pt-5 pb-5" style="margin-inline: auto; padding-top: 0">
        <v-col
          v-for="(item, index) in dataHistorico"
          v-bind:key="index"
          style="margin-inline: auto"
          class="divcol"
        >
          <div class="divrow">
            <span class="h9-em marginbottom">
              <span>{{ new Date(item.date).toLocaleString(MyTimeZone.locale, {timeZone: MyTimeZone.timeZone}) }}</span>
            </span>
          </div>
          <div class="divrow" style="gap: 10px">
            <img class="token" :src="item.image" :alt="item.stateImage" />
            <aside class="divcol" style="width: 100%; justify-content: center">
              <div style="display: flex; justify-content: space-between">
                <!-- cambio aqui -->
                <span class="h8-em">{{ item.title }} {{  $t(item.stateImage) }} </span> 
                <span class="h7-em"
                  :class="{colorGreen: item.stateImage == 'compra', retiro: item.stateImage == 'retiro'}">
                  <!--<span v-if="item.action == 'compra'">+</span>
                  <span v-if="item.action == 'retiro'">-</span>
                  <span v-if="item.action == 'enviado' || 'recibido'">$</span> 2.00-->
                  {{ item.amountToken }}
                </span>
                <!-- cambio aqui -->
              </div>
              <div style="display: flex; justify-content: space-between">
                <span
                  class="h9-em"
                  :class="{ colorGreen: item.colorG, colorRed: item.colorR }"
                  >{{ $t(item.state) }}</span
                >
                <span class="h9-em">{{ item.explorer }} </span>
                <!--<span class="h9-em">{{ item.amountToken }}</span>-->
              </div>
            </aside>
          </div>
        </v-col>
      </v-col>
      </div>
      </span>

      <!--<aside class="botonFinalEnviar">
        <v-btn
          class="botonSwap h7-em"
          @click="download()"
        >
          {{ $t("Descargar") }}
        </v-btn>
      </aside>-->
    </v-card>
    <!-- <v-col
      cols="11" md="11" lg="10"
      class="containerFinal divrow"
      style="justify-content: space-between"
    >
      <div class="divrow" style="gap: 1em">
        <aside class="divcol center hoverDown">
        <v-btn id="containerBotones" @click="irEnviar()">
          <img class="botones" src="@/assets/app/enviar.png" alt="Enviar" />
        </v-btn>
          <span class="desc2 h8-em" style="cursor: pointer"
            @click="irEnviar()">Enviar</span>
        </aside>
        <aside class="divcol center hoverDown">
        <v-btn id="containerBotones" @click="modalLeft()">
          <img class="botones" src="@/assets/app/recibir.png" alt="Recibir" />
        </v-btn>
          <span class="desc2 h8-em" style="cursor: pointer"
            @click="modalLeft()">Recibir</span>
        </aside>
      </div>
      <aside class="divcol center hoverDown">
        <v-btn id="containerBotones" @click="modalLeft()">
          <img class="botones" src="@/assets/app/qr.png" alt="QR" />
        </v-btn>
        <span class="desc2 h8-em" style="cursor: pointer"
          @click="modalLeft()">QR</span>
      </aside>
    </v-col> -->
  </section>
</template>

<script>
//import Chart from "@/pages/app/Left/Chart.vue"
import jsPDF from 'jspdf' 
import axios from "axios";
import html2canvas from "html2canvas";
//import jsPDF from "jspdf";

//var doc = new jsPDF('p', 'pt', 'A4');

export default {
  name: "Historico",
  i18n: require("./i18n"),
  /*components: {
    Chart
  },*/
  data() {
    return {
      //dataCoinHistorico: {},
      ocultar: false,
      dataHistorico: [],
      dataHistoricoOrigen: [],
      filtros: [
        'todo',
        //'compra',
        //'retiro',
        'envio',
        'recibido',
        'swap',
      ],
      filter: {
        year: [],
        month: [
          'Todos',
          'enero',
          'febrero',
          'marzo',
          'abril',
          'mayo',
          'junio',
          'julio',
          'agosto',
          'septiembre',
          'octubre',
          'noviemre',
          'diciembre'
        ],
        coin: ['Todas'],
      },
      Mfiltros: "todo",
      year: new Date().getFullYear(),
      month: 'Todos',
      coin: 'Todas',
      MyTimeZone: null,
    };
  },
  mounted() {
    //this.month = this.filter.month[parseInt(new Date().getMonth()) + 1];
    this.CargarHistorico();
    this.CargarYear();
    this.interval2 = setInterval(function () {
        this.CargarHistorico();
    }.bind(this), 1000 * 60);
    this.MyTimeZone = Intl.DateTimeFormat().resolvedOptions();
    console.log(this.MyTimeZone)
  },
  methods: {
    volverAtras() {
      if (window.innerWidth <= 880) {
        this.$parent.Cartera = true;
        this.$parent.Historico = false;
        this.$parent.leftCard = true;
        this.$parent.centerCard = false;
        this.$parent.rightCard = false;
      } else {
        this.$parent.Portafolio = true;
        this.$parent.Historico = false;
        /*this.$parent.Cartera = true;
        this.$parent.Fiat = false;
        this.$parent.Dapps = false;
        this.$parent.Perfil = false;
        this.$parent.Historico = false;*/
      }
    },
    CargarYear() {
      this.filter.year = [];
      for (let i = new Date().getFullYear(); i >= 2021; i--) {
        this.filter.year.push(i);
      }
    },
    Funfiltros(filtro) {
      this.Mfiltros = filtro;
      this.CargarHistorico()
    },
    CargarHistorico() {
      //(this.filter.month.indexOf(this.month)).toString()
      let coin = this.coin == "Todas" ? "%" : this.coin;
      let mesSelect = (this.filter.month.indexOf(this.month)).toString();
      let month = this.month == "Todos" ? "%" : mesSelect.length == 1 ? "0" + mesSelect : mesSelect;
      let tipo;
      switch (this.Mfiltros) {
        case 'todo':
          tipo = "%";
          break;
        case 'envio':
          tipo = "TRANSFER";
          break;
        case 'recibido':
          tipo = "TRANSFER";
          break;
        default: 
          tipo = "DEX"
          break;
      }
      
      let json = {
        defixId: JSON.parse(localStorage.getItem('dataUser')).defixId,
        coin: coin,
        date_year: this.year.toString(),
        date_month: month,
        tipo: tipo,
      }
      axios.post(process.env.VUE_APP_API_BASE_URL + "transaction-history", json)
        .then((response) => {
         //console.log("historico", response.data);
         if(response.data) {
            let img = null;
            let stateImg = "";
            let coin = null
            let explorer;
            this.dataHistoricoOrigen = response.data.map(data => {
              if(data.from_defix === this.$parent.dataUser.defixId) {
                img = require("@/assets/app/enviado.svg");
                stateImg = "Enviado";
              } else {
                img = require("@/assets/app/recibido.svg");
                stateImg = "Recibido";
              } 

              if(data.tipo === "DEX") {
                img = require("@/assets/app/swap.svg");
                stateImg = "Swap";
              }

              coin = data.coin.split('/').length > 1 ? data.coin.split('/')[0] : data.coin;

              switch (process.env.VUE_APP_API_BASE_RED) {
                case 'mainnet': explorer = this.$parent.dataCartera.filter(item => item.title == coin)[0].explorerMainnetTransactions + data.hash;
                  break;
                case 'testnet': explorer = this.$parent.dataCartera.filter(item => item.title == coin)[0].explorerTestnetTransactions + data.hash;
                  break;
              }
             
              return {
                number: data.id,
                date: data.date_time,
                image: img,
                stateImage: stateImg,
                title: data.coin,
                state: "confirmado",
                amountDollar: "",
                amountToken: data.value + " " + data.coin,
                colorR: false,
                colorG: true,
                explorer: explorer,
              };
            });

            this.dataHistorico = this.dataHistoricoOrigen.reverse();
            switch (this.Mfiltros) {      
              case 'envio':
                this.dataHistorico = this.dataHistoricoOrigen.filter(
                  item => item.stateImage == 'Enviado'
                );
                break;
              case 'recibido':
                this.dataHistorico = this.dataHistoricoOrigen.filter(
                  item => item.stateImage == 'Recibido'
                );
                break;
              default:
                this.dataHistorico = this.dataHistoricoOrigen;
                break;
            }
         }
        })
        .catch(() => {
          this.dataHistorico = [];
        });
      this.filter.coin = this.$parent.dataCartera.map(data => {
        return data.title;
      });
      this.filter.coin.unshift("Todas");
    },
    download() {
      window.html2canvas = html2canvas;
      var doc = new jsPDF();
      //var html = this.$refs.datahistoricohtml.innerHTML;
      doc.html(document.querySelector("#datahistoricohtml2"), {
        callback: function(pdf) {
          pdf.save("pruebaPdf.pdf");
        }
      });
      
      //doc.text(10, 10, 'Hello world!');
      /*var  margins = {
            top: 80,
            bottom: 60,
            left: 40,
            width: 522
      };
      doc.html('<HTML><HEAD><TITLE>Convert HTML file to PDF</TITLE></HEAD><BODY><h1>Convert HTML file to PDF</h1></BODY></HTML>', margins.left, margins.top,{
        'width' : margins.width
      });*/
      /*var html = this.$refs.datahistoricohtml.innerHTML;
      //let html = '<HTML><HEAD><TITLE>Convert HTML file to PDF</TITLE></HEAD><BODY><h1>Convert HTML file to PDF</h1></BODY></HTML>'.innerHTML;
      doc.html(html, 15, 15,{
        'width' : 170,
      });
      doc.save('hello-world.pdf');
      */
      
      /*var  margins = {
            top: 80,
            bottom: 60,
            left: 40,
            width: 522
        };
      
      doc.html(this.$refs.datahistoricohtml, margins.left, margins.top,{
        'width' : margins.width
      });
      
      doc.save('test.pdf');*/
    },
    /*{
      const doc = new jsPDF();
      const contentHtml = this.$refs.datahistorico.innerHTML;
      doc.fromHTML(contentHtml, 15, 15, {
        width: 170
      });
      doc.save("sample.pdf");
    },*/
  }
};
</script>

<style src="./Left.scss" lang="scss"></style>
<style scoped>
.containerFinal::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 90%;
  background-color: #3333332a;
}
/* .containerFinal::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
} */
</style>
<template>
  <section class="containerCartas">
    <Alert ref="alert"></Alert>
    <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center" class="containerTitulo">
      <v-col class="paddvertical divcol">
        <span class="titulo h6-em">{{ $t("Activacion 2FA") }}</span>
        <span class="desc h7-em">{{ $parent.dataUser.defixId }}</span>
      </v-col>
      <div class="divrow center">
        <!--<v-badge
          :content="messages"
          :value="messages"
          color="red"
          overlap
          bordered
        >
          <v-icon color="#6667AB" medium>mdi-bell</v-icon>
        </v-badge>-->
        <!--<img class="user" src="@/assets/app/user.png" alt="token" />-->
        <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
        <div class="divrow" style="justify-content: space-between; align-items: center">
          <span class="titulo h7-em paddtop paddbottom"></span>
          <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB"
          @click="volverAtras()">
            <v-icon medium>mdi-arrow-left-thin</v-icon>
          </v-btn>
        </div>
        <!--<span class="desc h8-em">{{ this.$parent.dataUser.defixId }}</span>-->
      </v-col>
      </div>
    </v-col>

    <v-card class="cartaInside cartaIE">

      <v-row class="row mt-5">
        <v-col cols="5" md="5" class="divcol center" style="margin-inline: auto">
          <!-- temporal -->
          <!--<vue-qr v-if="wallet != null" :text="wallet" :size="200" style="margin-block: 12px"></vue-qr>-->
          <img :src="qr" class="img-fluid" />
          <P><span style="font-weight: bold ">{{ $t("Llave") }}:</span> {{ codigo }}</P>
        </v-col>
        <v-col cols="5" md="5" class="" style="margin-inline: auto;">
          <span class="h8-em mt-5" style="text-align: center">{{ $t("Si no tiene la App, puede descargarla aqui") }}:</span>
          <v-col cols="5" md="5" lg="5">
            <table>
              <tr>
                <td style="margin-inline: auto;">
                  <img alt="Descargar en Google&nbsp;Play" :src="imgGooglePlay" width="115" >
                </td>
                <td style="margin-inline: auto;" class="pl-2">
                  <img alt="Descargar en Apple&nbsp;Store" :src="imgAppleStore" width="115">
                </td>
              </tr>
              <tr>
                <td style="margin-inline: auto;" class="pl-4">
                  <p><a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=es_VE&gl=US"><img alt="Descargar en Google&nbsp;Play" :src="imgGoogleAuth" width="40" ></a></p>
                  <p><a href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=es_VE&gl=US"><img alt="Descargar en Google&nbsp;Play" :src="imgAuthy" width="40" ></a></p>
                </td>
                <td style="margin-inline: auto;" class="pl-4">
                  <p><a href="https://apps.apple.com/es/app/google-authenticator/id388497605"><img alt="Descargar en Apple&nbsp;Store" :src="imgGoogleAuth" width="40" ></a></p>
                  <p><a href="https://apps.apple.com/es/app/authy/id494168017"><img alt="Descargar en Apple&nbsp;Store" :src="imgAuthy" width="40" ></a></p>
                </td>
              </tr>
            </table>
          </v-col>
        </v-col>
        <v-col cols="12" md="11" class="divcol " style="margin-inline: auto">
          <span class="h8-em" style="text-align: center">{{ $t("Escanee el código QR con Google authenticator, luego ingrese el código que le aparece para") }}<strong>{{ $t("Activar") }}.</strong></span>
        </v-col>
      </v-row>

      <v-col md="12" lg="10" style="margin-inline: auto" class="mt-5">
        <!--<v-card
          v-for="(item, index) in dataPerfil"
          v-bind:key="index"
          class="containerSwap2"
          :class="{menosmargin: item.margen}"
          style="position: relative; margin-bottom:.5em !important"
        >
          <span class="flotingText2 h8">{{ $t(item.title) }}</span>
          <v-col class="paddvertical">
            <div class="divrow" style="justify-content: space-between; align-items: center; margin-bottom: 5px">
                <v-text-field
                  v-model="codigo"
                  :type="item.type"
                  :hide-details="hideDetails"
                  background-color="transparent"
                  solo
                  flat
                  required
                >
                  <template v-slot:label>
                    <span class="h8-em">{{ $t(item.label) }}</span>
                  </template>
                </v-text-field>

              <span id="botonEditar" class="h9-em">
                <v-icon medium color="#ffff">mdi-barcode</v-icon>
              </span>
            </div>
          </v-col>
          <span v-if="messageError" style="color:red">{{ messageError }} </span>
        </v-card>-->


        <template>
          <v-col md="12" lg="10" style="margin-inline: auto" class="mt-5">
            <v-card
              class="containerSwap2"
              style="position: relative; margin-bottom:.5em !important"
            >
              <span class="flotingText2 h8">{{ $t("Llave") }}</span>
                <v-col class="pt-3">
                  <div class="divrow center" style=" align-items: center; ">
                    <div id="app" style="background-color: #ffff">
                      <CodeInput ref="code" :loading="loagding" class="input" v-on:complete="Activar2Fa" />
                    </div>
                  </div>
                </v-col>
            </v-card>
          </v-col>
        </template>
        
        

        

    
        
        <!--<aside class="botonFinal">
          <v-btn
            class="botonSwap h7-em"
            :loading="FormCargando"
            :disabled="FormCargando"
            @click="Activar2Fa()"
          >
            {{ $t("Activar") }}
          </v-btn>
        </aside>-->
        
      </v-col>
    </v-card>
  </section>
</template>

<script>
import Alert from "@/pages/app/Alert/Alert.vue"
import axios from "axios";
import CodeInput from "vue-verification-code-input";

export default {
  name: "dosfa",
  i18n: require("./i18n"),
  components: {
    Alert,
    CodeInput,
  },
  data() {
    return {
      valid: true,
      messages: 0,
      hideDetails: true,
      margen: true,
      dataPerfil: [
        {
          title: "Codigo",
          type: "numeric",
          label: "LabelCodigo",
          margen: true
        }
      ],
      qr: null,
      codigo: null,
      imgGooglePlay: require("@/assets/app/descarga_google.png"),
      imgAppleStore: require("@/assets/app/descarga_apple.png"),
      imgGoogleAuth: require("@/assets/app/googleauth.png"),
      imgAuthy: require("@/assets/app/authy.png"),
      //codigo: null,
      messageError: null,
      loagding: false,
    }
  },
  methods: {
    /*onChange(v) {
      console.log("onChange ", v);
    },
    onComplete(v) {
      this.codigo = v;
      console.log(this.codigo);
      console.log("onComplete ", v);
    },*/
    async Activar2Fa(codigo) {
      //this.codigo = v;
      //let rul_requid = !!this.codigo || 'Este campo es requerido';
      //let rul_length = (this.codigo && this.codigo.length == 6) || 'El codigo debe tener 6 caracteres numericos';
      //this.FormCargando = true;
      //if(rul_requid == true && rul_length == true) {
        //this.messageError = null;
        this.loagding = true;
        let data = {
          defixId: this.$parent.dataUser.defixId,
          mnemonic: this.$parent.dataUser.mnemonic,
          code: codigo,
        }
        await axios
          .post(process.env.VUE_APP_API_BASE_URL + "activar-2fa", data)
          .then((response) => {
            console.log(response.data);
            switch (response.data.respuesta) {
              case "ok": {
                this.$parent.$refs.perfil.dosfa = true;
                this.$refs.alert.alertSuccess = true;
                this.$refs.alert.Titulo = this.$t("Exito");
                this.$refs.alert.Alerta = this.$t("Se activo con exito el 2FA");
                this.volverAtras();
              }
                break;
              case "code": {
                this.$refs.alert.alertWarning = true;
                this.$refs.alert.Titulo = this.$t("Advertencia");
                this.$refs.alert.Alerta = this.$t("Su codigo 2fa no es valido");
              }
                break;
              case "secret": {
                this.$refs.alert.alertCancelled = true;
                this.$refs.alert.Titulo = this.$t("Error");
                this.$refs.alert.Alerta = this.$t("Ocurrio un error inesperado por favor vuelva a intentarlo mas tarde !!!");
              }
                break;
              default: {
                this.$refs.alert.alertCancelled = true;
                this.$refs.alert.Titulo = this.$t("Error");
                this.$refs.alert.Alerta = this.$t("Ocurrio un error inesperado por favor vuelva a intentarlo mas tarde !!!");
              }
                break;
            }
            this.loagding = false;
            this.$refs.code.values = ['','','','','',''];
          })
          .catch(() => {
            this.loagding = false;
            this.$refs.code.values = ['','','','','',''];
          });
      /*} else {
        this.FormCargando = false;
        if(rul_requid == true) {
        this.messageError = rul_length;
        } else {
          this.messageError = rul_requid;
        }
      }*/
    },
    clearCard() {
      this.$parent.Dapps = false;
      this.$parent.Historico = false;
      this.$parent.Fiat = false;
      this.$parent.EstadoTransaccion = false;
      this.$parent.Perfil = false;
      this.$parent.Cartera = false;
      this.$parent.Portafolio = false;
      this.$parent.ExchangeCard = false;
    },
    volverAtras() {
      this.clearCard()
      if (window.innerWidth <= 880) {
        this.$parent.Perfil = true;
        this.$parent.dosfa = false;
        //
        this.$parent.leftCard = true;
        this.$parent.centerCard = false;
        this.$parent.rightCard = false;
      } else {
        this.$parent.Perfil = true;
        this.$parent.dosfa = false;
      }
    },
  }
};
</script>

<style src="./Left.scss" lang="scss"></style>
<style scoped>
.containerLineas {
  position: relative;
  padding-block: 20px;
}
.containerLineas::after {
  content: '';
  top: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
}
.containerLineas::before {
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
}
</style>
<template>
  <section id="fiat" class="containerCartas">
    <ModalLeft ref="menu"></ModalLeft>
    <ModalCryptoc ref="modalcryptoc"></ModalCryptoc>
    <ModalDosFa ref="modaldosfa"></ModalDosFa>
    <Alert ref="alert"></Alert>
    <v-window v-model="step" touchless>  

      <!-- Acceso -->
      <v-window-item :value="1">
        <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center" class="containerTitulo">
          <v-col class="paddvertical divcol">
            <span class="titulo h6-em">{{ $t(this.mensaje) }}
            </span>
          </v-col>
        </v-col>
        <v-card class="cartaInside cartaIE">
          <v-card-text class="pt-4">
      
            <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
              <span v-if="emaillogin.email==''" style="color:red !important" class="h7-em">
                  {{ $t("Falta Email. Debe registrar el email en la opción de perfil.") }}</span>
              <v-form v-else v-model="valid" ref="form">
                <!--v-text-field label="User" v-model="user" disabled></!--v-text-field>
                            <v-text-field-- 
                              label="e-mail address" 
                              v-model="emaillogin.email"
                              :rules="emailRules"
                              disabled> 
                            </v-text-field-->
                <span v-if="emaillogin.email==''" style="color:red !important" class="h7-em">
                  {{ $t("Falta Email. Debe registrar el email en la opción de perfil.") }}</span>
                <v-text-field v-model="password" min="8" :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="passwordRules"
                  required :type="e1 ? 'text' : 'password'" name="input-10-1" label="Enter your password"
                  @click:append="e1 = !e1">
                </v-text-field>
      
                <v-text-field v-if="!existe" v-model="ConfirmPassword" min="8" :append-icon="e1c ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="ConfirmPasswordRules" required :type="e1c ? 'text' : 'password'" name="input-10-1"
                  label="Confirm Password" @click:append="e1c = !e1c">
                </v-text-field>
      
                <v-layout justify-space-between>
                  <v-col cols="11" md="11" lg="10" class="botonFinalEnviar" style="margin-inline: auto">
                    <v-btn class="h8-em botonSwap" :disabled="!valid" @click="createpass()" :loading="loading">
                      {{ $t("Login") }}
                    </v-btn>
                  </v-col>
                </v-layout>
              </v-form>
            </v-col>
      
          </v-card-text>
        </v-card>
      
      </v-window-item>

      <!-- seleccionar pais -->
      <v-window-item :value="2">
        <v-row v-if="kycAprobado" style="margin-inline: auto;margin-top: 25px !important;">
            <v-col cols="6" md="4" lg="4" class="contCompraVenta" style="margin-inline: auto;">
              <v-btn block class="h8-em" @click="OpenKYC()" :loading="loading"  style="background-color: #6667AB; color: #FFFF;
                     float:center;
                                   border-radius: 30px; padding: 1.1em; text-transform: none !important">
                {{ $t("DocumentosKYC") }}
              </v-btn>
            </v-col>
            <v-col cols="6" md="4" lg="4" class="contCompraVenta divcol center" style="margin-inline: auto">
              <v-btn block class="h8-em mr-5" @click="OpenHistorico()" style="background-color: #6667AB; color: #FFFF;  float:center;
                                   border-radius: 30px; padding: 1.1em; text-transform: none !important">
                {{ $t("Historial de Transacciones") }}
              </v-btn>
            </v-col>
          </v-row>

        <v-card class="cartaInside cartaIE">
          <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
            <div class="divrow" style="justify-content: space-between; align-items: center">
              <span class="titulo h6-em paddtop paddbottom">{{ $t("Selecciona el País") }}</span>
              <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB" @click="VolverAtras()">
                <v-icon medium>mdi-arrow-left-thin</v-icon>
              </v-btn>
            </div>
          </v-col>
      
          <v-col cols="11" md="11" lg="9" class="contPais divcol" style="margin-inline: auto ;" v-if="kycAprobado">
            <v-card v-for="(item, index) in data" :key="index" class="divcol mb-5">
              <v-row>
                <v-col cols="10" v-if="item.estatus === 'A'">
                  <span class="h6-em">{{item.nombre}} </span>
                  <span class="h8-em">{{item.coin}}</span>
                </v-col>
      
              </v-row>
              <div class="divcol" style="cursor:pointer;">
                <!--v-img :src="'http://127.0.0.1:8000'+item.imagen" style="border-radius: 15px !important;" alt="Pais" width="100%"
                        height="90%" @click="selectCountry1(item)" v-if="item.estatus === 'A'">
                      </!v-img-->
                <v-img :src="item.imagen" style="border-radius: 15px !important;" max-height="90%"
                  @click="selectCountry1(item)" v-if="item.estatus === 'A'">
                </v-img>
              </div>
            </v-card>
          </v-col>
          <v-col cols="11" md="11" lg="9" class=" " style="margin-inline: auto ;" v-else>
            <span class="h6-em">{{ $t("Bienvenidos a la opcion de FIAT. Le invitamos a gestionar el proceso de KYC para su Pais. Para ello puede dar clic aqui en el boton con la etiqueta KYC") }} </span>

            <v-col cols="11" md="11" lg="10" class="contCompraVenta" style="margin-inline: auto;">
              <v-btn block class="h8-em" @click="OpenKYC()" :loading="loading" style="background-color: #6667AB; color: #FFFF;
                     float:center;
                                   border-radius: 30px; padding: 1.1em; text-transform: none !important" > 
                {{ $t("DocumentosKYC") }}
              </v-btn>
            </v-col>
          </v-col>
      
          <v-row v-if="kycAprobado" style="margin-inline: auto;margin-top: 25px !important;">
            <v-col cols="6" md="4" lg="4" class="contCompraVenta" style="margin-inline: auto;">
              <v-btn block class="h8-em " @click="CompraVenta('comprar')" style="background-color: #6667AB; color: #FFFF; 
                     float:center;
                                   border-radius: 30px; padding: 1.1em; text-transform: none !important"
                :disabled="isActiveCompra">
                {{ $t("Comprar") }}
              </v-btn>
            </v-col>
            <v-col cols="6" md="4" lg="4" class="contCompraVenta divcol center" style="margin-inline: auto">
              <v-btn block class="h8-em mr-5" @click="CompraVenta('vender')" style="background-color: #6667AB; color: #FFFF;  float:center;
                                   border-radius: 30px; padding: 1.1em; text-transform: none !important"
                :disabled="isActiveVenta">
                {{ $t("Vender") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-window-item>

      <!-- seleccionar crypto -->
      <v-window-item :value="4">
        <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center" class="containerTitulo">
          <v-col class="paddvertical divcol">
            <span class="titulo h6-em">Cripto</span>
            <span class="desc h7-em">{{ $t("Fiat") }}</span>
          </v-col>
        </v-col>

        <v-card class="cartaInside cartaIE">
          <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
            <div class="divrow" style="justify-content: space-between; align-items: center">
              <span class="titulo h6-em paddtop paddbottom">{{ $t("Selecciona la criptomoneda") }}</span>
              <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB"
                @click="(step=2)">
                <v-icon medium>mdi-arrow-left-thin</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col class="contCripto" cols="11" md="11" lg="10" style="margin-inline: auto">
            <v-card v-for="(item, index) in dataCrypto" :key="index" @click="selectCripto(item)"
              color="transparent" style="display:flex" class="space">
              <aside class="divrow" style="gap: clamp(.2em, .5vw, .5em)">
                <img class="token" :src="item.token" alt="token" />
                <div class="divcol" style="justify-content: center">
                  <span class="desc h7-em">{{ item.title }}</span>
                  <span class="h8-em descStyle">{{ item.desc }}</span>
                </div>
              </aside>

              <aside class="divcol">
                <span class="h7-em semibold">$ {{balanceUSDT}}</span>
              </aside>
            </v-card>
          </v-col>
        </v-card>
      </v-window-item>

      <!-- comprar -->
      <v-window-item :value="5">
        <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center" class="containerTitulo">
          <v-col class="paddvertical divcol">
            <span class="titulo h6-em">{{ $t("Compra") }}</span>
            <span class="desc h7-em"> {{llaves.crypto}}</span>
          </v-col>
          <div class="divrow center">
          </div>
        </v-col>

        <v-card class="cartaInside cartaIE">
          <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
            <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB; margin-left: auto"
              @click="OpenCryptocDefix()">
              <v-icon medium>mdi-arrow-left-thin</v-icon>
            </v-btn>
          </v-col>
          <v-form v-model="validC" ref="form">
          <v-col class="contComprar" cols="11" md="11" lg="10" style="margin-inline: auto; padding-bottom: 0">
            <div class="space semibold" style="gap: clamp(.5em, 5vw, .8em)">
              <span class="h8-em">{{ $t("Cantidad en Fiat") }}</span>
            </div>
            <v-text-field
              v-model="datosCompra.cantidad"
              type="number"
              hide-spin-buttons
              background-color="transparent"
              label="cantidad"
              solo
              flat
              reverse
              :rules="[rules_compra.required, rules_compra.min]"
            >
              <template v-slot:append>
                <span class="h8-em" style="color: #747373 !important">{{llaves.coin}}</span>
              </template>
              <template v-slot:label>
                <span class="h7-em">0.00</span>
              </template>
            </v-text-field>
          </v-col>

          <v-col class="divcol" cols="11" md="11" lg="10"  style="gap: clamp(.5em, 5vw, .8em);
            margin-inline: auto; color: #707070; padding-inline: 24px">
            <div class="space semibold" style="gap: clamp(.5em, 5vw, .8em)">
              <span class="h8-em">{{ $t("Cantidad en criptomoneda") }}</span>
              <span class="h8-em">{{parseFloat(datosCompra_crypto).toFixed(2)}} {{llaves.crypto}}</span>
            </div>
            <div class="space semibold" style="gap: clamp(.5em, 5vw, .8em)">
              <span class="h8-em">{{ $t("Monto a Depositar") }}</span>
              <span class="h8-em">
                <span style="color: #000000">{{ parseFloat(datosCompra.cantidad).toFixed(2)}}</span> {{llaves.coin}}
              </span>
            </div>
          </v-col>

          <v-col cols="11" md="11" lg="10" class="botonFinalEnviar" style="margin-inline: auto">
            <v-btn class="h8-em botonSwap" :disabled="!validC" @click="step = 8">
              {{ $t("Siguiente") }}
            </v-btn>
          </v-col>
          </v-form>

        </v-card>
      </v-window-item>

      <!-- vista previa (comprar) -->
      <v-window-item :value="8">
        <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center" class="containerTitulo">
          <v-col class="paddvertical divcol">
            <span class="titulo h6-em">{{ $t("Vista previa") }}</span>
            <span class="desc h7-em">{{ $t("Compra") }}</span>
          </v-col>
          <div class="divrow center">
          </div>
        </v-col>

        <v-card class="cartaInside cartaIE vistaPreviaReduccion">
          <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
            <div class="divrow" style="justify-content: space-between; align-items: center; gap: 1em">
              <span class="titulo h6-em paddtop paddbottom">
                {{ $t("Confirma tu Compra") }}
              </span>
              <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB"
                @click="(step = 5)">
                <v-icon medium>mdi-arrow-left-thin</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col class="contVistaPreviaCompra divcol" cols="11" md="11" lg="10" style="margin-inline: auto">
            <aside class="divcol">

              <div class="space">
                <span class="h7-em">{{ $t("MontoaPagar") }}</span>
                <span class="h7-em">{{llaves.coin}}  {{parseFloat(datosCompra.cantidad).toFixed(2)}}</span>
              </div>
              <div class="space">
                <span class="h7-em">{{ $t("comision") }}</span>
                <span class="h7-em">{{llaves.coin}}  {{parseFloat(llaves.comision).toFixed(2)}}</span>
              </div>
              <div class="space bold">
                <span class="h7-em">{{ $t("TotalaPagar") }}</span>
                <span class="h7-em">{{llaves.coin}}  {{parseFloat(previoCompra).toFixed(2)}}</span>
              </div>

              <div class="space">
                <span class="h7-em">{{ $t("Cripto a recibir") }}</span>
                <span class="h7-em">{{ parseFloat(datosCompra_crypto).toFixed(2)}} {{llaves.crypto}}</span>
              </div>
              <div class="space">
                <span class="h7-em">{{ $t("Método de Pago") }}</span>
                <span class="h7-em">{{datosCompra.tipopago}}</span>
              </div>
              <div class="space">
                <span class="h7-em">{{ $t("Banco") }}</span>
                <span class="h7-em">{{datosCompra.banco}}</span>
              </div>
              <div class="space">
                <span class="h7-em">{{ $t("Nombre Titular") }}</span>
                <span class="h7-em">{{datosCompra.titular}}</span>
              </div>
              <div class="space">
                <span class="h7-em">{{ $t("Id Titular") }}</span>
                <span class="h7-em">{{datosCompra.cedula}}</span>
              </div>
              <div class="space">
                <span class="h7-em">{{ $t("Telefono Titular") }}</span>
                <span class="h7-em">{{datosCompra.telefono}}</span>
              </div>
              <div class="space" v-if="datosCompra.numerocuenta">
                <span class="h7-em">{{ $t("Nro. Cuenta") }}</span>
                <span class="h7-em">{{datosCompra.numerocuenta}}</span>
              </div>
            </aside>

            <aside class="space semibold">
              <span class="h7-em">{{ $t("Tasa por") }} 1 {{llaves.crypto}}</span>
              <span class="h7-em">{{parseFloat(llaves.tasa).toFixed(2)}} {{llaves.coin}}</span>
            </aside>
          </v-col>

          <v-col cols="11" md="11" lg="10" class="botonFinalEnviar" style="margin-inline: auto">
            <v-btn class="h8-em botonSwap" @click="llaves.referencia=random(),step++,timerCount= 60,play()">
              {{ $t("Confirmar") }}
            </v-btn>
          </v-col>
        </v-card>
      </v-window-item>

      <!-- transfiere (comprar) -->
      <v-window-item :value="9">
        <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center; padding-top: 30px" class="containerTitulo">
          <v-col class="paddvertical divcol">
            <span class="titulo h6-em">{{ $t("Realiza el pago") }}</span>
            <span class="desc h7-em">{{ $t("Datos") }}</span>
          </v-col>
          <div class="divrow center">
            <!--<v-badge
              :content="messages"
              :value="messages"
              color="red"
              overlap
              bordered
            >
              <v-icon color="#6667AB" medium>mdi-bell</v-icon>
            </v-badge> -->
            <!-- <img class="user" src="@/assets/app/user.png" alt="token" /> -->
          </div>
        </v-col>

        <v-card class="cartaInside cartaTransfiere">
          <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
            <span class="titulo h7-em paddtop paddbottom normal" style="line-height: 1.2 !important">
              {{ $t("Transfiere a la siguiente cuenta e ingresa el número de referencia") }}
            </span>
          </v-col>

          <v-col class="contTransfiere divcol padd" cols="11" md="11" lg="10" style="margin-inline: auto">
                <!--span class="h8-em semibold" style="color: #CAC8CA">{{llaves.referencia}}</!--span-->
                <span class="h6-em semibold center" >{{llaves.referencia}}</span>



            <aside class="countDown center">
              <span class="h6-em">{{ timerCount }}</span>
            </aside>

            <aside class="divcol semibold">
              <div class="divcol">
                <div class="space bold">
                  <span class="h7-em">{{ $t("TotalaPagar") }}</span>
                  <span class="h7-em">{{parseFloat(previoCompra).toFixed(2)}} {{llaves.coin}}</span>
                </div>
                <div class="space">
                  <span class="h8-em">{{ $t("Transferir a") }}</span>
                  <span class="h8-em">{{datosCompra.titular}}</span>
                </div>
              </div>

              <div class="divcol">


                <div class="space">
                <span class="h8-em">{{ $t("Método de Pago") }}</span>
                <span class="h8-em">{{datosCompra.tipopago}}</span>
              </div>
              <div class="space">
                <span class="h8-em">{{ $t("Banco") }}</span>
                <span class="h8-em">{{datosCompra.banco}}</span>
              </div>
              <div class="space">
                <span class="h8-em">{{ $t("Id Titular") }}</span>
                <span class="h8-em">{{datosCompra.cedula}}</span>
              </div>
              <div class="space">
                <span class="h8-em">{{ $t("Telefono Titular") }}</span>
                <span class="h8-em">{{datosCompra.telefono}}</span>
              </div>
              <div class="space" v-if="datosCompra.numerocuenta">
                <span class="h8-em">{{ $t("Nro. Cuenta") }}</span>
                <span class="h8-em">{{datosCompra.numerocuenta}}</span>
              </div>
              </div>
            </aside>
          </v-col>

          <v-col cols="11" md="11" lg="10" class="divcol" style="margin-inline: auto; gap: .5em">
            <span class="h9-em" style="color: #FF0000; text-align: center">
              {{ $t("Transfiere dentro del tiempo establecido, de no ser así se cancelarala") }} 
              {{ $t("transacción automáticamente") }}
            </span>

            <span class="h9-em" style="text-align: center">
              {{ $t("inicia sesión en tu cuenta de pago, realiza la transferencia") }}, 
              {{ $t("coloca el número de referencia y confirma") }}
            </span>
          </v-col>

          <v-col cols="11" md="11" lg="10" class="contBotonesFiat botonFinalEnviar space"
            style="margin-inline: auto; flex-direction: row-reverse">
            <v-btn class="h8-em" @click="CreaFiatTransaccion()">
              {{ $t("Confirmar") }}
            </v-btn>
            <v-btn class="h8-em" @click="step--">
             {{ $t("Cancelar") }} 
            </v-btn>
          </v-col>
        </v-card>
      </v-window-item>

      <!-- vender -->
      <v-window-item :value="10">
        <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center" class="containerTitulo">
          <v-col class="paddvertical divcol">
            <span class="titulo h6-em">{{ $t("Venta") }}</span>
            <span class="desc h7-em">{{llaves.crypto}}</span>
          </v-col>
          <div class="divrow center">
          </div>
        </v-col>

        <v-card class="cartaInside cartaIE">
          <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
            <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB; margin-left: auto"
              @click="OpenCryptoc()">
              <v-icon medium>mdi-arrow-left-thin</v-icon>
            </v-btn>
          </v-col>

          <v-form v-model="validV" ref="form">
          <v-col class="contComprar" cols="11" md="11" lg="10" style="margin-inline: auto; padding-bottom: 0">
            <div class="space semibold" style="gap: clamp(.5em, 5vw, .8em)">
              <span class="h8-em">{{ $t("Cantidad en criptomoneda") }}</span>
            </div>
            <v-text-field 
            v-model="datosVenta.cantidad" 
            type="number" 
            hide-spin-buttons 
            background-color="transparent" 
            solo 
            flat
            reverse 
            @blur="ValidarAmount()" 
            @keyup="ValidarAmount()" 
            :rules="[rules_venta.required, rules_venta.min, rules_venta.max]">
              <template v-slot:append>
                <span class="h8-em" style="color: #747373 !important">{{llaves.crypto}}</span>
              </template>
              <template v-slot:label>
                <span class="h7-em">0.00</span>
              </template>
            </v-text-field>
          </v-col>

          <v-col class="divcol" cols="11" md="11" lg="10"  style="gap: clamp(.5em, 5vw, .8em);
             margin-inline: auto; color: #707070; padding-inline: 24px">
            <div class="space semibold" style="gap: clamp(.5em, 5vw, .8em)">
              <span class="h8-em">{{ $t("Cantidad en Fiat") }}</span>
              <span class="h8-em">{{ parseFloat(datosVenta_crypto).toFixed(2)}} {{llaves.coin}}</span>
            </div>
            <div class="space semibold" style="gap: clamp(.5em, 5vw, .8em)">
              <span class="h8-em">{{ $t("Monto a Vender") }}</span>
              <span class="h8-em">
                <span style="color: #000000">{{parseFloat(datosVenta.cantidad).toFixed(2)}}</span> USDT
              </span>
            </div>

            <div class="space semibold" style="gap: clamp(.5em, 5vw, .8em)">
              <span class="h8-em">{{ $t("Saldo en ETH para GAS") }}</span>
              <span class="h8-em">{{ parseFloat(saldo_ETH)}} {{'ETH'}}</span>
            </div>

            <div class="space semibold" style="gap: clamp(.5em, 5vw, .8em)">
              <span class="h8-em">{{ $t("GAS") }}</span>
              <span class="h8-em">{{ parseFloat(fee_ETH)}} {{'ETH'}}</span>
            </div>

          </v-col>
          <span v-if="messageAmount" style="color:red"> {{ $t(messageAmount) }} mensaje</span>

          <v-col cols="11" md="11" lg="10" class="botonFinalEnviar" style="margin-inline: auto">
            <v-btn class="h8-em botonSwap" :disabled="!validV" @click="llaves.referencia=random(),(step = 13)">
             {{ $t("Siguiente") }}
            </v-btn>
          </v-col>

          </v-form>


        </v-card>
      </v-window-item>

      <!-- vista previa (venta) -->
      <v-window-item :value="13">
        <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center" class="containerTitulo">
          <v-col class="paddvertical divcol">
            <span class="titulo h6-em">{{ $t("Vista previa") }}</span>
            <span class="desc h7-em">{{ $t("Venta") }}</span>
          </v-col>
          <div class="divrow center">

          </div>
        </v-col>

        <v-card class="cartaInside cartaIE vistaPreviaReduccion">
          <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
            <div class="divrow" style="justify-content: space-between; align-items: center; gap: 1em">
              <span class="titulo h6-em paddtop paddbottom">
                {{ $t("Confirma tu Venta") }}
              </span>

              <span class="h6-em semibold center" >Referencia {{llaves.referencia}}</span>

              <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB" :disabled="loadingventa"
                @click="(step=10)">
                <v-icon medium>mdi-arrow-left-thin</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col class="contVistaPreviaCompra divcol" cols="11" md="11" lg="10" style="margin-inline: auto">
            <aside class="divcol">
              <div class="space bold">
                <span class="h7-em">{{ $t("Monto a Vender") }}</span>
                <span class="h7-em">USDT  {{ parseFloat(datosVenta.cantidad).toFixed(2)}}</span>
              </div>
              <div class="space">
                <span class="h7-em">{{ $t("Fiat a recibir") }}</span>
                <span class="h7-em"> {{llaves.coin}}  {{ parseFloat(datosVenta_crypto).toFixed(2)}}</span>
              </div>
              <div class="space">
                <span class="h7-em">{{ $t("comision") }}</span>
                <span class="h7-em"> {{llaves.coin}}  {{ parseFloat(llaves.comision).toFixed(2)}}</span>
              </div>
              <div class="space bold">
                <span class="h7-em">{{ $t("Total a recibir") }}</span>
                <span class="h7-em"> {{llaves.coin}}  {{ parseFloat(previoVenta).toFixed(2)}}</span>
              </div>



              <div class="space">
                <span class="h7-em">{{ $t("Método de Pago") }}</span>
                <span class="h7-em">{{datosVenta.tipopago}}</span>
              </div>
              <div class="space">
                <span class="h7-em">{{ $t("Banco") }}</span>
                <span class="h7-em">{{datosVenta.banco}}</span>
              </div>
              <div class="space">
                <span class="h7-em">{{ $t("Nombre Titular") }}</span>
                <span class="h7-em">{{datosVenta.titular}}</span>
              </div>
              <div class="space">
                <span class="h7-em">{{ $t("Id Titular") }}</span>
                <span class="h7-em">{{datosVenta.cedula}}</span>
              </div>
              <div class="space">
                <span class="h7-em">{{ $t("Telefono Titular") }}</span>
                <span class="h7-em">{{datosVenta.telefono}}</span>
              </div>
              <div class="space" v-if="datosVenta.numerocuenta">

                <span class="h7-em">{{ $t("Nro. Cuenta") }}</span>
                <span class="h7-em">{{datosVenta.numerocuenta}}</span>
              </div>
            </aside>

            <aside class="space semibold">
              <span class="h7-em">{{ $t("Tasa por") }} 1 {{llaves.crypto}}</span>
              <span class="h7-em">{{ parseFloat(llaves.tasa ).toFixed(2)}} {{llaves.coin}}</span>
            </aside>
          </v-col>

          <v-col cols="11" md="11" lg="10" class="botonFinalEnviar" style="margin-inline: auto">
            <v-btn class="h8-em botonSwap" :loading="loadingventa" @click="EnviarCoin()">
              {{ $t("Confirmar") }}
            </v-btn>
          </v-col>
        </v-card>
      </v-window-item>

      <!-- en proceso -->
      <v-window-item :value="20">
        <section style="background-color: #FFFFFF" class="contEnProceso divcol center">
          <div style="margin-inline: auto; gap: 1em" class="firstcont divcol center">
            <span class="h5-em semibold" style="color: #6667AB">{{ $t("Tu orden está en proceso") }}</span>
            <img src="@/assets/app/clock.png" alt="Clock icon">
            <p class="h8-em normal p" style="line-height: 1.4 !important">
              {{ $t("Puedes verificar el estado del proceso y sus detalles en la sección de Historial de Transacciones") }}
            </p>
          </div>

          <div class="contBotonesEnProceso botonFinalEnviar"
            style="margin-inline: auto">
            <v-btn class="h8-em" @click="step = 1">
              {{ $t("Entendido") }}
            </v-btn>
            <v-btn class="h8-em" @click="OpenHistorico()">
              {{ $t("Ver estado") }}
            </v-btn>
          </div>
        </section>
      </v-window-item>

      <!-- orden cancelada  -->
      <v-window-item :value="21">
        <section style="background-color: #FFFFFF" class="contEnProceso center">
          <div style="margin-inline: auto; gap: 1em" class="firstcont divcol center">
            <span class="h5-em semibold" style="color: #6667AB">{{ $t("Tu orden ha sido cancelada") }}</span>
            <img src="@/assets/app/cancelled.png" alt="Clock icon">
            <p class="h8-em normal p" style="line-height: 1.4 !important">
              {{ $t("Puedes verificar el estado del proceso y sus detalles en la sección de Historial de Transacciones") }}
            </p>
          </div>

          <div class="contBotonesEnProceso botonFinalEnviar"
            style="margin-inline: auto">
            <v-btn class="h8-em" @click="(step = 2)">
              {{ $t("Entendido") }}
            </v-btn>
            <v-btn class="h8-em" @click="VerEstado()">
              {{ $t("Ver estado") }}
            </v-btn>
          </div>
        </section>
      </v-window-item>
    </v-window>
        <!-- SNACKBAR MENSAJES -->
        <v-snackbar v-model="snackbar" right top absolute :color="color" :multi-line="multiLine" elevation="24">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <!-- FIN SNACKBAR -->
  </section>
</template>

<script>
//import Chart from "@/pages/app/Left/Chart.vue"
import ModalLeft from "./ModalLeft"
import ModalCryptoc from "@/pages/app/Left/ModalCryptoc.vue"
import ModalDosFa from "@/pages/app/ModalDosFa/ModalDosFa.vue"
import Alert from "@/pages/app/Alert/Alert.vue"
import axios from "axios";
import { i18n } from "@/plugins/i18n";
import {
  mdiTimerSand ,
  mdiThumbUpOutline,
  mdiFingerprintOff,
  mdiCropFree ,
  } from '@mdi/js'

export default {
  name: "Fiat",
  i18n: require("./i18n"),
  components: {
    // Chart
    ModalLeft,
    ModalCryptoc,
    ModalDosFa,
    Alert,
  },
  props: {
    balanceUSDT: {
      type: Number,
      default: 0
    }
  },
  computed: {
    datosCompra_crypto() {
      return parseFloat(this.datosCompra.cantidad / this.llaves.tasa).toFixed(2)
    },
    datosVenta_crypto() {
      return parseFloat(this.datosVenta.cantidad * this.llaves.tasa).toFixed(2)
    },
    saldo_ETH() {
      return parseFloat(this.$parent.dataCartera[this.$parent.dataCartera.findIndex(x => x.title == "ETH")].amountToken)
    },  
    fee_ETH() {
      return parseFloat(this.totalFee)
    },  
    previoCompra(){
      return parseFloat(this.datosCompra.cantidad*1 + this.llaves.comision*1).toFixed(2)
    },
    previoVenta(){
      return parseFloat((this.datosVenta.cantidad * this.llaves.tasa)- this.llaves.comision).toFixed(2)
    },
  },
  data() {
    return {
      loadingventa:false,
      email:'',
      CreaTransaccionFiat:false,
      messageAmount:'',
      amount:0,
      validar_amount:false,
      fee: 0,
      feeEnvio: 0,
      totalFee: 0,
      disabledEnvio: true,
      dataCoin: {
        title:'USDT',
        amountToken:0,
      },
      FormCargando:false,

      icons: {
        mdiTimerSand,
        mdiThumbUpOutline,
        mdiFingerprintOff,
        mdiCropFree,
      },
      observacion: '',
      isActiveCompra: true,
      isActiveVenta: true,
      data: [],
      step: 1,
      llaves: {
        accion: "",
        pais: "",
        paisnombre: "",
        tasa: 0,
        monto: 0,
        montomin: 0,
        tasaaltomonto: 0,
        tasav: 0,
        montov: 0,
        montominv: 0,
        tasaaltomontov: 0,
        coin: '',
        kyccabecera: "",
        banco: "",
        crypto: "",
        comision: 0,
        referencia: 0,
      },
      datosCompra: {
        cantidad: 0,
        cuentaEnvio: null,
        id: '',
        bancoId: '',
        banco: '',
        tipopagoId: '',
        tipopago: '',
        titular: '',
        cedula: '',
        telefono: '',
        numerocuenta: '',
        tipocuenta: '',
      },
      datosVenta: {
        cantidad: 0,
        id: '',
        bancoId: '',
        banco: '',
        tipopagoId: '',
        tipopago: '',
        titular: '',
        cedula: '',
        telefono: '',
        numerocuenta: '',
        tipocuenta: '',
      },
      dataCoinHistorico: {},
      dataHistorico: [],
      // balanceUSDT: 0,//this.$parent.home.balanceUSDT,
      loading:false,
      dataPais: [
        {
          key: "vnz",
          img: require("@/assets/app/idioma/vnz.png"),
          country: "Venezuela",
          coin: "Bolivares (Bs)",
          active: false
        },
        {
          key: "pnm",
          img: require("@/assets/app/idioma/panama.png"),
          country: "Panamá",
          coin: "Dólar (USD)",
          active: false
        },
        {
          key: "us",
          img: require("@/assets/app/idioma/us.png"),
          country: "United States",
          coin: "Dólar (USD)",
          active: false
        },
      ],
      dataBanco: {
        frecuentes: [
          {
            key: "banesco",
            img: require("@/assets/app/bancos/banesco.png"),
            banco: "Banesco",
            amount: "4.735 Bs"
          },
          {
            key: "provincial",
            img: require("@/assets/app/bancos/provincial.png"),
            banco: "Provincial",
            amount: "5.00 Bs"
          },
          {
            key: "mercantil",
            img: require("@/assets/app/bancos/mercantil.png"),
            banco: "Mercantil",
            amount: "4.99 Bs"
          },
        ]
      },
      dataCrypto: [

        {
          key: "usdt",
          token: require("@/assets/app/token/usdt.svg"),
          title: "USDT (ERC20)",
          desc: "Tether",
          amount: this.balanceUSDT ,
          state: "+0,05",
          colorG: true,
          colorR: false,
        },
      ],
      rules_compra: {
        required: value => !!value || 'Required.',
        // hay que colocar mensaje de monto minimo (este solo es un ejemplo del mensaje)
        min: v => v >= this.llaves.montomin || 'Monto min. es de '+this.llaves.montomin,
      },
      dataCuentas_compra: [
        {
          name: "Diego Hernández",
          active: false
        },
        {
          name: "Ana María Pérez",
          active: false
        },
      ],
      crearCuenta_compra: {
        name: "",
        tipe: "V",
        id: "",
        active: false,
      },
      timerEnabled: true,
      timerCount: 60,
      dataProgress_compra: [
        {
          progress: "Creada",
          active: false,
          passed: true,
        },
        {
          progress: "Asignada",
          active: false,
          passed: true,
        },
        {
          progress: "Procesando",
          active: false,
          passed: true,
        },
        {
          progress: "Completada",
          active: true,
        },
      ],
      progress_compra: {
        asignada: false,
        procesando: false,
        completada: true,
      },
      rules_venta: {
        required: value => !!value || 'Required.',
        // hay que colocar mensaje de monto minimo (este solo es un ejemplo del mensaje)
         min: v => v >= this.llaves.montomin || 'Monto min. es de '+this.llaves.montomin,
         max: v => v <= this.balanceUSDT|| 'Monto max. al disponible de '+this.balanceUSDT,
         //min: v => {
      //if (!v.trim()) return true;
     // if (!isNaN(parseFloat(v)) && v >= 0 && v <= this.llaves.montomin) return true;
     // return 'Monto min. es de '+this.llaves.montomin;
    //},


      },
      dataCuentas_venta: [
        {
          name: "Diego Hernández",
          active: false
        },
        {
          name: "Ana María Pérez",
          active: false
        },
      ],
      crearCuenta_venta: {
        name: "",
        numeroCuenta: null,
        banco: "Seleccione el Banco",
        tipe: "V",
        id: null,
        active: false,
      },
      dataProgress_venta: [
        {
          progress: "Creada",
          active: false,
          passed: true,
        },
        {
          progress: "Asignada",
          active: false,
          passed: true,
        },
        {
          progress: "Procesando",
          active: false,
          passed: true,
        },
        {
          progress: "Completada",
          active: true,
        },
      ],
      progress_venta: {
        asignada: false,
        procesando: false,
        completada: true,
      },
      lang: [
        {
          title: "English",
          to: "#",
          value: "US",
        },
        {
          title: "Español",
          to: "#",
          value: "ES",
        },
      ],
      mensaje:'',
      user:'',
      emaillogin:{
        email:localStorage.getItem('email'),},
      existe:false,
      wallet:JSON.parse(localStorage.getItem('dataUser')).usdt_credentials.address,

      accesa:'',
      e1: false,
      e1c: false,
      password: '',
      ConfirmPassword: '',
      passwordRules: [ (v) => !!v || 'Password is required', ],
      ConfirmPasswordRules : [
        (v) => !!v || 'type confirm password',
        (v) =>
          v === this.password || 'The password confirmation does not match.',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      valid: true,
      validC: true,
      validV: true,
      kycAprobado: true,
    };
  },
  mounted() {
    // this.$emit("prueba", 23)
    //this.$parent.balanceUSDT = 23
    this.changeLanguaje(localStorage.language);
    this.$refs.modalcryptoc.ModalCreaContrasena = true
    //this.fecthDataPais()
    this.user = JSON.parse(localStorage.getItem('dataUser')).defixId;
    this.sesionactiva()
    this.validuser()
  },
  // temporizador
//  watch: {
//    timerCount: {
//      handler(value) {
//        if (value > 0) {
//          setTimeout(() => {
//            this.timerCount--;
//          }, 1000);
//        }
//      },
//      immediate: true // This ensures the watcher is triggered upon creation
//    }
//  },

  watch: {
    timerEnabled(value) {
        if (value) {
            setTimeout(() => {
                this.timerCount--;
            }, 1000);
        }
    },

    timerCount: {
        handler(value) {

            if (value > 0 && this.timerEnabled) {
                setTimeout(() => {
                    this.timerCount--;
                }, 1000);
            }

        },
        immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  methods: {
    changeLanguaje(lang) {
      if (lang === "ES") {
        localStorage.setItem('language', lang)
        //localStorage.language = lang;
        i18n.locale = lang;
      } else {
        localStorage.setItem('language', lang)
        //localStorage.language = lang;
        i18n.locale = lang;
      }
    },
    validate () {
        this.$refs.form.validate()
      },
    play() {
                this.timerEnabled = true;
            },
    random() {
      return Math.floor((Math.random() * (10000000000 - 100000000 + 1)) + 100000000);
    },
    fecthDataPais() {
        this.user = JSON.parse(localStorage.getItem('dataUser')).defixId;
        //axios.get( process.env.VUE_APP_API_BASE_AXIOS + '/get-paises/'+this.user+' ').then(response => {
        axios.get( process.env.VUE_APP_API_BASE_AXIOS + '/get-paises').then(response => {
        this.data = response.data
        this.kycAprobado = false
        response.data.forEach(element => {
        if(element.estatus === 'A'){
           this.kycAprobado = true
        }
        })

      }).catch(err => {
        console.log(err)
      })
    },
    OpenCryptoc() {
      //CompraVenta('vender')"
      this.$refs.modalcryptoc.fecthDatac(this.llaves.kyccabecera, this.llaves.pais)
      this.$refs.modalcryptoc.Fix = true;
      this.$refs.modalcryptoc.ModalCryptoc = true;
      setTimeout(() => {
        this.$refs.modalcryptoc.Fix = false;
      }, 1000);
    },
    OpenCryptocDefix() {
      //CompraVenta('comprar')"
      this.$refs.modalcryptoc.fecthDatacDefix(this.llaves.pais)
      this.$refs.modalcryptoc.Fix = true;
      this.$refs.modalcryptoc.ModalCryptocDefix = true;
      setTimeout(() => {
        this.$refs.modalcryptoc.Fix = false;
      }, 1000);
    },
    OpenHistorico() {
      this.$refs.modalcryptoc.fecthDatahistorico(this.$parent.dataUser.defixId)
      this.$refs.modalcryptoc.Fix = true;
      //this.$refs.modalcryptoc.modalFiatHistorico = true;
      setTimeout(() => {
        this.$refs.modalcryptoc.Fix = false;
      }, 1000);
    },
    OpenKYC() {
      this.loading=true;
      this.$refs.modalcryptoc.fecthDataKYC(this.$parent.dataUser.defixId)
      this.$refs.modalcryptoc.Fix = true;
      this.loading=false;
      //this.$refs.modalcryptoc.modalKYC = true;
      setTimeout(() => {
        this.$refs.modalcryptoc.Fix = false;
      }, 1000);
    },
    clearCard() {
      this.$parent.Dapps = false;
      this.$parent.Historico = false;
      this.$parent.Fiat = false;
      this.$parent.EstadoTransaccion = false;
      this.$parent.Perfil = false;
      this.$parent.Cartera = false;
      this.$parent.Portafolio = false;
      this.$parent.ExchangeCard = false;
    },
    VolverAtras() {
      this.clearCard()
      this.isActiveCompra= true
      this.isActiveVenta= true
      if (window.innerWidth <= 880) {
        this.$store.commit("ClearNavigation")
        this.$parent.Cartera = true;
        //
        this.$parent.leftCard = true;
        this.$parent.centerCard = false;
        this.$parent.rightCard = false;
      } else {
        const item = { key: "portafolio" }
        this.$store.dispatch("Navigation", { item })
        this.$parent.Cartera = true;
        this.$parent.Portafolio = true;
        this.$parent.ExchangeCard = true;
        this.$parent.Exchange = false;
        //
        this.$parent.leftCard = true;
        this.$parent.centerCard = true;
        this.$parent.rightCard = true;
      }
    },
    selectCountry(item) {
      this.dataPais.forEach(element => {
        element.active = false
      });
      item.active = true
      this.llaves.pais = item.id;
      this.step++;
    },
    selectCountry1(item) {
      this.data.forEach(element => {
        element.active = false
      });
      item.active = true
      this.llaves.pais = item.id;
      this.llaves.paisnombre = item.nombre;
      //this.llaves.tasa = item.tasa;
      this.llaves.coin = item.coin;
      this.llaves.kyccabecera = item.kycCabeceraId;
      this.isActiveCompra = false
      this.isActiveVenta = false
      //this.step++;
    },
    CompraVenta(key) {
      if (this.llaves.pais !== "") {
        this.llaves.accion = key;
        this.step = 4;
      }
    },
    selectBanco(item) {
      this.llaves.banco = item.key;
      this.step++;
    },
    selectCripto(item) {
      this.llaves.crypto = item.title;
      console.log(this.llaves)
      if (this.llaves.accion == 'comprar') {
        this.OpenCryptocDefix()
      }
      if (this.llaves.accion == 'vender') {
        this.OpenCryptoc()
  
      }
    },
    selectCuenta_compra(item) {
      this.dataCuentas_compra.forEach(element => {
        element.active = false
      });
      item.active = true
      this.datosCompra.banco = item.banco
      this.datosCompra.tipopago = item.tipopago
      this.datosCompra.titular = item.titular
      this.datosCompra.cedula = item.cedula
      this.datosCompra.telefono = item.telefono
      this.datosCompra.bancoId = item.bancoId
      this.datosCompra.tipopagoId = item.tipopagoId
      this.datosCompra.numerocuenta = item.numerocuenta
      this.datosCompra.tipocuenta = item.tipocuenta
      this.llaves.tasa = item.tasa
      this.llaves.monto = item.monto
      this.llaves.montomin = item.montomin
      this.llaves.comision = item.comision
      this.llaves.tasaaltomonto = item.tasaaltomonto
      this.step = 5
    },
    deleteCuenta_compra(item) {
      let index = this.dataCuentas_compra.indexOf(item)
      this.dataCuentas_compra.splice(index, 1)
    },
    GuardarCrearCuenta_compra() {
      this.dataCuentas_compra.push(this.crearCuenta_compra)
      this.clearCrearCuenta()
    },
    CuentaBancariaNext_compra() {
      if (this.datosCompra.titular !== null) {
        this.step = 8
        console.log(this.datosCompra)
      }
    },
    VerEstado() {
      if (window.innerWidth <= 880) {
        // mobile
        this.$parent.leftCard = false
        this.$parent.centerCard = true
        this.$parent.EstadoTransaccion = true;
        this.step = 1
        if (this.llaves.accion == 'comprar') {
          this.$parent.$refs.estadoTransaccion.step = 1
        }
        if (this.llaves.accion == 'vender') {
          this.$parent.$refs.estadoTransaccion.step = 2
        }
      } else {
        // desktop
        this.$parent.centerCard = true
        this.$parent.EstadoTransaccion = true;
        this.step = 1
        if (this.llaves.accion == 'comprar') {
          this.$parent.$refs.estadoTransaccion.step = 1
        }
        if (this.llaves.accion == 'vender') {
          this.$parent.$refs.estadoTransaccion.step = 2
        }
      }
    },
    selectCuenta_venta(item) {
      this.dataCuentas_venta.forEach(element => {
        element.active = false
      });
      item.active = true
      this.datosVenta.banco = item.banco
      this.datosVenta.tipopago = item.tipopago
      this.datosVenta.titular = item.titular
      this.datosVenta.cedula = item.cedula
      this.datosVenta.telefono = item.telefono
      this.datosVenta.bancoId = item.bancoId
      this.datosVenta.tipopagoId = item.tipopagoId
      this.datosVenta.numerocuenta = item.numerocuenta
      this.datosVenta.tipocuenta = item.tipocuenta
      this.llaves.tasa = item.tasav
      this.llaves.monto = item.montov
      this.llaves.montomin = item.montominv
      this.llaves.comision = item.comisionv
      this.llaves.tasaaltomonto = item.tasaaltomontov
      this.step = 10


    },
    deleteCuenta_venta(item) {
      let index = this.dataCuentas_venta.indexOf(item)
      this.dataCuentas_venta.splice(index, 1)
    },
    GuardarCrearCuenta_venta() {
      this.dataCuentas_venta.push(this.crearCuenta_venta)
      this.clearCrearCuenta()
    },
    CuentaBancariaNext_venta() {
      if (this.datosVenta.titular !== null) {
        this.step = 13
        console.log(this.datosVenta)
      }
    },
    cancelTransaction() {
      console.log("hacer funcion")
      this.step = 1
    },
    clearCrearCuenta() {
      this.crearCuenta_compra = { tipe: "V" }
      this.crearCuenta_venta = { tipe: "V", banco: "Seleccione el Banco" }
    },
    CreaFiatTransaccion() {
      const formData = new FormData()
      formData.append('referencia', this.llaves.referencia)
      if (this.llaves.accion == 'comprar') {
        this.CreaTransaccionFiat=true
        formData.append('accion', 'C')
        formData.append('cantidad', this.datosCompra.cantidad)
        formData.append('monto', (this.datosCompra.cantidad / this.llaves.tasa).toFixed(2))
        formData.append('banco', this.datosCompra.bancoId)
        formData.append('banco_nombre', this.datosCompra.banco)
        formData.append('tipopago', this.datosCompra.tipopagoId)
        formData.append('tipopago_nombre', this.datosCompra.tipopago)
        formData.append('titular', this.datosCompra.titular)
        formData.append('cedula', this.datosCompra.cedula)
        formData.append('telefono', this.datosCompra.telefono)
        formData.append('numerocuenta', this.datosCompra.numerocuenta)
        formData.append('tipocuenta', this.datosCompra.tipocuenta)
      }
      if (this.llaves.accion == 'vender') {
        formData.append('accion', 'V')
        formData.append('cantidad', this.datosVenta.cantidad)
        formData.append('monto', (this.datosVenta.cantidad * this.llaves.tasa).toFixed(2))
        formData.append('banco', this.datosVenta.bancoId)
        formData.append('banco_nombre', this.datosVenta.banco)
        formData.append('tipopago', this.datosVenta.tipopagoId)
        formData.append('tipopago_nombre', this.datosVenta.tipopago)
        formData.append('titular', this.datosVenta.titular)
        formData.append('cedula', this.datosVenta.cedula)
        formData.append('telefono', this.datosVenta.telefono)
        formData.append('numerocuenta', this.datosVenta.numerocuenta)
        formData.append('tipocuenta', this.datosVenta.tipocuenta)
      }
      formData.append('pais', this.llaves.pais)
      formData.append('cripto', this.llaves.crypto)
      formData.append('tasa', this.llaves.tasa)
      formData.append('comision', this.llaves.comision)
      formData.append('observacion', this.$parent.dataUser.defixId)
      formData.append('usuario', '1')
      formData.append('email', localStorage.getItem('email'))
      formData.append('wallet', this.wallet)
      if (this.CreaTransaccionFiat==true){

          axios.post(process.env.VUE_APP_API_BASE_AXIOS + '/FiatTransaccion/', formData).then((res) => {
            //envia email a defix
            axios.put( process.env.VUE_APP_API_BASE_AXIOS + '/SendEmailFiat', formData).then((res)=>{
                  console.log(res)
                }).catch((error) => {
                  console.error(error)
                })
            // envia email al usuario
            axios.put( process.env.VUE_APP_API_BASE_AXIOS + '/SendEmailFiatUser', formData).then((res)=>{
                  console.log(res)
                }).catch((error) => {
                  console.error(error)
                })
            console.log(res.data)
            this.snackbar = true
            this.color = 'primary'
            this.text = 'Se guardo correctamente'
            this.loadingventa=false
            this.datosCompra = {
              cantidad: 0,
              cuentaEnvio: null,
              id: '',
              bancoId: '',
              banco: '',
              tipopagoId: '',
              tipopago: '',
              titular: '',
              cedula: '',
              telefono: '',
              numerocuenta: '',
              tipocuenta: '',
            }
            this.datosVenta = {
              cantidad: 0,
              id: '',
              bancoId: '',
              banco: '',
              tipopagoId: '',
              tipopago: '',
              titular: '',
              cedula: '',
              telefono: '',
              numerocuenta: '',
              tipocuenta: '',
            }
            this.isActiveCompra = true
            this.isActiveVenta = true
            this.step = 20
            this.sesionactiva()  // valida si el token esta disponible
            //this.fecthData()
          }).catch((err) => {
            console.log(err)
            this.snackbar = true
            this.color = '#E53935'
            this.text = 'Ocurrio un error ' + err
          });
      }else{
        this.snackbar = true
            this.color = '#E53935'
            this.text = 'Ocurrio un error generando la Venta'
      }


    },
    validuser() {
        axios.get(process.env.VUE_APP_API_BASE_AXIOS + '/ValidacionUsuario/'+this.user+'').then((res) => {
        this.existe=res.data.existe
        if (res.data.mensaje=="Accesar al modulo FIAT."){
          this.mensaje=this.$t("Accesa") 
        }else{
          this.mensaje=this.$t("CreaClave") 
        }
      }).catch((err) => {
        console.log(err)
        this.snackbar = true
        this.color = '#E53935'
        this.text = 'Ocurrio un error ' + err
      });
    },
  sesionactiva(){
  if (axios.defaults.headers.common.Authorization){
    this.step=2;
  }
  else{this.step=1}
  },
    createpass() {
      this.emaillogin.email = localStorage.getItem('email')
      const data = {
        username:this.user,
        email:this.emaillogin.email,
        password:this.password,
      }

      if (this.emaillogin.email!='' && this.password!='')
      {
        if (this.existe)
        {
          axios.post(process.env.VUE_APP_API_BASE_AXIOS + '/auth/', data).then((res) => {
            console.log(res.data)
            axios.defaults.headers.common.Authorization = 'token ' + res.data.token
            //this.snackbar = true
            //this.color = 'primary'
            //this.text = 'Acceso autorizado'
            this.fecthDataPais()
            this.loading=false
            this.step=2

          }).catch((err) => {
            console.log(err)
            this.snackbar = true
            this.color = '#E53935'
            //this.text = 'Ocurrio un error ' + err
            this.text = 'Ocurrio un error: Revise su clave.'
          });}
        else{
          axios.post(process.env.VUE_APP_API_BASE_AXIOS + '/create-user/', data).then((res) => {
            this.accesa=res.data.mensaje
            axios.defaults.headers.common.Authorization = 'token ' + res.data.token
            console.log(res.data)
            this.snackbar = true
            this.color = 'primary'
            this.text = 'Se guardo correctamente'
            this.fecthDataPais()
            this.step=2

          }).catch((err) => {
            console.log(err)
            this.snackbar = true
            this.color = '#E53935'
            this.text = 'Ocurrio un error ' + err
          });}
        }
        else{

          if (this.emaillogin.email=='' )
          { this.snackbar = true
            this.color = '#E53935'
            this.text = 'Ocurrio un error: Revise su e-mail.'}
          if (this.password==''){
             this.snackbar = true
            this.color = '#E53935'
            this.text = 'Ocurrio un error: Revise su clave.'
          }

        }
    },
    EjecutarEnviarCoin(code) {
      let json = {
        fromDefix: this.$parent.dataUser.defixId,
        privateKey: process.env.VUE_APP_API_BASE_RED == 'mainnet' ?  this.$parent.dataUser['usdt_credentials'].privateKey:  this.$parent.dataUser['eth_credentials'].privateKey,
        toDefix:    process.env.VUE_APP_API_BASE_RED == 'mainnet' ? "revenue.defix3" : "jcuauro3.defix3",
        coin:       process.env.VUE_APP_API_BASE_RED == 'mainnet' ? "USDT" : "ETH",
        amount: this.datosVenta.cantidad ,
        type: "1",
        code: code ? code : null,
      }
      console.log('lola json', json)
      axios.post(process.env.VUE_APP_API_BASE_URL + "transaction", json)
        .then((response) => {
          console.log('lola respuesta',response.data.respuesta)
          this.CreaTransaccionFiat = response.data.respuesta =="ok" ? true : false
          this.$refs.modaldosfa.ModalDosfa = false;
          if (this.CreaTransaccionFiat) {this.CreaFiatTransaccion()}

          
          //this.$parent.CargarBalance();
          //this.$parent.$refs.historico.CargarHistorico(); 
          //console.log("then")
          //console.log(response);
        })
        .catch(() => {
          this.CreaTransaccionFiat=false
          //this.$refs.alert.Titulo = this.$t("alertTitulo3");
          //this.$refs.alert.Alerta = this.$t("alertAlerta2");
          //console.log("cath")
          console.log('lola error')
        }
        );
    },

    isNumeric(val) {
      if (/^-?\d+$/.test(val) ) {
        return true
      } else if ( /^\d+(.\d+)$/.test(val) ) {
        return true
      } else { 
        return false
      }
    },
    ValidarAmount() {
      this.amount = this.datosVenta.cantidad .split(",").join(".");
      this.amount = this.amount.split(" ").join("");
      if(this.amount === null || this.amount.length <= 0) {
        this.disabledEnvio = true;
        this.messageAmount = "Requerido";
        this.validar_amount = false
        //this.montoDivisa = null
        this.amount = null
        this.totalFee = this.feeEnvio = 0;
      } else {
        //this.messageAmount = null;
        let numerico =this.isNumeric(this.amount);
        if(numerico) {
          let json = {
            address: this.$parent.dataUser.btc_credentials.address,
            amount: this.amount.toString(),
          }
          axios.post(process.env.VUE_APP_API_BASE_URL + "get-comisiones", json)
          .then((response) => {    
            if(response.data) {
              //console.log(response.data)
              let index = response.data.findIndex(x => x.coin == this.dataCoin.title);
              let feeEnvio = this.feeEnvio = response.data[index].fee == false ? 0 : parseFloat(response.data[index].fee)
              //console.log("fee:", response.data[index].fee)
              let feedefix = this.dataCoin.title == "NEAR" ? parseFloat(this.fee) : feeEnvio * (parseFloat(this.fee) / 100);
              this.totalFee = parseFloat(this.fee) > 0 ? (feeEnvio + feedefix).toFixed(8) : feeEnvio.toFixed(8);
                if(this.dataCoin.title == "USDT" || this.dataCoin.title == "USDC" || this.dataCoin.title == "DAI") {
                  if((parseFloat(this.$parent.dataCartera[this.$parent.dataCartera.findIndex(x => x.title == "ETH")].amountToken) - this.totalFee) < 0) {
                    this.disabledEnvio = true;
                    this.messageAmount = this.$t("No cuenta con suficiente ETH para cubrir la tarifa");
                    this.validar_amount = false;
                  } else {
                    this.validar_amount = true;
                  }
                } else {
                  if(parseFloat(this.amount) > (parseFloat(this.dataCoin.amountToken) - this.totalFee)) {
                    this.disabledEnvio = true;
                    this.messageAmount = this.$t("No cuenta con suficiente ") + this.dataCoin.title + this.$t(" para cubrir la tarifa");
                    this.validar_amount = false
                  } else {
                    this.validar_amount = true;
                  }
                }
              
            }
          })
          .catch((error) => {
            console.log("error consultar fee: ", error);
          });
        } else {
          this.disabledEnvio = true;
          this.messageAmount = "Solo se permiten valores numericOpenModalDosFaos";
          this.validar_amount = false
          //this.montoDivisa = null
          this.totalFee = this.feeEnvio = 0;
        }
      }
    },
    OpenModalDosFa() {
      this.$refs.modaldosfa.ModalDosfa = true;
      this.$refs.modaldosfa.Fix = true;
      this.$refs.modaldosfa.accion = "enviar";
      this.$refs.modaldosfa.code = null;
      setTimeout(() => {
        this.$refs.modaldosfa.Fix = false;
      }, 1000);
    },
    EnviarCoin() {
            this.loadingventa=true
            this.FormCargando = this.disabledEnvio = true;
            axios.post(process.env.VUE_APP_API_BASE_URL + "status-2fa", {defixId: this.$parent.dataUser.defixId})
              .then((response) => {
                switch (response.data) {
                  case true: {
                    this.FormCargando = this.disabledEnvio = false;
                    this.OpenModalDosFa();
                    this.$refs.modaldosfa.loading = false
                  }
                    break;
                  case false: {
                    this.EjecutarEnviarCoin();
                  }
                    break;
                  default: {
                    this.$refs.alert.alertCancelled = true;
                    this.$refs.alert.Titulo = this.$t("Error");
                    this.$refs.alert.Alerta = this.$t("Ocurrio un error inesperado por favor vuelva a intentarlo mas tarde !!!");
                    this.FormCargando = this.disabledEnvio = false;
                  }
                    break;
                }
              })

    },

  }
};
</script>

<style src="./Left.scss" lang="scss"></style>

<style scoped>
.containerFinal::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 90%;
  background-color: #3333332a;
}
/* .containerFinal::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
} */
</style>this.step = 10;EnviarCoin
<template>
  <section>
    <!-- modal crypto A -->
    <v-navigation-drawer
      id="modalQR"
      :class="{ fix: Fix }"
      v-model="Notificaciones"
      :absolute="positionModal"
      :fixed="!positionModal"
      temporary
      overlay-color="black"
      overlay-opacity="0.5"
      color="#FFFFFF"
    >
      <v-col
        cols="10"
        class="containerLineas margintop"
        style="
          margin-inline: auto;
          padding-block: 15px;
          display: flex;
          justify-content: space-between
          align-items: center
        "
      >
        <span class="titulo h6-em">{{ $t("Notificaciones") }}</span>
        <v-btn icon @click="Notificaciones = false">
          <v-icon color="#6667AB" medium>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-col>

      <template>
         <v-card color="#FFFFFF" style="padding-bottom: 12px">
          <v-col md="11" lg="10" style="margin-inline: auto; padding-bottom: 0" class="divcol">
            <span class="titulo h8-em paddtop paddbottom" @click="OpenNotificaciones()"
              >{{ $t("Notificaciones") }}</span
            >
          </v-col>

          <v-col md="12" lg="10" class="cardHistorico cardN paddvertical" style="margin-inline: auto">
            <div class="hoverHistorico">
              <v-checkbox
                v-model="$parent.editedItem.flag_send"
                color="#6667AB"
                hide-details
                @click="$parent.cambios()"
              >
                <template v-slot:label>
                  <span class="h8-em">{{ $t("AlRecibir") }}</span>
                </template>
              </v-checkbox>
            </div>
            <div class="hoverHistorico">
              <v-checkbox
                v-model="$parent.editedItem.flag_receive"
                color="#6667AB"
                hide-details
                @click="$parent.cambios()"
              >
                <template v-slot:label>
                  <span class="h8-em">{{ $t("AlEnviar") }}</span>
                </template>
              </v-checkbox>
            </div>
            <div class="hoverHistorico">
              <v-checkbox
                v-model="$parent.editedItem.flag_dex"
                color="#6667AB"
                hide-details
                @click="$parent.cambios()"
              >
                <template v-slot:label>
                  <span class="h8-em">{{ $t("AlRealizarDexchange") }}</span>
                </template>
              </v-checkbox>
            </div>
            <div class="hoverHistorico">
              <v-checkbox
                v-model="$parent.editedItem.flag_fiat"
                color="#6667AB"
                hide-details
                @click="$parent.cambios()"
              >
                <template v-slot:label>
                  <span class="h8-em">{{ $t("AlRealizarFiat") }}</span>
                </template>
              </v-checkbox>
            </div>
          </v-col>
        </v-card>
      </template>


    </v-navigation-drawer>

  </section>
</template>

<script>

export default {
  name: "Notificaciones",
  i18n: require("./i18n"),
  data() {
    return {
      Fix: false,
      Notificaciones: false,
      positionModal: true,
    }
  },
  mounted() {
    if (window.innerWidth <= 880) {
      this.positionModal = false
    } else {
      this.positionModal = true
    }
  },
};
</script>

<style src="../Right/Right.scss" lang="scss"></style>
<style scoped>
.containerLineas {
  position: relative;
}
.containerLineas::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
}
</style>

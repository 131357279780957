import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/C-layout/Layout/Layout';

// Pages layout
import Home from '@/pages/layout/Home/Home';
import Error from "@/pages/layout/Error/Error";

// Pages app
import Index from '@/pages/app/Home/Home';
import App from '@/components/C-app/Layout/Layout';


Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Layout',
      component: Layout,
      children: [
        {
          path: '/',
          name: 'Home',
          component: Home,
        },
      ],
    },
    {
      path: '*',
      name: 'Error',
      component: Error
    },
    {
      path: '/app',
      name: 'App',
      component: App,
      children: [
        {
          path: '/',
          name: 'Home',
          component: Index,
        },
      ],
    },
  ],
});


/*import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/C-app/Layout/Layout';
import Login from '@/components/C-app/Login/Login';

// Pages
import Home from '@/pages/app/Home/Home';
import Error from "@/pages/app/Error/Error";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
    path: '/',
    name: 'App',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
    ],
  },
    {
      path: '*',
      name: 'Error',
      component: Error
    }
  ],
});
*/
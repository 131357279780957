<template>
  <section>
    <!-- modal crypto A -->
    <v-navigation-drawer
      id="modalQR"
      :class="{ fix: Fix }"
      v-model="ModalSwap"
      :absolute="positionModal"
      :fixed="!positionModal"
      temporary
      overlay-color="black"
      overlay-opacity="0.5"
      color="#FFFFFF"
    >
      <v-col
        cols="10"
        class="mt-4"
        style="
          margin-inline: auto;
          padding-block: 0.001px;
          display: flex;
          justify-content: space-between
          align-items: center
        "
      >
        <span class="titulo h6-em">{{ $t("Vista previa swap") }}</span>
        <v-btn icon @click="ModalSwap = false">
          <v-icon color="#6667AB" medium>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="10"
        class="containerLineas"
        style="
          margin-inline: auto;
          display: flex;
          justify-content: space-between
          align-items: center
        "
      >
        {{ $t("DEX aggregator protocol optimizing most efficient exchange route.") }}
      </v-col>

      <template>
        <v-col md="12" lg="10" style="margin-inline: auto" class="mt-1">
          <v-card
            class="containerSwap2"
            style="position: relative; margin-bottom:.5em !important"
          >
              <v-col class="pt-3">
                <div class="divrow center" style=" align-items: center; ">
                  <div id="app">
                    <span class="h8-em start"><span span class="mr-1" style="font-weight:bold">{{ $t("De") }} {{$parent.dataConsultaSwap.De}}: </span> {{ $parent.dataConsultaSwap.amountDe }} </span>
                    <span class="h8-em start"><span span class="mr-3" style="font-weight:bold">{{ $t("Para") }} {{$parent.dataConsultaSwap.Para}}:</span> <strong class="mr-2">~</strong> {{ $parent.dataConsultaSwap.amountPara }} </span>
                    <!--<span class="h9-em end paddright">0 <span span class="ml-2" style="font-weight:bold"> ~ Fee defix3</span></span>-->

                    <!--<span class="h8-em start mt-5"><span class="mr-2" style="font-weight:bold">{{ $t("Fee defix3") }}: </span> {{$parent.dataConsultaSwap.fee}} %</span>
                    <span class="h8-em start"><span class="mr-2" style="font-weight:bold">{{ $t("Fee") }} {{ $parent.dataConsultaSwap.Swap }}: </span> {{$parent.dataConsultaSwap.feeSwap }} <strong class="mr-2 ml-2">{{ $t("De").toLowerCase() }} ETH</strong></span>-->
                    <span class="h8-em start"><span class="mr-2" style="font-weight:bold">{{ $t("Fee") }}: </span> <strong class="mr-2">~</strong> {{ $parent.dataConsultaSwap.feeSwap }} (${{ (parseFloat($parent.dataConsultaSwap.feeSwap) * parseFloat($parent.$parent.dataCartera[1].price)).toFixed(2) }}) </span>
                    <!--<span class="h8-em start"><span class="mr-2" style="font-weight:bold">{{ $t("Fee") }} USD: </span> <strong class="mr-2">~</strong>  $ </span>-->
                    <!--<span class="h8-em start"><span class="mr-2" style="font-weight:bold">Gas: </span> {{ $parent.dataConsultaSwap.gas }}</span>-->
                    <span class="h8-em start">{{ $t('Fee discounted from ETH origin balance') }} </span>
                    <span v-if="$parent.messageErrorStatus" style="color:red">*{{ $t($parent.messageError) }} </span>
                  </div>
                </div>
              </v-col>
          </v-card>
        </v-col>
      </template>
      <aside class="botonFinalSwap">
        <v-btn
          class="botonSwap h7-em"
          :disabled="$parent.messageErrorStatus"
          :loading="FormCargando"
          @click="ejecutarSwap()"
        >
          {{ $t(tituloBoton) }} <span class="ml-3" v-if="tituloBoton=='Swap'">({{ contador }})</span>
        </v-btn>
      </aside>
    </v-navigation-drawer>
  </section>
</template>

<script>
export default {
  name: "ModalSwap",
  i18n: require("./i18n"),
  data() {
    return {
      Fix: false,
      ModalSwap: false,
      positionModal: true,
      FormCargando: false,
      tituloBoton: "Swap",
      contador: 10,
      interval: null,
    };
  },
  mounted() {
    if (window.innerWidth <= 880) {
      this.positionModal = false
    } else {
      this.positionModal = true
    }
  },
  methods: {
    timer() {
      clearInterval(this.interval);
      this.tituloBoton = 'Swap';
      this.contador = 10;
      this.interval = setInterval(function () {
        this.contador -= 1;
        if(this.contador == 0){
          clearInterval(this.interval);
          this.tituloBoton = "Actualizar";
          this.contador = 10;
        }
      }.bind(this), 1000);
    },
    ejecutarSwap(){
      if(this.tituloBoton == 'Swap') {
        this.$parent.Ejecutarswap();
      } else {
        this.$parent.OpenModalSwap('actualizar');
        this.tituloBoton = 'Swap';
        this.timer()
      }
    },
  },
};
</script>

<style src="./Right.scss" lang="scss"></style>
<style scoped>
.containerLineas {
  position: relative;
}
.containerLineas::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
}
</style>

module.exports = {
  messages: {
    ES: {
      // Enviar 
      explorer: 'Ver en el explorador',
    },
    US: {
      // Enviar 
      explorer: 'View on explorer',
    }
  }
}
    
<template>
  <section class="containerCartas">
    <ModalCrypto ref="modalcrypto"></ModalCrypto>
    <ModalDosFa ref="modaldosfa"></ModalDosFa>
    <ModalSwap ref="modalswap"></ModalSwap>
    <Alert ref="alert"></Alert>
    <v-col
      cols="11" md="11" lg="10"
      style="justify-content: space-between"
      class="containerTitulo"
    >
      <v-col class="paddvertical divcol">
        <span class="titulo h6-em">Dex</span>
        <!--<span class="desc h7-em">{{ $t("Intercambio") }}</span>-->
      </v-col>
      <!-- <div class="divrow center" v-if="user">
        <v-badge
          :content="messages"
          :value="messages"
          color="red"
          overlap
          bordered
        >
          <v-icon color="#6667AB" medium>mdi-bell</v-icon>
        </v-badge>
        <img class="user" src="@/assets/app/user.png" alt="token" />
      </div> -->
    </v-col>

    <v-card class="cartaInside cartaIE">
      <v-col
        cols="11" md="11" lg="10"
        style="margin-inline: auto; padding-bottom: 0"
        class="divcol"
      >
        <!--<div class="end">
          <v-btn
            class="flechitaLeft"
            icon
            color="#FFFFFF"
            style="background-color: #6667ab"
            @click="volverAtras()"
          >
            <v-icon medium>mdi-arrow-left-thin</v-icon>
          </v-btn>
        </div>-->
        <div class="center">
          <aside class="containerBotonesIE">
            <v-btn
              v-for="(item, index) in dataBotonesIE"
              v-bind:key="index"
              class="botonesIE h8-em"
              style="text-transform: none !important;"
              :class="{ botonesIEActive: item.color }"
              @click="ChangeIE(item)"
            >
              <span>{{ $t(item.text) }}</span>
            </v-btn>
          </aside>
        </div>
        <aside
          class="divrow"
          style="justify-content: space-between; align-items: center"
        >
          <span class="titulo h6-em paddtop paddbottom">Swap</span>
        </aside>
      </v-col>

      <v-col md="12" lg="10" style="margin-inline: auto">
        <section class="divcol" style="position: relative">
          <template v-for="(item, index) in dataSwap">
            <section v-bind:key="index">
              <v-card class="containerSwap">
                <v-col class="paddvertical">
                  <div
                    class="divrow"
                    style="
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: 5px;
                    "
                  >
                    <aside
                      id="containerTokens"
                      class="divrow center"
                      @click="openCrypto(item)"
                    >
                      <img class="token2" :src="item.token" alt="token" />
                      <span class="desc h8-em" style="margin-left: 5px">{{
                        item.desc
                      }}</span>
                      <v-icon small>mdi-chevron-down</v-icon>
                      <!--<v-icon v-if="item.desc != 'ETH'" small>mdi-chevron-down</v-icon>
                      <span v-if="item.desc == 'ETH'" class="mr-3"></span>-->
                    </aside>
                    <v-text-field
                      v-model="item.input"
                      type="text"
                      hide-spin-buttons
                      :hide-details="hideDetails"
                      background-color="transparent"
                      solo
                      flat
                      reverse
                      @blur="ConsultarSwap(item)"
                      @keyup="ConsultarSwap(item)"
                    >
                      <template v-slot:label>
                        <span class="h7-em">0.00</span>
                      </template>
                    </v-text-field>
                  </div>
                  <div
                    class="divrow"
                    style="justify-content: space-between; align-items: center"
                  >
                    <span class="h9-em">{{ item.balance }}</span>
                    <span class="h9-em">{{ item.pricePriview }}</span>
                  </div>
                  <span v-if="messageErrorStatus" style="color:red"> {{ $t(messageError) }} </span>
                </v-col>
              </v-card>

              
              <v-btn
                v-if="item.flechita"
                icon
                :disabled="disableSwitch"
                class="center-absolute"
                @click="switchToken()"
                style="border: 0.2em solid #FFFFFF;background-color:#F6F5F8 !important"
              >
              <!--<v-btn
                v-if="item.flechita"
                icon
                class="center-absolute flechitaSwap"
              >-->
                <v-icon :style="playIcon?'transform: rotate(180deg)':null" id="flechitaSwapIcon" color="#333333">mdi-arrow-up-down</v-icon>
              </v-btn>
            </section>
          </template>
        </section>
        <!--<span class="h9-em end paddright">0 <span span class="ml-2" style="font-weight:bold"> ~ Fee defix3</span></span>
        <span class="h9-em end paddright">0 <span class="ml-2" style="font-weight:bold"> ~ Fee swap </span> </span>
        <span class="h9-em end paddright">{{ dataConsultaSwap.gas }} <span span class="ml-2 mr-1" style="font-weight:bold"> ETH </span> = {{ dataConsultaSwap.gasUsd }} $ <span class="ml-2" style="font-weight:bold"> ~ Gas </span></span>
        -->

        <aside class="botonFinalSwap">
          <v-btn
            class="botonSwap h7-em"
            :disabled="disableSwitch"
            :loading="FormCargando"
            @click="OpenModalSwap()"
          >
            {{ $t('Vista previa swap') }}
          </v-btn>
        </aside>
      </v-col>
    </v-card>
  </section>
</template>

<script>
import ModalCrypto from "@/pages/app/Right/ModalCrypto.vue";
import ModalDosFa from "@/pages/app/ModalDosFa/ModalDosFa.vue"
import ModalSwap from "@/pages/app/Right/ModalSwap.vue"
import Alert from "@/pages/app/Alert/Alert.vue"
import axios from "axios";

export default {
  name: "DexExchange",
  i18n: require("./i18n"),
  components: {
    ModalCrypto,
    ModalDosFa,
    Alert,
    ModalSwap,
  },
  data() {
    return {
      playIcon: true,
      user: false,
      messages: 0,
      hideDetails: true,
      FormCargando: false,
      dataBotonesIE: [
        {
          key: "enviar",
          text: "Enviar",
          color: false,
        },
        {
          text: "Intercambiar",
          color: true,
        },
      ],
      dataSwap: [
        {
          token: null,
          desc: null,
          balance: null,
          price: null,
          pricePriview: null,
          flechita: true,
          input: null,
          inputP: 0,
        },
        {
          token: null,
          desc: null,
          balance: null,
          price: null,
          pricePriview: null,
          flechita: true,
          input: null,
          inputP: 1,
        },
      ],
      dataConsultaSwap:{},
      messageError: null,
      messageErrorStatus: false,
      disableSwitch: true,
    };
  },
  mounted() {
    if (window.innerWidth <= 880) {
      this.user = true
    }
    this.ConsultarFee(this.$parent.dataCartera[0].title);
  },
  methods: {
    isNumeric(val) {
      if (/^-?\d+$/.test(val) ) {
        return true
      } else if ( /^\d+(.\d+)$/.test(val) ) {
        return true
      } else { 
        return false
      }
    },
    ConsultarSwap(item) {
      if(item.input != null) {
        if(item.input.length == 0) {
          this.disableSwitch = true;
          this.messageErrorStatus = false;
          this.dataSwap[0].input = null;
          this.dataSwap[1].input = null;
          this.dataSwap[0].pricePriview = null;
          this.dataSwap[1].pricePriview = null;
        } else {
          item.input = item.input.split(",").join(".");
          if(this.isNumeric(item.input)) {
            if(parseFloat(this.dataSwap[0].input) > parseFloat(this.$parent.dataCartera.filter(item => item.title  == this.dataSwap[0].desc)[0].amountToken)) {
              this.messageErrorStatus = true
              this.messageError = this.$t('messageAmount');
            } else {
              this.messageErrorStatus = false;
            }
            let json = {};
            if(item.inputP == 0) {
              json = {
                  fromCoin: this.dataSwap[0].desc,
                  toCoin: this.dataSwap[1].desc,
                  amount: item.input,
              };
            } else {
              json = {
                  fromCoin: this.dataSwap[1].desc,
                  toCoin: this.dataSwap[0].desc,
                  amount: item.input,
              };
            }
            
            axios.post(process.env.VUE_APP_API_BASE_URL + "swap-preview-eth", json)
              .then((response) => {
                try {
                  let gasCostUSD = process.env.VUE_APP_API_BASE_RED == 'testnet' ? 0.00001 : response.data.priceRoute.gasCostUSD
                  if(response.data) {
                    //console.log(response.data)
                    let validar = false;
                    
                    let feeSwap = parseFloat(gasCostUSD) / parseFloat(this.$parent.dataCartera[1].price);
                    let feeSwapConsol = parseFloat(response.data.fee) + feeSwap
                    let totalFee =  feeSwapConsol + (feeSwapConsol * (parseFloat(this.dataConsultaSwap.fee) / 100));
                    console.log('lola a',parseFloat(response.data.fee), feeSwap, parseFloat(this.dataConsultaSwap.fee),parseFloat(gasCostUSD) , parseFloat(this.$parent.dataCartera[1].price))
                    console.log('lola a',response.data)
                    switch (this.dataSwap[0].desc) {
                      case "ETH": {
                          if((parseFloat(this.$parent.dataCartera.filter(item => item.title  == this.dataSwap[0].desc)[0].amountToken) >= parseFloat(this.dataSwap[0].input) + totalFee)) {
                            this.messageErrorStatus = false;
                            validar = true;
                          } 
                        }
                      break;
                      default:
                        if(parseFloat(this.$parent.dataCartera.filter(item => item.title  == this.dataSwap[0].desc)[0].amountToken) >= totalFee) {
                          this.messageErrorStatus = false;
                          validar = true;
                        }
                        break;
                    }
                    if(!validar) {
                      this.messageErrorStatus = true
                      this.messageError = this.$t('messagenogas');
                    }
                      //this.dataConsultaSwap.amount = response.data.srcAmount / Math.pow(10, response.data.srcDecimals);
                      //this.dataConsultaSwap.AmountDestino = response.data.destAmount / Math.pow(10, response.data.destDecimals);
                      //this.dataConsultaSwap.AmountDestinoUsd = parseFloat(response.data.destUSD).toFixed(2);
                      this.dataConsultaSwap.gas = response.data.priceRoute.gasCost;
                      this.dataConsultaSwap.gasUsd = parseFloat(gasCostUSD).toFixed(2);

                      if(item.inputP == 0) {
                        this.dataSwap[1].input = isNaN(response.data.priceRoute.destAmount / Math.pow(10, response.data.priceRoute.destDecimals)) ? 0 : response.data.priceRoute.destAmount / Math.pow(10, response.data.priceRoute.destDecimals);
                        /*if(this.dataSwap[0].input.length === 0) {
                          this.dataSwap[0].input = null
                          this.dataSwap[1].input = null
                        }*/
                    } else {
                      this.dataSwap[0].input = isNaN(response.data.priceRoute.srcAmount / Math.pow(10, response.data.priceRoute.srcDecimals)) ? 0 : response.data.priceRoute.srcAmount / Math.pow(10, response.data.priceRoute.srcDecimals);
                      /*if(this.dataSwap[1].input.length === 0) {
                        this.dataSwap[0].input = null
                        this.dataSwap[1].input = null
                      }*/
                    }
                    this.dataSwap[0].pricePriview = parseFloat(this.dataSwap[0].input) * parseFloat(this.dataSwap[0].price) > 0 ? "~ $" + (parseFloat(this.dataSwap[0].input) * parseFloat(this.dataSwap[0].price)) : this.dataSwap[0].input == null ? "~ $" : "~ $" + this.dataSwap[0].input;
                    this.dataSwap[1].pricePriview = parseFloat(this.dataSwap[1].input) * parseFloat(this.dataSwap[1].price) > 0 ? "~ $" + (parseFloat(this.dataSwap[1].input) * parseFloat(this.dataSwap[1].price)) : this.dataSwap[1].input == null ? "~ $" : "~ $" + this.dataSwap[1].input;
                  }
                  this.disableSwitch = false;
                
                } catch (error) {
                  console.log("error consulta: ", error)
                }
              })
              .catch((error) => {
                this.disableSwitch = true;
                console.log("error: ", error);
              });
          } else {
            this.disableSwitch = true;
            this.messageErrorStatus = true
            this.messageError = "Solo se permiten valores numericos";
            this.dataSwap[0].pricePriview = null;
            this.dataSwap[1].pricePriview = null;
          }
        }
      } else {
        this.messageErrorStatus = false;
      }
    },
    OpenModalSwap(actualizar) {
      this.FormCargando = true;
      this.ConsultarFee(this.dataSwap[0].desc)
      if(this.dataSwap[0].input != null || this.dataSwap[1].input != null) {
        if(this.isNumeric(this.dataSwap[0].input) || this.isNumeric(this.dataSwap[1].input)) {
          //console.log(this.$parent.dataCartera.filter(item => item.title  == this.dataSwap[0].desc)[0].amountToken)
          if(parseFloat(this.dataSwap[0].input) > parseFloat(this.$parent.dataCartera.filter(item => item.title  == this.dataSwap[0].desc)[0].amountToken)) {
            this.messageErrorStatus = true
            this.messageError = this.$t('messageAmount');
          } else {
            this.messageErrorStatus = false;
          }
            let json = {
              fromCoin: this.dataSwap[0].desc,
              toCoin: this.dataSwap[1].desc,
              amount: this.dataSwap[0].input,
            };
            axios.post(process.env.VUE_APP_API_BASE_URL + "swap-preview-eth", json)
              .then((response) => {
                if(response.data) {
                  let validar = false;
                  let feeSwap = parseFloat(response.data.priceRoute.gasCostUSD) / parseFloat(this.$parent.dataCartera[1].price);
                  let feeSwapConsol = parseFloat(response.data.fee) + feeSwap
                  let totalFee = feeSwapConsol + (feeSwapConsol * (parseFloat(this.dataConsultaSwap.fee) / 100));
                  console.log('lola o',parseFloat(response.data.fee), feeSwap, parseFloat(this.dataConsultaSwap.fee))
                  switch (this.dataSwap[0].desc) {
                    case "ETH": {
                        if((parseFloat(this.$parent.dataCartera.filter(item => item.title  == "ETH")[0].amountToken) >= parseFloat(this.dataSwap[0].input) + totalFee)) {
                          this.messageErrorStatus = false;
                          validar = true;
                        } 
                      }
                    break;
                    default:
                      if(parseFloat(this.$parent.dataCartera.filter(item => item.title  == "ETH")[0].amountToken) >= totalFee) {
                        this.messageErrorStatus = false;
                        validar = true;
                      }
                      break;
                  }
                  if(!validar) {
                    this.messageErrorStatus = true
                    this.messageError = this.$t('messagenogas'+'lolo');
                  }
                  this.dataConsultaSwap.amountDe = response.data.priceRoute.srcAmount / Math.pow(10, response.data.priceRoute.srcDecimals);
                  this.dataConsultaSwap.amountPara = response.data.priceRoute.destAmount / Math.pow(10, response.data.priceRoute.destDecimals);
                  this.dataConsultaSwap.De = this.dataSwap[0].desc
                  this.dataConsultaSwap.Para = this.dataSwap[1].desc
                  this.dataConsultaSwap.gas = response.data.priceRoute.gasCost;
                  //this.dataConsultaSwap.fee = 0;
                  //this.dataConsultaSwap.gasUsd = parseFloat(response.data.gasCostUSD);
                  this.dataConsultaSwap.Swap = response.data.priceRoute.bestRoute[0].swaps[0].swapExchanges[0].exchange;
                  console.log(totalFee)
                  this.dataConsultaSwap.feeSwap = totalFee;

                  this.dataSwap[1].input = response.data.priceRoute.destAmount / Math.pow(10, response.data.priceRoute.destDecimals)
                  
                  if(actualizar == null) {
                    this.$refs.modalswap.ModalSwap = true;
                    this.$refs.modalswap.Fix = true;
                    setTimeout(() => {
                      this.$refs.modalswap.Fix = false;
                    }, 1000);
                    this.$refs.modalswap.timer();
                  }
                  this.FormCargando = false;
                }
              })
              .catch((error) => {
                console.log("error: ", error);
                this.FormCargando = false;
              });
          
        } else {
          this.FormCargando = false;
          this.messageErrorStatus = true;
          this.messageError = "Solo se permiten valores numericos";
          this.dataSwap[0].pricePriview = null;
          this.dataSwap[1].pricePriview = null;
        }
      } else {
        this.FormCargando = false;
        this.messageErrorStatus = true;
        this.messageError = "Debe introducir un monto";
      }
    },
    OpenModalDosFa() {
      this.$refs.modaldosfa.ModalDosfa = true;
      this.$refs.modaldosfa.Fix = true;
      this.$refs.modaldosfa.accion = "swap";
      this.$refs.modaldosfa.code = null;
      setTimeout(() => {
        this.$refs.modaldosfa.Fix = false;
      }, 1000);
    },
    Ejecutarswap() {
      this.$parent.verificarSession().then((res) => {
        if(res != true) {
          this.FormCargando = true;
          axios.post(process.env.VUE_APP_API_BASE_URL + "status-2fa", {defixId: this.$parent.dataUser.defixId})
            .then((response) => {
              switch (response.data) {
                case true: {
                  this.$refs.modalswap.ModalSwap = false;
                  this.OpenModalDosFa();
                }
                  break;
                case false: this.Realizarswap();
                  break;
                default: {
                  this.$refs.alert.alertCancelled = true;
                  this.$refs.alert.Titulo = this.$t("Error ejecutar swap");
                  this.$refs.alert.Alerta = this.$t("Ha ocurrido un error inesperado, vuelva a intentarlo mas tarde");
                }
                  break;
              }
              this.FormCargando = false;
            })
        }
      });
    },
    Realizarswap(code) {
      this.$parent.verificarSession().then((res) => {
        if(res != true) {
          let json = {
            fromCoin: this.dataSwap[0].desc,
            toCoin: this.dataSwap[1].desc,
            amount: this.dataSwap[0].input,
          };
          axios.post(process.env.VUE_APP_API_BASE_URL + "swap-preview-eth", json)
            .then((response) => {
              if(response.data) {
                let jsonfinal = {
                  fromDefix: this.$parent.dataUser.defixId,
                  fromCoin : this.dataSwap[0].desc,
                  toCoin: this.dataSwap[1].desc,
                  privateKey: this.$parent.dataUser.eth_credentials.privateKey, 
                  priceRoute: response.data.priceRoute, 
                  code: code ? code : null,
                };
                axios.post(process.env.VUE_APP_API_BASE_URL + "swap-token-eth", jsonfinal)
                  .then((response) => {
                    console.log("////////////////////////////////////////")
                    console.log(response.data)
                    console.log("////////////////////////////////////////")
                    switch (response.data.respuesta) {
                      case "ok": {
                        this.$refs.alert.alertSuccess = true;
                        this.$refs.alert.Titulo = this.$t("alertTitulo4");
                        this.$refs.alert.Alerta = this.$t("dExchange completed successfully.");
                        this.$parent.CargarBalance(this.FormCargando);
                        this.$parent.$refs.historico.CargarHistorico();
                        this.$refs.modalswap.ModalSwap = false;
                      }
                        break;
                      case "code": {
                        this.$refs.alert.alertWarning = true;
                        this.$refs.alert.Titulo = this.$t("Advertencia");
                        this.$refs.alert.Alerta = this.$t("Su codigo 2fa no es valido");
                        this.$refs.modaldosfa.ModalDosfa = false;
                      }
                        break;
                      default: {
                        this.$refs.alert.alertCancelled = true;
                        this.$refs.alert.Titulo = this.$t("Error al realizar el swap");
                        this.$refs.alert.Alerta = this.$t("Ha ocurrido un error inesperado, vuelva a intentarlo mas tarde");
                        this.$refs.modaldosfa.ModalDosfa = false;
                        this.$refs.modalswap.ModalSwap = false;
                      }
                        break;
                    } 
                    this.FormCargando = false;
                    this.$refs.modaldosfa.loading = false;
                    this.$refs.modaldosfa.ModalDosfa = false;
                  })
                  .catch(() => {
                    this.$refs.alert.alertCancelled = true;
                    this.$refs.alert.Titulo = this.$t("Error al realizar el swap");
                    this.$refs.alert.Alerta = this.$t("Ha ocurrido un error inesperado, vuelva a intentarlo mas tarde");
                    this.FormCargando = false;
                    this.$refs.modaldosfa.loading = false;
                    this.$refs.modaldosfa.ModalDosfa = false;

                    this.$refs.modalswap.ModalSwap = false;
                  });
              }
            })
            .catch(() => {
              this.$refs.alert.alertCancelled = true;
              this.$refs.alert.Titulo = "Error al realizar el swap";
              this.$refs.alert.Alerta = "Ha ocurrido un error inesperado, vuelva a intentarlo mas tarde";
              this.FormCargando = false;
              this.$refs.modaldosfa.loading = false;
              this.$refs.modaldosfa.ModalDosfa = false;
              this.$refs.modalswap.ModalSwap = false;
            });
        }
      });
    },
    switchToken() {
      this.playIcon = !this.playIcon;
      this.disableSwitch = true;
      let token = this.dataSwap[0].token;
      let nombre = this.dataSwap[0].desc;
      let balance = this.dataSwap[0].balance;
      let price = this.dataSwap[0].price;
      let flechita = this.dataSwap[0].flechita;

      this.dataSwap[0].token = this.dataSwap[1].token;
      this.dataSwap[0].desc = this.dataSwap[1].desc;
      this.dataSwap[0].balance = this.dataSwap[1].balance;
      this.dataSwap[0].price = this.dataSwap[1].price;
      this.dataSwap[0].flechita = this.dataSwap[1].flechita;
      this.dataSwap[0].input = this.dataSwap[1].input;

      this.dataSwap[1].token = token;
      this.dataSwap[1].desc = nombre;
      this.dataSwap[1].balance = balance;
      this.dataSwap[1].price = price;
      this.dataSwap[1].flechita = flechita;
      this.dataSwap[1].input = null;
      
      if(this.dataSwap[0].input != null) {
        let json = {
              fromCoin: this.dataSwap[0].desc,
              toCoin: this.dataSwap[1].desc,
              amount: this.dataSwap[0].input,
          };
        
        axios.post(process.env.VUE_APP_API_BASE_URL + "swap-preview-eth", json)
          .then((response) => {
            if(response.data) {
              //console.log(response.data)
              //this.dataConsultaSwap.amount = response.data.srcAmount / Math.pow(10, response.data.srcDecimals);
              //this.dataConsultaSwap.AmountDestino = response.data.destAmount / Math.pow(10, response.data.destDecimals);
              //this.dataConsultaSwap.AmountDestinoUsd = parseFloat(response.data.destUSD).toFixed(2);
              this.dataConsultaSwap.gas = response.data.gasCost;
              this.dataConsultaSwap.gasUsd = parseFloat(response.data.gasCostUSD).toFixed(2);

              this.dataSwap[1].input = response.data.destAmount / Math.pow(10, response.data.destDecimals)

              this.dataSwap[0].pricePriview = parseFloat(this.dataSwap[0].input) * parseFloat(this.dataSwap[0].price) > 0 ? "~$ " + (parseFloat(this.dataSwap[0].input) * parseFloat(this.dataSwap[0].price)) : this.dataSwap[0].input == null ? "~$ " : "~$ " + this.dataSwap[0].input;
              this.dataSwap[1].pricePriview = parseFloat(this.dataSwap[1].input) * parseFloat(this.dataSwap[1].price) > 0 ? "~$ " + (parseFloat(this.dataSwap[1].input) * parseFloat(this.dataSwap[1].price)) : this.dataSwap[1].input == null ? "~$ " : "~$ " + this.dataSwap[1].input;
            }
            this.disableSwitch = false;
          })
          .catch((error) => {
            console.log("error: ", error);
            alert("algo paso")
            this.disableSwitch = false;
          });
      }
    },
     openCrypto(data) {
      this.$refs.modalcrypto.dataCrypto = this.$parent.dataCartera.filter(item => item.title !== "BTC").filter(item => item.title !== "NEAR");
      if (data.inputP == 0) {
        this.$refs.modalcrypto.dataCrypto = this.$refs.modalcrypto.dataCrypto.filter(item => item.title !== this.dataSwap[1].desc)
        this.openCryptoA();
      } else {
        this.$refs.modalcrypto.dataCrypto = this.$refs.modalcrypto.dataCrypto.filter(item => item.title !== this.dataSwap[0].desc)
        this.openCryptoB();
      }
    },
    ChangeIE(item) {
      this.$parent.$refs.enviar.montoDivisa = 0;
      this.$parent.$refs.enviar.messageAddress = null;
      this.$parent.$refs.enviar.messageAmount = null;
      this.$parent.$refs.enviar.disabledEnvio = true;
      this.$parent.$refs.enviar.validar_amount = this.$parent.$refs.enviar.validar_address = false;
      this.$parent.$refs.enviar.totalFee = 0;
      if (item.key == 'enviar') {
        this.$parent.Exchange = false;
        //this.$parent.$refs.enviar.messageAddress = null;
        //this.$parent.$refs.enviar.messageAmount = null;
      }
    },
    clearCard() {
      this.$parent.Dapps = false;
      this.$parent.Historico = false;
      this.$parent.Fiat = false;
      this.$parent.EstadoTransaccion = false;
      this.$parent.Perfil = false;
      this.$parent.Cartera = false;
      this.$parent.Portafolio = false;
      this.$parent.ExchangeCard = false;
    },
    volverAtras() {
      if (window.innerWidth <= 880) {
        this.clearCard()
        this.$parent.Cartera = true;
        //
        this.$parent.leftCard = true;
        this.$parent.centerCard = false;
        this.$parent.rightCard = false;
      } else {
        this.$parent.Exchange = false;
      }
    },
    openCryptoA() {
      this.$refs.modalcrypto.ModalCryptoA = true;
      this.$refs.modalcrypto.Fix = true;
      setTimeout(() => {
        this.$refs.modalcrypto.Fix = false;
      }, 1000);
    },
    openCryptoB() {
      this.$refs.modalcrypto.ModalCryptoB = true;
      this.$refs.modalcrypto.Fix = true;
      setTimeout(() => {
        this.$refs.modalcrypto.Fix = false;
      }, 1000);
    },
    selectTokenA(item) {
      this.dataSwap[0].token = item.token;
      this.dataSwap[0].desc = item.title;
      this.dataSwap[0].balance = "Balance " + item.amountToken + " " + item.title,
      this.dataSwap[0].price = "~$ " + item.amountDollar + " / " + item.state,
      this.dataSwap[0].input = null,
      this.$refs.modalcrypto.ModalCryptoA = false;
      this.ConsultarFee(item.title)
    },
    selectTokenB(item) {
      this.dataSwap[1].token = item.token;
      this.dataSwap[1].desc = item.title;
      this.dataSwap[1].balance = "Balance " + item.amountToken + " " + item.title,
      this.dataSwap[1].price = "~$ " + item.amountDollar + " / " + item.state,
      this.dataSwap[1].input = null,
      this.$refs.modalcrypto.ModalCryptoB = false;
    },
    ConsultarFee(coin) {
      axios.get(process.env.VUE_APP_API_BASE_URL_DJANGO + "get-comision/" + coin)
        .then((response) => {    
          if(response.data) {
            this.dataConsultaSwap.fee = response.data.swap;
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
  },
};
</script>

<style src="./Right.scss" lang="scss" />

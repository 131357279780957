import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({

  namespace: true,
  state: {
    baseURL: process.env.BASE_URL,
    theme: "dark",
    dataDrawer: [
      {
        key: "cartera",
        title: "Portafolio",
        icon: "mdi-briefcase",
        color: false,
      },
      {
        key: "fiat",
        title: "Fiat",
        icon: "mdi-currency-usd",
        color: false,
      },
      {
        key: "dapps",
        title: "Dapps",
        icon: "mdi-format-line-weight",
        color: false,
      },
      {
        key: "exchange",
        title: "dExchange",
        icon: "mdi-cached",
        style: "transform: rotate(30deg)",
        color: false,
      },
      {
        key: "more",
        title: ".",
        icon: "mdi-dots-vertical",
        color: false,
      },
    ],
    dataMore: [
      {
        key: "perfil",
        title: "Perfil",
        icon: "mdi-account-circle",
        color: false,
      },
      {
        key: "market",
        title: "Markets",
        icon: "mdi-shopping",
        color: false,
      },
    ]
  },
  mutations: {
    CambiarTheme(state, theme) {state.theme = theme},
    ClearNavigation(state) {
      state.dataDrawer.forEach((element) => (element.color = false));
      state.dataMore.forEach((element) => (element.color = false));
    },
    SwitchNavBtn(state) {
      if (window.innerWidth > 880) {
        state.dataDrawer.splice(state.dataDrawer.findIndex(data=>data.key=='more'), 1)
        state.dataMore.forEach(e=>{
          if (state.dataDrawer.findIndex(data=>data==e) == -1 && e.key=='perfil') {state.dataDrawer.push(e)}
        })
      }
    },
    Navigation(state, item) {
      // pintar botones navbar
      const indexDrawer = state.dataDrawer.findIndex(data=>data.key==item.key)
      const indexMore = state.dataMore.findIndex(data=>data.key==item.key)
      state.dataDrawer.forEach((element) => (element.color = false));
      if (item.key!=='more') {state.dataMore.forEach((element) => (element.color = false))}
      if (item.key == 'cartera') {state.dataDrawer[indexDrawer].color = true}
      if (item.key == 'fiat') {state.dataDrawer[indexDrawer].color = true}
      if (item.key == 'dapps') {state.dataDrawer[indexDrawer].color = true}
      if (item.key == 'exchange') {state.dataDrawer[indexDrawer].color = true}
      if (item.key == 'more') {state.dataDrawer[indexDrawer].color = true}
      if (window.innerWidth <= 880) {
        if (item.key == 'perfil') {state.dataMore[indexMore].color = true}
        if (item.key == 'market') {state.dataMore[indexMore].color = true}
      } else {
        if (item.key == 'perfil') {state.dataDrawer[indexDrawer].color = true}
        if (item.key == 'market') {state.dataDrawer[indexDrawer].color = true}
      }
    },
  },
  actions: {
    CambiarTheme({state, commit}, {theme, element}) {
      element.href = `${state.baseURL}themes/${theme}/theme.css`;
      localStorage.setItem("theme", theme);
      commit( "CambiarTheme", theme)
    },
    Navigation({commit}, {item}) {
      // pintar cartas
      if (window.innerWidth >= 880) {
        if (
          item.key == 'fiat' ||
          item.key == 'dapps' ||
          item.key == 'perfil' ||
          item.key == 'cartera'
        ) {
          document.getElementById("leftCard").style.boxShadow =
            "inset 0px -2px 6px 2px #6667AB";
          setTimeout(() => {
            document.getElementById("leftContainerCard").style.boxShadow =
              "inset 0px -2px 6px 2px #6667AB";
          }, 200);
        } else {
          document.getElementById("leftCard").style.boxShadow =
            "inset 0px -2px 6px 1px #707070";
          setTimeout(() => {
            document.getElementById("leftContainerCard").style.boxShadow =
              "inset 0px -2px 6px 2px #707070";
          }, 200);
        }
        if (item.key == "market") {
          document.getElementById("centerCard").style.boxShadow =
            "inset 0px -2px 6px 2px #6667AB";
        } else {
          document.getElementById("centerCard").style.boxShadow =
            "inset 0px -2px 6px 1px #707070";
        }
        if (item.key == "exchange") {
          document.getElementById("rightCard").style.boxShadow =
            "inset 0px -2px 6px 2px #6667AB";
        } else {
          document.getElementById("rightCard").style.boxShadow =
            "inset 0px -2px 6px 1px #707070";
        }
      }
      commit( "Navigation", item)
    },
  },
});

module.exports = {
  messages: {
    ES: {
      bm5:'Idioma',
    },
    US: {
      bm5:'Language',
    }
  }
}
  
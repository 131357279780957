<template>
  <section class="containerCartas">
    <ModalCrypto ref="modalcrypto"></ModalCrypto>
    <ModalUsers ref="modalusers"></ModalUsers>
    <ModalDosFa ref="modaldosfa"></ModalDosFa>
    <Alert ref="alert"></Alert>
    <v-col cols="11" md="11" lg="10" style="justify-content: space-between" class="containerTitulo">
      <v-col class="paddvertical divcol">
        <span class="titulo h6-em">{{ $t("Enviar") }}</span>
        <span class="desc h7-em">{{ this.$parent.dataUser.defixId }}</span>
      </v-col>
      <!-- <div class="divrow center" v-if="user">
        <v-badge
          :content="messages"
          :value="messages"
          color="red"
          overlap
          bordered
        >
          <v-icon color="#6667AB" medium>mdi-bell</v-icon>
        </v-badge>
        <img class="user" src="@/assets/app/user.png" alt="token" />
      </div> -->
    </v-col>

    <v-card class="cartaInside cartaIE">
      <v-col cols="11" md="11" lg="10" style="margin-inline: auto; padding-bottom: 0" class="divcol">
        <!--<div class="end">
          <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB"
            @click="volverAtras()">
            <v-icon medium>mdi-arrow-left-thin</v-icon>
          </v-btn>
        </div>-->
        <div class="center">
          <aside class="containerBotonesIE">
            <v-btn
              v-for="(item, index) in dataBotonesIE"
              v-bind:key="index"
              class="botonesIE h8-em"
              style="text-transform: none !important;"
              :class="{botonesIEActive: item.color}"
              @click="ChangeIE(item)"
            >
              <span>{{ $t(item.text) }}</span>
            </v-btn>
          </aside>
        </div>
        <aside class="divrow end mb-9">
        </aside>
      </v-col>

      <v-col md="12" lg="10" style="margin-inline: auto">
        <v-card class="containerSwap2" style="position: relative">
          <span class="flotingText h8-em">{{ $t("Desde") }}</span>
          <v-col class="paddvertical">
            <div class="divrow" style="justify-content: space-between; align-items: center; margin-bottom: 5px">
              <aside id="containerTokens" class="divrow center" @click="openCryptoC()">
                <img class="token2" :src="dataCoin.token" alt="token">
                <span class="desc h8-em" style="margin-left: 5px">{{ dataCoin.title }}</span>
                <v-icon small>mdi-chevron-down</v-icon>
              </aside>
              <v-text-field
                v-model="amount"
                type="text"
                hide-spin-buttons
                :hide-details="hideDetails"
                background-color="transparent"
                solo
                flat
                reverse
                @blur="ValidarAmount()"
                @keyup="ValidarAmount()"
              >
                <template v-slot:label>
                  <span class="h7-em" ref="inputamount">0.00</span>
                </template>
              </v-text-field>
            </div>
            <div class="" style="justify-content: space-betw  een; align-items: center;
              margin-inline: 13px">
              <span class="h8-em">{{ $t("Disponible") }} {{ dataCoin.amountToken }} {{ dataCoin.title }} <span v-if="montoDivisa">~ ${{ montoDivisa }}</span></span>
              <v-btn id="botonMax" class="h9-em" @click="maxAmount()" style="float:right">
                <span>Max</span>
              </v-btn>
            </div>
          </v-col>
          <span v-if="messageAmount" style="color:red"> {{ $t(messageAmount) }} </span>
        </v-card>

        <div class="center mb-7">
          <aside class="containerBotonesIE">
            <v-btn
              v-for="(item, index) in dataTypeAddress"
              v-bind:key="index"
              class="botonesIE h9-em"
              :class="{botonesIEActive: item.color}"
              @click="ChangeTypeAddress(index)"
            >
              <span style="text-transform: none !important; ">{{ $t(item.text) }}</span>
            </v-btn>
          </aside>
        </div>

        <v-card class="containerSwap2" style="position: relative">
          <span class="flotingText h8-em">
            <span v-if=" TypeAddress == 1">
              {{ $t("Defix User") }}
            </span>
            <span v-if=" TypeAddress == 2">
              {{ $t("Dirección") }}
            </span>
          </span>
          <v-col class="paddvertical" style="padding-right: 0">
            <v-text-field
              v-model="address"
              type="text"
              hide-spin-buttons
              :hide-details="hideDetails"
              background-color="transparent"
              solo
              flat
              @blur="ValidarAddress()"
              @keyup="ValidarAddress()"
            >
              <template v-slot:append>
                <div class="center">
                  <v-btn v-if=" TypeAddress == 1" @click="ListaUsers()" max-height="30" max-width="30" icon>
                    <v-icon color="#6667AB" medium>{{ iconWallet }}</v-icon>
                  </v-btn>
                  <span v-if=" TypeAddress == 2" max-height="30" max-width="30" icon>
                    <v-icon color="#6667AB" medium>{{ iconWallet }}</v-icon>
                  </span>
                  <!--<v-btn max-height="30" max-width="30" icon>
                    <v-icon color="#6667AB" medium>mdi-fit-to-screen</v-icon>
                  </v-btn>-->
                </div>
              </template>
            </v-text-field>
          </v-col>
          <span v-if="messageAddress" style="color:red"> {{ messageAddress }} </span>
        </v-card>

        <!--<span class="h9-em end paddright">{{ fee }} % <span span class="ml-2" style="font-weight:bold"> ~ {{ $t('Fee defix3') }}</span></span>
        <span class="h9-em end paddright">{{ feeEnvio }} <span span class="ml-2" style="font-weight:bold"> ~ {{ $t('Fee') }}</span></span>-->
        <span class="h9-em end paddright">{{ totalFee }} <span class="ml-3">(${{ (dataCoin.price * totalFee).toFixed(2) }})</span> <span span class="ml-2" style="font-weight:bold"> ~ {{ $t('Fee') }}</span></span>

        <!-- <v-card class="containerSwap2" style="position: relative">
          <v-col class="paddvertical">
            <div class="divrow" style="justify-content: space-between; align-items: center">
              <aside id="containerTokens" class="divrow center" @click="openRed()">
                <span class="desc h8-em" style="margin-left: 5px">Red</span>
                <v-icon small>mdi-chevron-down</v-icon>
              </aside>
              <span class="h7-em">{{ redCrypto }}</span>
            </div>
          </v-col>
        </v-card> -->

        <aside class="botonFinalEnviar">
          <v-btn
            :disabled="disabledEnvio"
            class="botonSwap h7-em"
            @click="EnviarCoin()"
          >
            {{ $t("Enviar") }}
            <span v-if="FormCargando">
              <v-progress-circular
                :indeterminate="FormCargando"
                :value="0"
                size="24"
              ></v-progress-circular>
            </span>
          </v-btn>
        </aside>
      </v-col>
    </v-card>
  </section>
</template>

<script>
import ModalCrypto from "@/pages/app/Right/ModalCrypto.vue";
import ModalUsers from "@/pages/app/Right/ModalUsers.vue";
import ModalDosFa from "@/pages/app/ModalDosFa/ModalDosFa.vue"
import Alert from "@/pages/app/Alert/Alert.vue"
import axios from "axios";

export default {
  name: "DexExchange",
  i18n: require("./i18n"),
  components: {
    ModalCrypto,
    ModalUsers,
    ModalDosFa,
    Alert,
  },
  data() {
    return {
      messages: 0,
      FormCargando: false,
      Rules: [
        v => !!v || this.$t("Requerido"),
      ],
      user: false,
      hideDetails: true,
      amount: null,
      address: null,
      dataCoin: {},
      montoDivisa: null,
      dataBotonesIE: [
        {
          text: "Enviar",
          color: true
        },
        {
          key: "intercambiar",
          text: "Intercambiar",
          color: false
        },
      ],
      dataTypeAddress: [
        {
          text: "Defix User",
          color: true
        },
        {
          text: "Address",
          color: false
        }
      ],
      TypeAddress: "1",
      iconWallet: "mdi-account-circle",
      messageAmount: null,
      messageAddress: null,
      validar_amount: false,
      validar_address: false,
      fee: 0,
      feeEnvio: 0,
      totalFee: 0,
      disabledEnvio: true,
      //token: require("@/assets/app/token/ethereum.svg"),
      //desc: "ETH",
      //redCrypto: "Ethereum (ERC20)"
    };
  },
  mounted() {
    if (window.innerWidth <= 880) {
      this.user = true
    }
  },
  methods: {
    ChangeIE(item) {
      this.amount = null;
      this.address = null;
      this.montoDivisa = 0;
      this.messageAddress = null;
      this.messageAmount = null;
      this.disabledEnvio = true;
      this.validar_amount = this.validar_address = false;
      this.totalFee = 0;
      if (item.key == 'intercambiar') {
        this.$parent.Exchange = true;
      }
    },
    ChangeTypeAddress(index) {
      this.dataTypeAddress.forEach(item => item.color = false)
      this.dataTypeAddress[index].color = true
      if(this.dataTypeAddress[index].text == "Address") {
        this.TypeAddress = "2";
        this.iconWallet = "wallet";
      } else {
        this.TypeAddress = "1";
        this.iconWallet = "mdi-account-circle";
      }
      this.address = null;
      this.messageAddress = null;
      this.disabledEnvio = true;
    },
    clearCard() {
      this.$parent.Dapps = false;
      this.$parent.Historico = false;
      this.$parent.Fiat = false;
      this.$parent.EstadoTransaccion = false;
      this.$parent.Perfil = false;
      this.$parent.Cartera = false;
      this.$parent.Portafolio = false;
      this.$parent.ExchangeCard = false;
    },
    volverAtras() {
      if (window.innerWidth <= 880) {
        this.clearCard()
        this.$parent.Cartera = true;
        //
        this.$parent.leftCard = true;
        this.$parent.centerCard = false;
        this.$parent.rightCard = false;
      } else {
        this.$parent.Exchange = true
      }
    },
    openCryptoC() {
      this.$refs.modalcrypto.dataCrypto = this.$parent.dataCartera;
      this.$refs.modalcrypto.ModalCryptoC = true;
      this.$refs.modalcrypto.Fix = true;
      setTimeout(() => {
        this.$refs.modalcrypto.Fix = false;
      }, 1000);
    },
    selectTokenC(item) {
      this.ConsultarFeeDefix(item.title);
      this.dataCoin = this.$refs.modalcrypto.dataCrypto.filter(data =>  data.title === item.title)[0];
      /*this.token = item.token;
      this.desc = item.desc;*/
      this.$refs.modalcrypto.ModalCryptoC = false;
      this.amount = null;
      this.address = null;
      this.messageAddress = null;
      this.messageAmount = null;
      this.disabledEnvio = true;
      this.validar_amount = this.validar_address = false;
      this.totalFee = 0;
      this.montoDivisa = null;
    },
    ConsultarFeeDefix(coin) {
      axios.get(process.env.VUE_APP_API_BASE_URL_DJANGO + "get-comision/" + coin)
        .then((response) => {    
          if(response.data) {
            this.fee = response.data.transfer;
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    /*ConsultarFee(coin) {
      axios.get(process.env.VUE_APP_API_BASE_URL + "get-comisiones")
        .then((response) => {    
          if(response.data) {
            console.log(coin)
            console.log(response.data)
            let index = response.data.findIndex(x => x.coin == coin);
            console.log(response.data[index].fee);
            this.messageAmount = "Solo se permiten valores numericos";
            this.validar_amount = false
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },*/
    selectRed(item) {
      this.redCrypto = item.red;
      this.$refs.modalcrypto.ModalRed = false;
    },
    maxAmount() {
      let json = {
        address: this.$parent.dataUser.btc_credentials.address,
        amount: this.dataCoin.amountToken
      }
      axios.post(process.env.VUE_APP_API_BASE_URL + "get-comisiones", json)
      .then((response) => {    
        if(response.data) {
          let index = response.data.findIndex(x => x.coin == this.dataCoin.title);
          let feeEnvio = parseFloat(response.data[index].fee);
          let feedefix = this.dataCoin.title == "NEAR" ? parseFloat(this.fee) : feeEnvio * (parseFloat(this.fee) / 100);
          this.totalFee = parseFloat(this.fee) > 0 ?  (feeEnvio + feedefix).toFixed(8) : feeEnvio.toFixed(8);
          if(isNaN(this.totalFee)) {
            console.log("paso por aqui")
            this.amount = "0";
          } else {
            console.log("paso por aqui 2")
            if(this.dataCoin.title == "USDT" || this.dataCoin.title == "USDC" || this.dataCoin.title == "DAI") {
              console.log("paso por aqui 3")
              this.amount = this.dataCoin.amountToken.toString();
            } else {
              console.log("paso por aqui 4")
              this.amount = this.totalFee > parseFloat(this.dataCoin.amountToken) ? "0" : (parseFloat(this.dataCoin.amountToken) - this.totalFee).toFixed(9);
              console.log("paso por aqui 5")
              console.log(("amount - fee ", parseFloat(this.dataCoin.amountToken) - this.totalFee), "amount ", parseFloat(this.dataCoin.amountToken), "fee ", this.totalFee)
              console.log("paso por aqui 6")
            }
          }
          console.log("max amount:", this.amount)
          this.ValidarAmount(true);
        }
      })
      .catch((error) => {
        console.log("error max amount: ", error);
      });
  
    },
    isNumeric(val) {
      if (/^-?\d+$/.test(val) ) {
        return true
      } else if ( /^\d+(.\d+)$/.test(val) ) {
        return true
      } else { 
        return false
      }
    },
    ValidarAmount(maxAmount) {
      this.amount = this.amount.split(",").join(".");
      this.amount = this.amount.split(" ").join("");
      if(this.amount === null || this.amount.length <= 0) {
        this.disabledEnvio = true;
        this.messageAmount = "Requerido";
        this.validar_amount = false
        this.montoDivisa = null
        this.amount = null
        this.totalFee = this.feeEnvio = 0;
      } else {
        //this.messageAmount = null;
        let numerico =this.isNumeric(this.amount);
        if(numerico) {
          let json = {
            address: this.$parent.dataUser.btc_credentials.address,
            amount: maxAmount == true ? this.dataCoin.amountToken : this.amount.toString(),
          }
          axios.post(process.env.VUE_APP_API_BASE_URL + "get-comisiones", json)
          .then((response) => {    
            if(response.data) {
              //console.log(response.data)
              let index = response.data.findIndex(x => x.coin == this.dataCoin.title);
              let feeEnvio = this.feeEnvio = response.data[index].fee == false ? 0 : parseFloat(response.data[index].fee)
              console.log("fee:", response.data[index].fee)
              let feedefix = this.dataCoin.title == "NEAR" ? parseFloat(this.fee) : feeEnvio * (parseFloat(this.fee) / 100);
              this.totalFee = parseFloat(this.fee) > 0 ? (feeEnvio + feedefix).toFixed(8) : feeEnvio.toFixed(8);
              console.log("amount: ", parseFloat(this.amount), "- saldo: ", parseFloat(this.dataCoin.amountToken), " - saldo - fee: ", parseFloat(this.dataCoin.amountToken) - this.totalFee)
              console.log("total fee: ", this.totalFee)
              console.log("verificar: ", parseFloat(this.amount), parseFloat(this.dataCoin.amountToken) - this.totalFee)
              console.log(("amount - fee ", parseFloat(this.dataCoin.amountToken) - this.totalFee), "amount ", parseFloat(this.dataCoin.amountToken), "fee ", this.totalFee)
              if(parseFloat(this.amount) <= parseFloat(this.dataCoin.amountToken)) {
                if(this.dataCoin.title == "USDT" || this.dataCoin.title == "USDC" || this.dataCoin.title == "DAI") {
                  if((parseFloat(this.$parent.dataCartera[this.$parent.dataCartera.findIndex(x => x.title == "ETH")].amountToken) - this.totalFee) < 0) {
                    this.disabledEnvio = true;
                    this.messageAmount = this.$t("No cuenta con suficiente ETH para cubrir la tarifa");
                    this.validar_amount = false;
                  } else {
                    this.validar_amount = true;
                  }
                } else {
                  if(parseFloat(this.amount) > (parseFloat(this.dataCoin.amountToken) - this.totalFee)) {
                    this.disabledEnvio = true;
                    this.messageAmount = this.$t("No cuenta con suficiente ") + this.dataCoin.title + this.$t(" para cubrir la tarifa");
                    this.validar_amount = false
                  } else {
                    this.validar_amount = true;
                  }
                }
              } else {
                this.disabledEnvio = true;
                this.totalFee = 0;
                this.messageAmount = this.$t("messageAmount");
                this.validar_amount = false
              }
              //if(this.totalFee )

              index = this.$parent.dataCartera.findIndex((x) => x.title === this.dataCoin.title)
              this.montoDivisa = (this.$parent.dataCartera[index].price * parseFloat(this.amount)).toFixed(2)
              
              this.validarEnvio();
              if(this.validar_amount) {
                //this.disabledEnvio = false;
                this.messageAmount = null;
                //if(isNaN(this.amount) === false) {
                
                /*if(parseFloat(monto) <= parseFloat(this.dataCoin.amountToken)) {
                  this.messageAmount = null;
                  this.validar_amount = true
                } else {
                  this.messageAmount = this.$t("messageAmount");
                  this.validar_amount = false
                }*/
                //this.ConsultarFee();
                /*} else {
                  this.messageAmount = this.$t("messageAmount2");
                  this.validar_amount = false
                }*/
              }
              
            }
          })
          .catch((error) => {
            console.log("error consultar fee: ", error);
          });
        } else {
          this.disabledEnvio = true;
          this.messageAmount = "Solo se permiten valores numericos";
          this.validar_amount = false
          this.montoDivisa = null
          this.totalFee = this.feeEnvio = 0;
        }
      }
    },
     validarEnvio() {
      console.log(this.validar_amount, this.validar_address)
      if(this.validar_amount && this.validar_address) {
        this.disabledEnvio = false;
        //this.messageAmount = null;
        //this.messageAddress = null;
      } else {
        this.disabledEnvio = true;
      }
    },
    ValidarAddress() {
      this.address = this.address.split(" ").join("");
      let TypeAddress  = this.dataTypeAddress[this.dataTypeAddress.findIndex(item => item.text == "Address")].color;
      if(this.address === null || this.address.length <= 0) {
        this.messageAddress = this.$t("Requerido");
        this.validar_address = false
      } else if(this.address === this.$parent.dataUser.defixId && TypeAddress === false && this.TypeAddress == "1") {
        this.messageAddress = this.$t("messageAddress");
        this.validar_address = false
      } else if(TypeAddress === false) {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "validate-defix3", { defixId: this.address})
          .then((response) => {
            if(response.data) {
              this.messageAddress = null;
              this.validar_address = true;
            } else {
              this.messageAddress = this.$t("messageAddress2");
              this.validar_address = false;
            }
            this.validarEnvio();
          }).catch((error) => {
            console.log(error);
            this.messageAddress = this.$t("messageAddress2");
            this.validar_address = false;
          });
      } else if(TypeAddress) {
        let data = JSON.parse(localStorage.getItem('dataUser'));
        if(this.address.trim() == data[this.dataCoin.title.toLowerCase() + '_credentials'].address.trim()) {
          this.messageAddress = this.$t("messageAddress3");
          this.validar_address = false;
        } else {
          let json = {
            address: this.address,
            coin: this.dataCoin.title
          }
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "validate-address", json)
            .then((response) => {
              console.log("adrdress valida ", response.data);
              if(response.data) {
                console.log("paso por la validacion del address")
                this.messageAddress = null;
                this.validar_address = true;
              } else {
                this.messageAddress = this.$t("This address is not a valid ") + this.dataCoin.title + this.$t(" address");
                this.validar_address = false;
              }
              this.validarEnvio()
            }).catch((error) => {
              console.log(error);
              this.messageAddress = this.$t("messageAddress2");
              this.validar_address = false;
            });
        }
      } 
      
      /*else {
        if(this.address.length > 0) {
          this.messageAddress = null;
          this.validar_address = true;
        }
      }*/
    },
    OpenModalDosFa() {
      this.$refs.modaldosfa.ModalDosfa = true;
      this.$refs.modaldosfa.Fix = true;
      this.$refs.modaldosfa.accion = "enviar";
      this.$refs.modaldosfa.code = null;
      setTimeout(() => {
        this.$refs.modaldosfa.Fix = false;
      }, 1000);
    },
    EnviarCoin() {
      //this.ValidarAmount();
      //this.ValidarAddress();
      this.$parent.verificarSession().then((res) => {
        if(res != true) {
          if(this.validar_amount && this.validar_address) {
            this.FormCargando = this.disabledEnvio = true;
            axios.post(process.env.VUE_APP_API_BASE_URL + "status-2fa", {defixId: this.$parent.dataUser.defixId})
              .then((response) => {
                switch (response.data) {
                  case true: {
                    this.FormCargando = this.disabledEnvio = false;
                    this.OpenModalDosFa();
                  }
                    break;
                  case false: {
                    this.EjecutarEnviarCoin();
                  }
                    break;
                  default: {
                    this.$refs.alert.alertCancelled = true;
                    this.$refs.alert.Titulo = this.$t("Error");
                    this.$refs.alert.Alerta = this.$t("Ocurrio un error inesperado por favor vuelva a intentarlo mas tarde !!!");
                    this.FormCargando = this.disabledEnvio = false;
                  }
                    break;
                }
              })
          }
        }
      })
    },
    EjecutarEnviarCoin(code) {
      let json = {
        fromDefix: this.$parent.dataUser.defixId,
        privateKey: this.$parent.dataUser[this.dataCoin.title.toLowerCase() + '_credentials'].privateKey,
        toDefix: this.address,
        coin: this.dataCoin.title,
        amount: this.amount,
        type: this.TypeAddress,
        code: code ? code : null,
      }
      console.log('lola json', json)
      axios.post(process.env.VUE_APP_API_BASE_URL + "transaction", json)
        .then((response) => {
          this.messageAmount = null
          this.messageAddress = null
          console.log(response.data)
          switch (response.data.respuesta) {
            case "ok": {
              this.amount = null;
              this.address = null;
              this.montoDivisa = 0;
              this.messageAddress = null;
              this.messageAmount = null;
              this.disabledEnvio = true;
              this.validar_amount = this.validar_address = false;
              this.totalFee = 0;
              console.log("apagar envio: ", this.disabledEnvio)
              this.$refs.alert.alertSuccessEnvio = true;
              this.$refs.alert.Titulo = this.$t("alertTitulo");
              this.$refs.alert.Alerta = this.$t("alertAlerta");
              switch (process.env.VUE_APP_API_BASE_RED) {
                case 'mainnet': this.$refs.alert.explorer = this.$parent.dataCartera.filter(item => item.title == response.data.data.coin)[0].explorerMainnetTransactions + response.data.data.hash;
                  break;
                case 'testnet': this.$refs.alert.explorer = this.$parent.dataCartera.filter(item => item.title == response.data.data.coin)[0].explorerTestnetTransactions + response.data.data.hash;
                  break;
              }
              this.$parent.CargarBalance(this.FormCargando);
              this.$parent.$refs.historico.CargarHistorico();
              this.$refs.modaldosfa.ModalDosfa = false;
              
            }
              break;
            case "code": {
              this.$refs.alert.alertWarning = true;
              this.$refs.alert.Titulo = this.$t("Advertencia");
              this.$refs.alert.Alerta = this.$t("Su codigo 2fa no es valido");
              this.$refs.modaldosfa.ModalDosfa = false;
              this.disabledEnvio = false
            }
              break;
            case "wallet": {
              this.messageAddress = this.$t("messageAddress2");
              this.$refs.alert.alertPending = true;
              this.$refs.alert.Titulo = this.$t("alertTitulo2");
              this.$refs.alert.Alerta = this.$t("messageAddress2");
              this.$refs.modaldosfa.ModalDosfa = false;
              this.disabledEnvio = false
            }
              break;
          } 
          this.FormCargando = false;
          this.$refs.modaldosfa.loading = false;
          //console.log("then")
          //console.log(response);
        })
        .catch(() => {
          this.FormCargando = this.disabledEnvio = false;
          this.$refs.alert.alertCancelled = true;
          this.$refs.alert.Titulo = this.$t("alertTitulo3");
          this.$refs.alert.Alerta = this.$t("alertAlerta2");
          this.$refs.modaldosfa.loading = false;
          //console.log("cath")
          //console.log(response);
        });
    },
    ListaUsers() {
      axios.post(process.env.VUE_APP_API_BASE_URL + "get-frequent", {defixId: this.$parent.dataUser.defixId.trim()})
        .then((response) => {
          if(response.data) {
            this.$refs.modalusers.dataUsers = response.data.map(item => { return { user: require("@/assets/app/icon-user.png"), userDefix: item.frequent_user, id: item.id} })
            this.$refs.modalusers.ModalUsers = true;
            this.$refs.modalusers.Fix = true;
            setTimeout(() => {
              this.$refs.modalusers.Fix = false;
            }, 1000);
          }
        });
    },
    selectUsers(item) {
      this.address = item.userDefix;
      this.$refs.modalusers.ModalUsers = false;
      this.interval3 = setInterval(function () {
        this.ValidarAddress();
        clearTimeout(this.interval3);
      }.bind(this), 1000);
      
    },
  }
};
</script>

<style src="../Right/Right.scss" lang="scss"></style>
<style scoped>
.containerLineas {
  position: relative;
}
.containerLineas::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
}
</style>
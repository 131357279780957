<template>
  <section>
    <!-- modal crypto A -->
    <v-navigation-drawer
      id="modalQR"
      :class="{ fix: Fix }"
      v-model="ModalDosfa"
      :absolute="positionModal"
      :fixed="!positionModal"
      temporary
      overlay-color="black"
      overlay-opacity="0.5"
      color="#FFFFFF"
    >
      <v-col
        cols="10"
        class="containerLineas margintop"
        style="
          margin-inline: auto;
          padding-block: 15px;
          display: flex;
          justify-content: space-between
          align-items: center
        "
      >
        <span class="titulo h6-em">2Fa</span>
        <v-btn icon @click="ModalDosfa = false">
          <v-icon color="#6667AB" medium>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-col>

      <template>
        <v-col md="12" lg="10" style="margin-inline: auto" class="mt-5">
          <v-row class="row mt-5 mb-10">
            <v-col cols="12" md="12" class="divcol center" style="margin-inline: auto">
              <span class="titulo h6-em" style="text-align: center">{{ $t("Por favor ingrese su codigo 2Fa") }}</span>
            </v-col>
          </v-row>
          <v-card
            class="containerSwap2"
            style="position: relative; margin-bottom:.5em !important"
          >
            <span class="flotingText2 h8">{{ $t("Codigo") }}</span>
              <v-col class="pt-3">
                <div class="divrow center" style=" align-items: center; ">
                  <div id="app" style="background-color: #ffff">
                    <CodeInput ref='code' :loading="loading" class="input" v-on:complete="ejecutar" />
                  </div>
                </div>
              </v-col>
          </v-card>
        </v-col>
      </template>


    </v-navigation-drawer>

  </section>
</template>

<script>
import CodeInput from "vue-verification-code-input";

export default {
  name: "dosfa",
  components: {
    CodeInput,
  },
  //i18n: require("./i18n"),
  data() {
    return {
      Fix: false,
      ModalDosfa: false,
      accion: "",
      positionModal: true,
      messageError: false,
      loading: false,
    }
  },
  mounted() {
    if (window.innerWidth <= 880) {
      this.positionModal = false
    } else {
      this.positionModal = true
    }
  },
  methods: {
    onChange(){
      console.log(this.algo);
    },
    ejecutar(code) {
      switch (this.accion) {
        case "guardarPerfil": {
          this.loading = true;
          this.$parent.EjecutarguardarEmail(code);
          this.$refs.code.values = ['','','','','',''];
        }
          break;
        case "enviar": {
          this.loading = true;
          this.$parent.EjecutarEnviarCoin(code);
          this.$refs.code.values = ['','','','','',''];
        }
          break;
        case "swap": {
          this.loading = true;
          this.$parent.Realizarswap(code);
          this.$refs.code.values = ['','','','','',''];
        }
          break;
        case "desactivar-2fa": {
          this.loading = true;
          this.$parent.desactivar_2fa(code);
          this.$refs.code.values = ['','','','','',''];
        }
          break;
      }
    },
  },
};
</script>

<style src="../Right/Right.scss" lang="scss"></style>
<style scoped>
.containerLineas {
  position: relative;
}
.containerLineas::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
}
</style>

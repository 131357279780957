<template>
  <v-app class="layout-landing pa-6">
    <Header />
    <v-main class="content">
      <router-view ref="data" class="mb-16" />
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/C-layout/Header/Header";
import "./Layout.scss";
/*
--Uso del GSAP:

1-npm install gsap

2-
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

3-
mounted() {
  gsap.to(".animation1", {
    scrollTrigger: {
      trigger: ".animation1",
      endTrigger: ".animationSection1",
      start: "bottom 80%",
      end: "top 20%",
      toggleActions: "restart pause reverse"
    },
    x: -400,
    opacity: 0,
    rotate: 80,
    duration: 0.3
  });
}

--uso delvue-animate-onscroll:

1-

npm install vue-animate-onscroll

2-

import VueAnimateOnScroll from 'vue-animate-onscroll'

Vue.use(VueAnimateOnScroll)

3-

class="animStartHero"
v-animate-onscroll.repeat="'animHero'"

4- crear animaciones
*/
// let ubicacionPrincipal = window.pageYOffset;
export default {
  name: "Layout",
  components: { Header },
};
</script>

<style src="./Layout.scss" lang="scss" />

<template>
  <section>
    <!-- modal crypto C -->
    <v-navigation-drawer
      id="modalQR"
      :class="{ fix: Fix }"
      v-model="ModalCryptoC"
      absolute
      temporary
      overlay-color="black"
      overlay-opacity="0.5"
      color="#FFFFFF"
    >
      <v-col
        cols="10"
        class="containerLineas margintop"
        style="
          margin-inline: auto;
          padding-block: 15px;
          display: flex;
          justify-content: space-between
          align-items: center
        "
      >
        <span class="titulo h6-em">{{ $t("SelectCrypto") }}</span>
        <v-btn icon @click="ModalCryptoC = false">
          <v-icon color="#6667AB" medium>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="10" class="divcol margintop paddlateralheight" style="margin-inline: auto">
        <section class="cardCrypto">
          <v-card
            v-for="(item, index) in this.$parent.$parent.dataCartera"
            v-bind:key="index"
            color="transparent"
            style="margin-block: 12px; display: flex; justify-content: start"
          >
            <div class="containerTokens" @click="selectTokenC(item)">
              <img class="token" :src="item.token" alt="token" />
              <aside class="divcol" style="justify-content: center">
                <span class="desc h8-em">{{ item.title }}</span>
                <span class="h9-em descStyle">{{ item.desc }}</span>
              </aside>
            </div>
          </v-card>
        </section>
      </v-col>
    </v-navigation-drawer>

  </section>
</template>

<script>
export default {
  name: "ModalCrypto",
  i18n: require("./i18n"),
  data() {
    return {
      Fix: false,
      ModalCryptoC: false,
      positionModal: true,
    };
  },
  mounted() {
    if (window.innerWidth <= 880) {
      this.positionModal = false
    } else {
      this.positionModal = true
    }
  },
  methods: {
    selectTokenC(item) {
      this.$parent.selectTokenC(item);
    },
  },
};
</script>

<style src="../Right/Right.scss" lang="scss"></style>
<style scoped>
.containerLineas {
  position: relative;
}
.containerLineas::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
}
</style>

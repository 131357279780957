<template>
  <section id="market" class="containerCartas">
    <v-col cols="11" md="11" lg="10" style="justify-content: space-between" class="containerTitulo">
      <v-col class="paddvertical divcol" style="gap: 1em">
        <span class="titulo h6-em">{{ $t("Ptitulo") }}</span>
        <!--<span class="desc h7-em">Lorem ipsum</span>-->
        <!--<aside class="divcol center hoverDown verMobile">
          <v-btn id="containerBotones" class="marginright" @click="irHistorico()">
            <img class="botones" src="@/assets/app/enviar.png" alt="Historial Transacciones" />
          </v-btn>
            <span class="desc2 h8-em" style="cursor: pointer"
              @click="irHistorico()">Historial Transacciones</span>
        </aside>-->
      </v-col>
      <!--<div class="divrow center" v-if="user">
        <v-badge
          :content="messages"
          :value="messages"
          color="red"
          overlap
          bordered
        >
          <v-icon color="#6667AB" medium>mdi-bell</v-icon>
        </v-badge>
        <img class="user" src="@/assets/app/user.png" alt="token" />
      </div>--> 
    </v-col>
    
    <v-col cols="12">
       <v-col class="padd" style="position: relative">
        <Chart ref="chart"></Chart>
        <div class="center-absolute divisor"></div>
      </v-col>
    </v-col>
    
    <v-col
      md="11" lg="10"
      class="divrow"
      style="justify-content: space-between; margin-inline: auto"
    >
      <div class="divrow" style="gap: 1em">
        <aside class="divcol center hoverDown">
          <div class="divrow" style="gap: 10px">
              <img class="token" :src="monedaSeleccionadaImg" alt="Token" />
              <aside class="divcol" style="width: 100%; justify-content: center">
                <div style="display: flex; justify-content: space-between">
                  <span class="h7-em">{{ monedaSeleccionada }}</span>
                </div>
              </aside>
            </div>
        </aside>
      </div>
      <!--<aside class="divcol center hoverDown eliminarMobile">-->
        <!--<v-btn id="containerBotones" @click="irHistorico()">
          <img class="botones" src="@/assets/app/enviar.png" alt="Historial Transacciones" />
        </v-btn>-->
      <aside class="divcol center hoverDown">
        <span class="desc2 h7-em" style="cursor: pointer; float: right;"
            @click="irHistorico()">{{ $t("Phistorial") }}</span>
      </aside>
      <!--</aside>-->
    </v-col>
    <v-col cols="12">
      <v-card class="cartaInside cartaPortafolio" style="padding-top: 16px">
        <!--<v-col cols="11" lg="10" style="margin-inline: auto" class="divcol">
          <div class="divrow" style="justify-content: space-between; align-items: center">
            <span class="titulo h6-em paddtop paddbottom">Mercados</span>
            <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB"
            @click="volverAtras()">
              <v-icon medium>mdi-arrow-left-thin</v-icon>
            </v-btn>
          </div>
          <span class="desc h8-em">Ordenado por: Popularidad</span>
        </v-col>-->

        <v-col md="12" lg="10" class="cardHistorico cardH4" style="margin-inline: auto">
          <v-col
            v-for="(item, index) in dataPortafolio"
            v-bind:key="index"
            style="margin-inline: auto"
            class="divcol"
          >
            <div 
              class="hoverHistorico"
              @click="ChangeCoin(item.title)"
              style="cursor: pointer"
            >
              <div class="divrow" style="gap: 10px">
                <img class="token" :src="item.token" alt="token" />
                <aside class="divcol" style="width: 100%; justify-content: center">
                  <div style="display: flex; justify-content: space-between">
                    <span class="h7-em">{{ item.title }}</span>
                    <span class="h7-em">${{ item.price }}</span>
                  </div>
                  <div style="display: flex; justify-content: space-between">
                    <span class="descStyle h9-em">{{ item.desc }}</span>
                    <span
                      class="h9-em"
                      :class="{ colorGreen: item.colorG, colorRed: item.colorR }"
                      >{{ item.state }}</span
                    >
                  </div>
                </aside>
              </div>
            </div>
          </v-col>
        </v-col>
      </v-card>
    </v-col>
  </section>
</template>

<script>
import Chart from "@/pages/app/Left/Chart.vue"

export default {
  name: "Portafolio",
  i18n: require("./i18n"),
  components: {
    Chart
  },
  mounted() {
    if (window.innerWidth <= 880) {
      this.user = true
    }
  },
  data() {
    return {
      user: false,
      messages: 0,
      dataPortafolio: [],
      monedaSeleccionada: null,
      monedaSeleccionadaImg: null,
    };
  },
  methods: {
    ChangeCoin(item) {
      this.$refs.chart.crypto = item;
      this.$refs.chart.updateChart();
      this.monedaSeleccionada = item;
      this.monedaSeleccionadaImg = require("@/assets/app/token/" + this.monedaSeleccionada.toLowerCase() + ".svg")
    },
    irHistorico() {
      if (window.innerWidth <= 880) {
        this.$parent.Portafolio = false;
        this.$parent.Historico = true;
        /*this.$parent.Fiat = false;
        this.$parent.Cartera = false;
        this.$parent.Dapps = false;
        this.$parent.Perfil = false;*/
        //
        this.$parent.leftCard = false;
        this.$parent.centerCard = true;
        this.$parent.rightCard = false;
      } else {
        this.$parent.Portafolio = false;
        this.$parent.Historico = true;
        /*this.$parent.Fiat = false;
        this.$parent.Cartera = false;
        this.$parent.Dapps = false;
        this.$parent.Perfil = false;*/
      }
    },
    // volverAtras() {
    //   if (window.innerWidth <= 880) {
    //     this.$parent.leftCard = false;
    //     this.$parent.centerCard = true;
    //     this.$parent.rightCard = false;
    //   } else {
    //     this.$parent.Dapps = false;
    //     this.$parent.Perfil = false;
    //     this.$parent.Portafolio = false;
    //     this.$parent.Historico = false;
    //     this.$parent.Fiat = false;
    //   }
    // }
  }
};
</script>

<style src="./Left.scss" lang="scss"></style>

<template>
  <section class="home-app">
    <!-- <img id="logomobile" src="@/assets/app/logo/logo-defix.svg" alt="logo"> -->
    <!-- desktop -->
    <section id="leftCard" class="divcol cartas" v-show="leftCard">
      <Perfil v-show="Perfil" ref="perfil"></Perfil>
      <EditarPerfil v-show="EditarPerfil" ref="editarperfil"></EditarPerfil>
      <dosfa v-show="dosfa" ref="dosfa"></dosfa>
      <Dapps v-show="Dapps"></Dapps>
      <Fiat v-show="Fiat" ref="fiat" :balanceUSDT="balanceUSDT" @prueba="$event => balanceUSDT = $event"></Fiat>
      <!------------------------------------------------------------------------------------------------------------------------------------------------->
      <!--------------------------------------- INICIO CARTERA ------------------------------------------------------------------------------------------>
      <!------------------------------------------------------------------------------------------------------------------------------------------------->
      <Cartera v-show="Cartera" ref="cartera">
          <section class="containerCartas">
            <ModalQR ref="modalqr"></ModalQR>
            <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center" class="containerTitulo">
              <v-col class="paddvertical divcol">
                <span class="titulo h6-em">${{ balanceTotal }}</span>
                <span class="desc h7-em">{{ $t("CBalanceT") }}</span>
              </v-col>
              <div class="divrow center">
                <!--<v-badge
                  :content="messages"
                  :value="messages"
                  color="red"
                  overlap
                  bordered
                >
                  <v-icon color="#6667AB" medium>mdi-bell</v-icon>
                </v-badge> -->
                <!--<img class="user" src="@/assets/app/user.svg" alt="token" />-->
              </div>
            </v-col>

            <v-col
              md="11" lg="10"
              class="divrow"
              style="justify-content: space-between; margin-inline: auto"
            >
              <div class="divrow" style="gap: 1em">
                <aside class="divcol center hoverDown">
                  <div class="divrow" style="gap: 10px">
                      <img class="token" :src="monedaSeleccionadaImg" alt="Token" />
                      <aside class="divcol" style="width: 100%; justify-content: center">
                        <div style="display: flex; justify-content: space-between">
                          <span class="h7-em">{{ monedaSeleccionada }}</span>
                        </div>
                        <a :href="explorer" target="_blank">{{ $t('Explorador') }}</a>
                      </aside>
                    </div>
                </aside>
                
                <!--<aside class="divcol center hoverDown">
                <v-btn id="containerBotones" @click="irEnviar()">
                  <img class="botones" src="@/assets/app/enviar.svg" alt="Enviar" />
                </v-btn>
                  <span class="desc2 h8-em" style="cursor: pointer"
                    @click="irEnviar()">Enviar</span>
                </aside>
                <aside class="divcol center hoverDown">
                <v-btn id="containerBotones" @click="modalQR()">
                  <img class="botones" src="@/assets/app/recibir.svg" alt="Recibir" />
                </v-btn>
                  <span class="desc2 h8-em" style="cursor: pointer"
                    @click="modalQR()">Recibir</span>
                </aside>-->
              </div>
              <aside class="divcol center hoverDown">
                <v-btn id="containerBotones" @click="modalQR()" :ripple="false">
                  <img class="botones" src="@/assets/app/qr-3.svg" alt="QR" />
                </v-btn>
                <span class="desc2 h8-em" style="cursor: pointer"
                  @click="modalQR()">{{ $t("CRecibir") }} - QR</span>
              </aside>
            </v-col>

            <v-card color="#FFFFFF" class="cartaInside cartaCartera">
              <v-col md="11" lg="10" style="margin-inline: auto; padding-bottom: 0" class="divcol">
                <span class="titulo h6-em" style="line-height: 2ch !important">{{ $t("CCarteras") }}</span>
                <!--<span class="desc h8-em">Ordenado por: Saldo</span>-->
                <span class="titulo h8-em paddtop paddbottom"
                  >{{ $t("CCriptomonedas") }}</span
                >
              </v-col>

              <v-col md="12" lg="10" class="cardHistorico cardH2" style="margin-inline: auto">
                
                <v-col
                  v-for="(item, index) in dataCartera"
                  v-bind:key="index"
                  style="margin-inline: auto"
                  class="divcol"
                >
               
                  <div 
                    class="hoverHistorico"
                    @click="ChangeCoin(item.title)"
                    style="cursor: pointer"
                  >
                    <div class="divrow" style="gap: 10px">
                      <img class="token" :src="item.token" alt="Token" />
                      <aside class="divcol" style="width: 100%; justify-content: center">
                        <div style="display: flex; justify-content: space-between">
                          <span class="h7-em">{{ item.title }} {{ item.red }}</span>
                          <span class="h7-em">${{ item.amountDollar }}</span>
                        </div>
                        <div style="display: flex; justify-content: space-between">
                          <aside class="divrow" style="gap: 0.625rem">
                            <span id="h7-5" class="h9-em">{{ item.price }}</span>
                            <span
                              id="h7-5"
                              class="h9-em"
                              :class="{ colorGreen: item.colorG, colorRed: item.colorR }"
                              >{{ item.state }}</span
                            >
                          </aside>
                          <span id="h7-5" class="h9-em">{{ item.amountToken }} {{ item.title }}</span>
                        </div>
                      </aside>
                    </div>
                  </div>


                </v-col>
              </v-col>
            </v-card>
          </section>
      </Cartera>
      <!------------------------------------------------------------------------------------------------------------------------------------------------->
      <!------------------------------------------ FIN CARTERA ------------------------------------------------------------------------------------------>
      <!------------------------------------------------------------------------------------------------------------------------------------------------->
    </section>
    <section id="centerCard" class="divcol cartas" v-show="centerCard">
      <Portafolio v-show="Portafolio" ref="portafolio"></Portafolio>
      <Historico v-show="Historico" ref="historico"></Historico>
      <EstadoTransaccion v-show="EstadoTransaccion" ref="estadoTransaccion"></EstadoTransaccion>
    </section>
    <section id="rightCard" class="divcol cartas" v-show="rightCard">
      <span v-show="ExchangeCard">
        <Intercambio v-show="Exchange" ref="intercambio"></Intercambio>
        <Enviar v-show="!Exchange" ref="enviar"></Enviar>
      </span>
    </section>
  </section>
</template>

<script>
import Historico from "@/pages/app/Left/Historico.vue"
import Fiat from "@/pages/app/Left/Fiat.vue"
import EstadoTransaccion from "@/pages/app/Left/EstadoTransaccion.vue"
import Portafolio from "@/pages/app/Left/Portafolio.vue"
import Perfil from "@/pages/app/Left/Perfil.vue"
import EditarPerfil from "@/pages/app/Left/EditarPerfil.vue"
import dosfa from "@/pages/app/Left/2fa.vue"
import Dapps from "@/pages/app/Left/Dapps.vue"
//import Cartera from "@/pages/app/Center/Cartera.vue"
import Intercambio from "@/pages/app/Right/Intercambio.vue"
import Enviar from "@/pages/app/Right/Enviar.vue"

////////  cartera //////////////////////////////////
import ModalQR from "@/pages/app/Home/ModalQR.vue"



import axios from "axios";
    
export default {
  name: "Home",
  i18n: require("./i18n"),
  components: {
    Portafolio,
    Perfil,
    EditarPerfil,
    dosfa,
    Dapps,
    // Cartera,
    Intercambio,
    Enviar,
    ModalQR,
    Historico,
    Fiat,
    EstadoTransaccion,
  },
  data() {
    return {
      user: false,
      dataUser: [],
      Perfil: false,
      EditarPerfil: false,
      dosfa: false,
      Dapps: false,
      Portafolio: false,
      Historico: false,
      Fiat: false,
      EstadoTransaccion: false,
      Cartera: false,
      ExchangeCard: false,
      Exchange: false,
      leftCard: false,
      centerCard: false,
      rightCard: false,
      lang: [
        {
          title: "English",
          to: "#",
          value: "US",
        },
        {
          title: "Spanish",
          to: "#",
          value: "ES",
        },
      ],
      /* inicio data cartera */
      messages: 1,
      dataCartera: [
        {
          token: require("@/assets/app/token/btc.svg"),
          title: "BTC",
          desc: "Bitcoin",
          consulta: "Bitcoin",
          red: "(Bitcoin)",
          price: 0,
          state: "0%",
          amountDollar: 0,
          amountToken: 0,
          colorG: true,
          colorR: false,
          explorerMainnet: "https://www.blockchain.com/en/search?search=",
          explorerTestnet: "https://www.blockchain.com/btc-testnet/address/",
          explorerMainnetTransactions: "https://www.blockchain.com/es/btc/tx/",
          explorerTestnetTransactions: "https://www.blockchain.com/es/btc-testnet/tx/",
        },
        {
          token: require("@/assets/app/token/ethereum.svg"),
          title: "ETH",
          desc: "Ether",
          consulta: "ethereum",
          red: "(Ethereum)",
          price: 0,
          state: "0%",
          amountDollar: 0,
          amountToken: 0,
          colorG: true,
          colorR: false,
          explorerMainnet: "https://etherscan.io/address/",
          explorerTestnet: "https://goerli.etherscan.io/address/",
          explorerMainnetTransactions: "https://etherscan.io/tx/",
          explorerTestnetTransactions: "https://goerli.etherscan.io/tx/",
        },
        {
          token: require("@/assets/app/token/usdt.svg"),
          title: "USDT",
          desc: "Usdt",
          consulta: "tether",
          red: "(ERC20)",
          price: 0,
          state: "0%",
          amountDollar: 0,
          amountToken: 0,
          colorG: true,
          colorR: false,
          explorerMainnet: "https://etherscan.io/address/",
          explorerTestnet: "https://goerli.etherscan.io/address/",
          explorerMainnetTransactions: "https://etherscan.io/tx/",
          explorerTestnetTransactions: "https://goerli.etherscan.io/tx/",
        },
        {
          token: require("@/assets/app/token/usdc.svg"),
          title: "USDC",
          desc: "Usdc",
          consulta: "usd-coin",
          red: "(ERC20)",
          price: 0,
          state: "0%",
          amountDollar: 0,
          amountToken: 0,
          colorG: true,
          colorR: false,
          explorerMainnet: "https://etherscan.io/address/",
          explorerTestnet: "https://goerli.etherscan.io/address/",
          explorerMainnetTransactions: "https://etherscan.io/tx/",
          explorerTestnetTransactions: "https://goerli.etherscan.io/tx/",
        },
        {
          token: require("@/assets/app/token/dai.svg"),
          title: "DAI",
          desc: "Dai",
          consulta: "dai",
          red: "(ERC20)",
          price: 0,
          state: "0%",
          amountDollar: 0,
          amountToken: 0,
          colorG: true,
          colorR: false,
          explorerMainnet: "https://etherscan.io/address/",
          explorerTestnet: "https://goerli.etherscan.io/address/",
          explorerMainnetTransactions: "https://etherscan.io/tx/",
          explorerTestnetTransactions: "https://goerli.etherscan.io/tx/",
        },
        {
          token: require("@/assets/app/token/near.svg"),
          title: "NEAR",
          desc: "Near",
          consulta: "near",
          red: "(Near)",
          price: 0,
          state: "0%",
          amountDollar: 0,
          amountToken: 0,
          colorG: true,
          colorR: false,
          explorerMainnet: "https://explorer.near.org/accounts/",
          explorerTestnet: "https://explorer.testnet.near.org/accounts/",
          explorerMainnetTransactions: "https://explorer.near.org/transactions/",
          explorerTestnetTransactions: "https://explorer.testnet.near.org/transactions/",
        },
      ],
      explorer: null,
      price_now: {},
      balanceTotal: 0,
      monedaSeleccionada: null,
      monedaSeleccionadaImg: null,
      monedaSeleccionadaWallet: null,
      /* fin data cartera */

      balanceUSDT: undefined,
    }
  },
  mounted() {
    this.responsive();
    /* validacion inicio de session */
    if (localStorage.getItem('dataUser')) {
      this.dataUser = JSON.parse(localStorage.getItem('dataUser'));
      //console.log(this.dataUser);
    } else {
      this.$router.push('/')
    }
    /* inicio cartera */
    this.CargarCartera();
    this.CargarBalance();
    this.verificarSession();
    //this.$refs.historico.$refs.chart.updateChart();
    this.interval = setInterval(function () {
        if(Object.entries(this.dataUser).length > 0) {
          this.CargarCartera();
        }
    }.bind(this), 5000 );
    this.interval2 = setInterval(function () {
        if(Object.entries(this.dataUser).length > 0) {
          this.CargarBalance();
          this.$refs.portafolio.$refs.chart.updateChart();
        }
        //this.$refs.historico.$refs.chart.updateChart();
    }.bind(this), 1000 * 60);
    this.intervalValidSession = setInterval(function () {
        if(Object.entries(this.dataUser).length > 0) {
          this.verificarSession();
        }
        //this.$refs.historico.$refs.chart.updateChart();
    }.bind(this), 5000 * 60);
  },
  // unmounted() {
  //   window.removeEventListener('resize', this.getDimensions)
  // },
  methods: {
    // getDimensions() {
    //   this.responsive();
    // },
    async verificarSession() {
      return await axios
      .post(process.env.VUE_APP_API_BASE_URL + "get-close-all-sessions", {defixId: JSON.parse(localStorage.getItem('dataUser')).defixId})
      .then((response) => {
        if (response.data) {
          localStorage.clear();
          this.$router.push('/')
        }
        return response.data
      }).catch(() => {
        return true;
      });
    },
    responsive() {
      if (window.innerWidth <= 880) {
        this.user = true
        this.Cartera = true
        //
        this.leftCard = true;
        this.centerCard = false;
        this.rightCard = false;
      } else {
        this.Portafolio = true
        this.Cartera = true
        this.ExchangeCard = true
        //
        this.leftCard = true;
        this.centerCard = true;
        this.rightCard = true;
      }
    },
    /* inicio funciones cartera */
    modalQR() {
      this.$refs.modalqr.ModalQR = true
      this.$refs.modalqr.Fix = true
      setTimeout(()=> {
        window.scrollTo(0, 1);
      }, 0);
      setTimeout(() => {
        this.$refs.modalqr.Fix = false
      }, 1000);
    },
    CargarBalance(FormCargando) {
      this.balanceUSDT = 1
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "get-balance", {defixId: JSON.parse(localStorage.getItem('dataUser')).defixId})
        .then((response) => {
          if (response.data) {
            response.data.forEach(data => {
              let index = this.dataCartera.findIndex(item => item.title === data.coin);
              if (index >= 0) {
                const cartera = this.dataCartera[index];
                cartera.amountToken =  parseFloat(data.balance.toFixed(7));
                switch (process.env.VUE_APP_API_BASE_RED) {
                  case 'mainnet':
                  if (cartera.title == "USDT") {  this.balanceUSDT = cartera.amountToken;}  
                    break;
                  case 'testnet':
                  if (cartera.title == "ETH") {  this.balanceUSDT = cartera.amountToken;}  
                    break;
                }
                if (cartera.title != "USDT") { 
                  cartera.amountDollar = parseFloat((cartera.amountToken * cartera.price).toFixed(2));
                } else {
                  cartera.amountDollar = cartera.amountToken.toFixed(2);
                  console.log("<------------------------------")
                }
              }
            });
            let balance = 0
            this.dataCartera.forEach(data => {
              balance = balance + data.amountDollar;
            });
            this.balanceTotal = parseFloat(balance.toFixed(2));
            this.$refs.portafolio.dataPortafolio = this.dataCartera;
            this.CargarSwap();
          }
          if(FormCargando) {
            this.$refs.enviar.FormCargando = false;
          }
          //this.CargarHistorico(this.$refs.historico.dataCoinHistorico.title)
        })
        .catch(() => {
          //console.log(error)
          if(FormCargando) {
            this.$refs.enviar.FormCargando = false;
          }
        });
    },
    /*CargarCartera() {
      this.balanceTotal = 0;
      if(Object.entries(this.$refs.enviar.dataCoin).length === 0) { 
        this.$refs.enviar.dataCoin = this.dataCartera[0];
      }
      
      for(var i=0; i < this.dataCartera.length; i++) {
        let data = this.dataCartera[i]
        if (data.title != "USDT") { 
          axios.get("https://api.binance.com/api/v3/ticker/24hr?symbol=" + data.title + "USDT")
          .then((response) => {
            let colorG = true;
            let colorR = false;
            if (parseFloat(response.data.priceChangePercent) < 0) {
              colorG = false;
              colorR = true;
            }
            data.price = parseFloat(parseFloat(response.data.lastPrice).toFixed(2));
            data.state = data.state = (parseFloat(response.data.priceChangePercent)).toFixed(1) + "%";
            data.colorG = colorG;
            data.colorR = colorR;
            if (data.title != "USDT") { 
              data.amountDollar = parseFloat((data.amountToken * data.price).toFixed(2));
            } else {
              data.amountDollar = data.amountToken;
            }
            this.CargarSwap();
            // actualizar precio en enviar
            if(this.$refs.enviar.amount != null) {
              if(this.$refs.enviar.amount.length > 0) {
                if(isNaN(this.$refs.enviar.amount) === false) {
                  if(data.title === this.$refs.enviar.dataCoin.title) {
                    this.$refs.enviar.montoDivisa = (data.price * this.$refs.enviar.amount).toFixed(2)
                  }
                }
              }
            }
          })
          .catch((response) => {
            this.price_now = response;
          });
        } 
      }
      /*this.dataCartera.forEach(data => {
        if (data.title != "USDT") { 
          axios.get("https://api.binance.com/api/v3/ticker/24hr?symbol=" + data.title + "USDT")
          .then((response) => {
            let colorG = true;
            let colorR = false;
            if (parseFloat(response.data.priceChangePercent) < 0) {
              colorG = false;
              colorR = true;
            }
            data.price = parseFloat(parseFloat(response.data.lastPrice).toFixed(2));
            data.state = data.state = response.data.priceChangePercent + "%";
            data.colorG = colorG;
            data.colorR = colorR;
            if (data.title != "USDT") { 
              data.amountDollar = parseFloat((data.amountToken * data.price).toFixed(2));
            } else {
              data.amountDollar = data.amountToken;
            }
            this.CargarSwap();
          })
          .catch((response) => {
            this.price_now = response;
          });
        } 
      });* /   ////////////////////////////
      
      

      let balance = 0
      this.dataCartera.forEach(data => {
        balance = balance + data.amountDollar;
      });
      //console.log(this.$refs.historico.coin);
      this.balanceTotal = parseFloat(balance.toFixed(2));
      if(this.monedaSeleccionada === null) {
        this.$refs.enviar.ConsultarFee(this.dataCartera[0].title);
        this.$refs.portafolio.$refs.chart.crypto = this.dataCartera[0].title;
        this.$refs.portafolio.$refs.chart.updateChart();
        let data = this.dataUser[this.dataCartera[0].title.toLowerCase() + '_credentials']
        if(data.address) {
          this.monedaSeleccionadaWallet = this.$refs.modalqr.wallet = data.address;
          this.$refs.modalqr.token = this.dataCartera[0].title;
          switch (process.env.VUE_APP_API_BASE_RED) {
            case 'mainnet':
              this.explorer = this.dataCartera[0].explorerMainnet + data.address;    
              break;
            case 'testnet':
              this.explorer = this.dataCartera[0].explorerTestnet + data.address;   
              break;
          }
        } else {
          this.$refs.modalqr.wallet = null;
          this.$refs.modalqr.token = null;
        }
        this.monedaSeleccionada = this.$refs.portafolio.monedaSeleccionada = this.$refs.modalqr.token;
        this.monedaSeleccionadaImg = this.$refs.portafolio.monedaSeleccionadaImg = require("@/assets/app/token/" + this.monedaSeleccionada.toLowerCase() + ".svg");
      }
      this.$refs.portafolio.dataPortafolio = this.dataCartera;
      
    },*/
    CargarCartera() {
      this.balanceTotal = 0;
      if(Object.entries(this.$refs.enviar.dataCoin).length === 0) { 
        this.$refs.enviar.dataCoin = this.dataCartera[0];
      }
      
      for(var i=0; i < this.dataCartera.length; i++) {
        let data = this.dataCartera[i]
        //if (data.title != "USDT") { 
          axios.get("https://api.coingecko.com/api/v3/simple/price?ids=" + data.consulta.toLowerCase() + "&vs_currencies=usd&include_24hr_change=true")
          .then((response) => {
            let colorG = true;
            let colorR = false;
            if (parseFloat(response.data[data.consulta.toLowerCase()].usd_24h_change) < 0) {
              colorG = false;
              colorR = true;
            }
            data.price = parseFloat(parseFloat(response.data[data.consulta.toLowerCase()].usd).toFixed(2));
            data.state = data.state = (parseFloat(response.data[data.consulta.toLowerCase()].usd_24h_change)).toFixed(1) + "%";
            data.colorG = colorG;
            data.colorR = colorR;
            if (data.title != "USDT") {
              data.amountDollar = parseFloat((data.amountToken * data.price).toFixed(2));
            } else {
              data.amountDollar = data.amountToken.toFixed(2);
            }
            this.CargarSwap();
            // actualizar precio en enviar
            if(this.$refs.enviar.amount != null) {
              if(this.$refs.enviar.amount.length > 0) {
                if(isNaN(this.$refs.enviar.amount) === false) {
                  if(data.title === this.$refs.enviar.dataCoin.title) {
                    this.$refs.enviar.montoDivisa = (data.price * this.$refs.enviar.amount).toFixed(2)
                  }
                }
              }
            }
          })
          .catch((response) => {
            this.price_now = response;
          });
        //} 
      }
      let balance = 0
      for(let i = 0; i < this.dataCartera.length; i++){
        balance = parseFloat(balance) + parseFloat(this.dataCartera[i].amountDollar);
      }
      /*this.dataCartera.forEach(data => {
        balance = balance + data.amountDollar;
      });*/
      //console.log(this.$refs.historico.coin);
      this.balanceTotal = balance.toFixed(2);
      if(this.monedaSeleccionada === null) {
        this.$refs.enviar.ConsultarFeeDefix(this.dataCartera[0].title);
        this.$refs.portafolio.$refs.chart.crypto = this.dataCartera[0].title;
        this.$refs.portafolio.$refs.chart.updateChart();
        let data = this.dataUser[this.dataCartera[0].title.toLowerCase() + '_credentials']
        if(data.address) {
          this.monedaSeleccionadaWallet = this.$refs.modalqr.wallet = data.address;
          this.$refs.modalqr.token = this.dataCartera[0].title;
          switch (process.env.VUE_APP_API_BASE_RED) {
            case 'mainnet':
              this.explorer = this.dataCartera[0].explorerMainnet + data.address;    
              break;
            case 'testnet':
              this.explorer = this.dataCartera[0].explorerTestnet + data.address;   
              break;
          }
        } else {
          this.$refs.modalqr.wallet = null;
          this.$refs.modalqr.token = null;
        }
        this.monedaSeleccionada = this.$refs.portafolio.monedaSeleccionada = this.$refs.modalqr.token;
        this.monedaSeleccionadaImg = this.$refs.portafolio.monedaSeleccionadaImg = require("@/assets/app/token/" + this.monedaSeleccionada.toLowerCase() + ".svg");
      }
      this.$refs.portafolio.dataPortafolio = this.dataCartera;
      
    },
    ChangeCoin(title) {
      let index = this.dataCartera.findIndex(item => item.title === title);
      this.$refs.historico.dataCoinHistorico = this.dataCartera[index];
      //this.CargarSwap(title);
      //this.CargarHistorico(title)
      //this.$refs.enviar.dataCoin = this.dataCartera[index];
      try {
        let data = this.dataUser[title.toLowerCase() + '_credentials']
        if(data.address) {
          this.monedaSeleccionadaWallet = this.$refs.modalqr.wallet = data.address;
          this.$refs.modalqr.token = title;
          switch (process.env.VUE_APP_API_BASE_RED) {
            case 'mainnet':
              this.explorer = this.dataCartera[index].explorerMainnet + data.address;    
              break;
            case 'testnet':
              this.explorer = this.dataCartera[index].explorerTestnet + data.address;
              break;
          }
        } else {
          this.$refs.modalqr.wallet = null;
          this.$refs.modalqr.token = null;
        }  
      } catch (error) {
        this.$refs.modalqr.wallet = null;
        this.$refs.modalqr.token = null;
      }
      this.monedaSeleccionada = this.$refs.modalqr.token;
      this.monedaSeleccionadaImg = require("@/assets/app/token/" + this.monedaSeleccionada.toLowerCase() + ".svg");
      this.Dapps = false;
      this.Historico = false;
      this.Fiat = false;
      this.Perfil = false;
      this.Portafolio = true;
    },
    /* fin funciones cartera */
    CargarSwap() {
      let index1 = null;
      let index2 = null;
      if(this.$refs.intercambio.dataSwap[0].balance === null) {
        index1 = this.dataCartera.findIndex(item => item.title === "ETH");
        index2 = this.dataCartera.findIndex(item => item.title === "USDT");
      } else {
        index1 = this.dataCartera.findIndex(item => item.title === this.$refs.intercambio.dataSwap[0].desc);
        index2 = this.dataCartera.findIndex(item => item.title === this.$refs.intercambio.dataSwap[1].desc);
      }
      
      this.$refs.intercambio.dataSwap = [
        {
          token: this.dataCartera[index1].token,
          desc: this.dataCartera[index1].title,
          balance: "Balance " + this.dataCartera[index1].amountToken + " " + this.dataCartera[index1].title,
          price: this.dataCartera[index1].price,
          pricePriview: parseFloat(this.$refs.intercambio.dataSwap[0].input) * parseFloat(this.dataCartera[index1].price) > 0 ? "~$ " + (parseFloat(this.$refs.intercambio.dataSwap[0].input) * parseFloat(this.dataCartera[index1].price)) : this.$refs.intercambio.dataSwap[0].input == null ? "~$ " : "~$ " + this.$refs.intercambio.dataSwap[0].input,
          flechita: true,
          input: this.$refs.intercambio.dataSwap[0].input,
          inputP: 0,
        },
        {
          token: this.dataCartera[index2].token,
          desc: this.dataCartera[index2].title,
          balance: "Balance " + this.dataCartera[index2].amountToken + " " + this.dataCartera[index2].title,
          price: this.dataCartera[index2].price,
          pricePriview: parseFloat(this.$refs.intercambio.dataSwap[1].input) * parseFloat(this.dataCartera[index2].price) > 0 ? "~$ " + (parseFloat(this.$refs.intercambio.dataSwap[1].input) * parseFloat(this.dataCartera[index2].price)) : this.$refs.intercambio.dataSwap[1].input == null ? "~$ " : "~$ " + this.$refs.intercambio.dataSwap[1].input,
          flechita: true,
          input: this.$refs.intercambio.dataSwap[1].input,
          inputP: 1,
        },
      ];
    },
  }
};
</script>

<style src="./Home.scss" lang="scss" />
<style src="./Center.scss" lang="scss" />

<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)
  >.v-card__text
    color: #000
</style>
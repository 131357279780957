<template>
  <v-col class="paddingMobile">
    <v-row id="hero">
      <v-col class="deletepadding340">
        <v-row>
          <v-col class="deletepadding340">
            <v-col class="container-titleHero">
              <span class="h2-em titleHero alinearPreMobile">
                {{ $t('titleHeader1') }}
                <br>
                {{ $t('titleHeader2') }}
              </span>
            </v-col>
            <v-col cols="12" sm="8" md="7" lg="6" xl="6" class="centrarPreMobile">
              <p class="h7-em textoHero alinearPreMobile p">
                {{ $t('textHeader') }}
              </p>
            </v-col>
            <v-col class="alinearPreMobile quitarpaddbottom divrow gap" style="flex-wrap:wrap">
              <v-btn id="botonHero" class="h6-em center alinearPreMobile" @click="dialogSignIn=true"
                style="background:none !important;box-shadow:none !important;color:#333333 !important">{{ $t('bm6') }}</v-btn>
              <v-btn id="botonHero" class="h6-em center alinearPreMobile" @click="dialog=true">{{ $t('bhero') }}</v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-col>


      <!-- dialogo import Wallet-->
      <v-dialog
        id="eliminarDialogo"
        v-model="dialogSignIn"
        overlay-opacity="0.6"
        overlay-color="black"
        :max-width="responsive"
        max-height="80%"
        persistent
      >
        <v-card style="border-radius: 30px !important" class="modalWallet">
          <v-card-title class="text-h5">
            <span id="tituloContainerWallet" class="text-h4" style="color: #6667AB !important">
              {{ $t("connect_signin") }}
            </span>
            <v-btn
              id="colorCloseWallet"
              style="top: 10px; right: 10px; position: absolute"
              icon
              :disabled="FormCargando"
              @click="closeDialogSignIn()"
            >
              <v-icon medium>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <v-stepper v-model="e2">
              <v-stepper-header>
                <v-stepper-step :complete="e2 > 1" step="1">
                  {{ $t("accept") }}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2">
                  {{ $t("phrase") }}
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="mb-12">
                    <aside class="containerChangeWallet">
                      <p>
                        {{ $t("importAccount") }}
                      </p>
                    </aside>
                    <aside class="mb-12">
                      <v-select
                        v-model="select"
                        :items="acountFrom"
                        chips
                        :label="$t('importAccount')"
                        @change="changeAccount()"
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            :key="JSON.stringify(data.item)"
                            :selected="data.selected"
                            :disabled="data.disabled"
                            class="v-chip--select-multi"
                            @click.stop="data.parent.selectedIndex = data.index"
                            @input="data.parent.selectItem(data.item)"
                          >
                            <v-avatar class="accent white--text">
                              {{ data.item.slice(0, 1).toUpperCase() }}
                            </v-avatar>
                            {{ data.item }}
                          </v-chip>
                        </template>
                      </v-select>
                    </aside>
                  </v-card>

                  <v-btn id="buttonStyle" @click="validateTypeAccount()">
                    {{ $t("continue") }}
                  </v-btn>

                  <v-btn
                    id="buttonStyleCancel"
                    class="ml-3"
                    text
                    @click="closeDialogSignIn()"
                  >
                    {{ $t("close") }}
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-card class="mb-12">
                    <aside
                      class="containerChangeWallet"
                      style="cursor: pointer"
                    >
                      <p
                        v-show="accountNear"
                        id="textoWallet"
                        class="p text-h7"
                      >
                        {{ $t("idnear") }}
                      </p>
                      <p
                        v-show="accountDefix" 
                        id="textoWallet"
                        class="p text-h7"
                      >
                        {{ $t("writePhrase") }}
                      </p>
                    </aside>
                    <div class="pt-3">
                      <v-text-field
                        v-show="accountNear"
                        v-model="nearId"
                        :rules="Rules"
                        :error-messages="importWalletNearIdError"
                        label="Id"
                        outlined
                        required
                        hint="Example.defix3"
                        solo
                      ></v-text-field>
                    </div>
                    <div class="pt-3">
                      <v-text-field
                        v-show="accountNear"
                        v-model="nearIdPhrase"
                        :rules="Rules3"
                        :error-messages="importWalletPhraseError"
                        :label="$t('writePhrase')"
                        outlined
                        required
                        solo
                      ></v-text-field>
                      <v-text-field
                        v-show="accountDefix"
                        v-model="defixIdPhrase"
                        :rules="Rules3"
                        :label="$t('writePhrase')"
                        :error-messages="importWalletError"
                        outlined
                        required
                        solo
                      ></v-text-field>
                    </div>
                  </v-card>

                  <v-btn
                    id="buttonStyle"
                    :disabled="FormCargando"
                    @click="walletImport()"
                  >
                    {{ $t("continue") }}
                    <span v-if="FormCargando">
                      <v-progress-circular
                        :indeterminate="FormCargando"
                        :value="0"
                        size="24"
                      ></v-progress-circular>
                    </span>
                  </v-btn>

                  <v-btn
                    id="buttonStyleCancel"
                    class="ml-3"
                    text
                    :disabled="FormCargando"
                    @click="closeDialogSignIn()"
                  >
                    {{ $t("close") }}
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-container>
        </v-card>
      </v-dialog>


      <!-- dialogo Wallet-->
      <v-dialog
        id="eliminarDialogo"
        v-model="dialog"
        overlay-opacity="0.6"
        overlay-color="black"
        :max-width="responsive"
        max-height="100%"
        persistent

      >
        <v-card class="createWallet" style="border-radius: 30px !important">
          <v-card-title class="text-h5">
            <span id="tituloContainerWallet" class="text-h4" style="color: #6667AB !important">
              {{ $t("connect") }}
            </span>
            <v-btn
              icon
              :disabled="FormCargando"
              @click="closeDialog()"
              id="colorCloseWallet"
              style="top: 10px; right: 10px; position: absolute"
            >
              <v-icon medium>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1">
                  {{ $t("accept") }}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" step="2">
                  {{ $t("idAccount") }}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 3" step="3">
                  {{ $t("phrase") }}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="4">
                  {{ $t("verifyphrase") }}
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="mb-12">
                    <aside
                      class="containerChangeWallet"
                    >
                      <p id="textoWallet" class="p text-h7">
                        {{ $t("savePhrase") }}
                      </p>
                    </aside>
                  </v-card>

                  <v-btn id="buttonStyle" @click="e1 = 2">
                    {{ $t("continue") }}
                  </v-btn>

                  <v-btn
                    id="buttonStyleCancel"
                    class="ml-3"
                    text
                    @click="closeDialog()"
                  >
                    {{ $t("close") }}
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-form
                    ref="formCreateWallet"
                    v-model="validCreateWallet"
                    lazy-validation
                  >
                    <v-card>
                      <aside
                        class="containerChangeWallet pt-10"
                      >
                        <p id="textoWallet" class="p text-h7">
                          {{ $t("idnear") }}
                        </p>
                      </aside>
                      <div class="pb-10">
                        <v-text-field
                          v-model="defixId"
                          :rules="Rules"
                          :color="ColorValidate"
                          :messages="MessageValidate"
                          :error-messages="defixIdError"
                          class="text-green"
                          :label="$t('idAccount')"
                          @keyup="ValidateNearId(defixId)"
                          outlined
                          required
                          :hint="defixIdHin"
                          solo
                        ></v-text-field>
                      </div>
                    </v-card>
                  </v-form>

                  <v-btn
                    id="buttonStyle"
                    :disabled="FormCargando"
                    @click="generateMnemonic()"
                  >
                    {{ $t("continue") }}
                    <span v-if="FormCargando">
                      <v-progress-circular
                        :indeterminate="FormCargando"
                        :value="0"
                        size="24"
                      ></v-progress-circular>
                    </span>
                  </v-btn>

                  <!--<v-btn
                    id="buttonStyleCancel"
                    class="ml-3"
                    text
                    :disabled="FormCargando"
                    @click="closeDialog()"
                  >
                    {{ $t("close") }}
                  </v-btn>-->
                </v-stepper-content>

                <v-stepper-content step="3" id="containerSlide">
                  <v-card class="mb-6">
                    <div class="row">
                      <div class="col-12">
                        <aside
                          class="containerChangeWallet"
                          style="cursor: pointer"
                        >
                          <p id="textoWallet" class="p text-h7">
                            {{ $t("warningPhrase") }}
                          </p>
                        </aside>
                      </div>
                      <div class="col-12 textoWallet text-h8"><strong>{{ $t("id_usuario") }}:</strong> {{ defixId }}.defix3 </div>
                      <div class="col-3"></div>
                      <div class="col-6">
                        <aside>
                          <v-chip-group active-class="primary--text" column>
                            <v-chip
                              id="bigChip"
                              v-for="(tag, i) in phrase1"
                              :key="i"
                            >
                              {{ i + 1 }}. {{ tag }}
                            </v-chip>
                          </v-chip-group>
                        </aside>
                      </div>
                    </div>
                    <v-row>
                      <v-col cols="3"></v-col>
                      <v-col cols="6">
                        <v-btn
                          id="buttonStyle"
                          style="font-size: 15px"
                          @click="copyToClipBoard()"
                        >
                          <v-icon>mdi-content-copy</v-icon>
                          {{ $t("copy") }}
                        </v-btn>
                        <p v-if="copyClipBoardError" class="p text-h8" style="color:red">{{ $t("copyClipBoardError") }}</p>
                      </v-col>
                    </v-row>
                      
                    <div class="row">
                      <div class="col-3"></div>
                      <div class="col-6">
                        <v-checkbox
                          v-model="EnvioCorreo"
                          :label="$t('EnvioCorreoOpcional')"
                          color="#6667AB"
                          :value="true"
                          hide-details
                        ></v-checkbox>
                        <v-form
                          ref="formEnvioCorreo"
                          v-model="validCreateWallet"
                          v-if="EnvioCorreo"
                          lazy-validation
                        >
                          <v-card class="mb-4">
                            <div class="pt-2">
                              <v-text-field
                                v-model="defixEmail"
                                :rules="email"
                                :label="$t('correo')"
                                outlined
                                required
                                solo
                                hint="Example@email.com"
                              ></v-text-field>
                            </div>
                          </v-card>
                        </v-form>
                      </div>
                    </div>
                  </v-card>
                  <v-row>
                    <v-col cols="6">
                      <v-btn
                        id="buttonStyle"
                        :disabled="FormCargando"
                        @click="ValidatecopyToClipBoard()"
                      >
                        {{ $t("continue") }}
                        <span v-if="FormCargando">
                          <v-progress-circular
                            :indeterminate="FormCargando"
                            :value="0"
                            size="24"
                          ></v-progress-circular>
                        </span>
                      </v-btn>
                      <v-btn
                        id="buttonStyleCancel"
                        class="ml-3"
                        text
                        :disabled="FormCargando"
                        @click="e1 = 2"
                      >
                        {{ $t("Back") }}
                      </v-btn>
                      <!--<v-btn
                        id="buttonStyleCancel"
                        class="ml-3"
                        text
                        :disabled="FormCargando"
                        @click="closeDialog()"
                      >
                        {{ $t("close") }}
                      </v-btn>-->
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content step="4" id="containerSlide">
                  <v-card class="mb-6">
                    <div class="row">
        
                      <div class="col-3"></div>
                      <div class="col-6">
                        <aside>
                          <p id="textoWallet" class="p text-h5 text-center mt-4 mb-4" style="color: #6667AB !important">
                            {{ $t("verifyphrase") }}
                          </p>
                          <v-card>
                            <aside
                              class="containerChangeWallet"
                            >
                              <p id="textoWallet" class="p text-h7 mb-4">
                                {{ $t("warningverifyphrase") }}
                              </p>
                            </aside>
                            <aside
                              class="containerChangeWallet"
                            >
                              <p id="textoWallet" class="p text-h7" style="font-weight: bold !important">
                                {{ $t("word") }} {{ word + 1 }}
                              </p>
                            </aside>
                            <div class="pt-2">
                              <v-text-field
                                v-model="wordInput"
                                :rules="Rules"
                                :error-messages="wordInputError"
                                class="text-green"
                                :label="$t('word') + (word + 1)"
                                outlined
                                required
                                :hint="$t('word') + (word + 1)"
                                solo
                              ></v-text-field>
                            </div>
                            <!--<aside
                              class="containerChangeWallet"
                            >
                              <p class="text-h6 text-center mb-5">
                                <v-btn
                                  class="hypervinculo"
                                  text
                                  @click="e1 = 3"
                                >
                                  {{ $t("Back") }}
                                </v-btn>
                              </p>
                            </aside>-->
                          </v-card>
                        
                        </aside>
                      </div>
                    </div>
                  </v-card>
                  <v-row>
                    <v-col cols="6">
                      <v-btn
                        id="buttonStyle"
                        :disabled="FormCargando"
                        @click="CulminarRegistro(phrase)"
                      >
                        {{ $t("continue") }}
                        <span v-if="FormCargando">
                          <v-progress-circular
                            :indeterminate="FormCargando"
                            :value="0"
                            size="24"
                          ></v-progress-circular>
                        </span>
                      </v-btn>

                      <v-btn
                        id="buttonStyleCancel"
                        class="ml-3"
                        text
                        :disabled="FormCargando"
                        @click="e1 = 3"
                      >
                        {{ $t("Back") }}
                      </v-btn>

                      <!--<v-btn
                        id="buttonStyleCancel"
                        class="ml-3"
                        text
                        :disabled="FormCargando"
                        @click="closeDialog()"
                      >
                        {{ $t("close") }}
                      </v-btn>-->
                    </v-col>
                  </v-row>
                </v-stepper-content>

              </v-stepper-items>
            </v-stepper>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
export default {
  name: "Hero",
  i18n: require("./i18n"),
  data() {
    return {
      responsive: "60%",
      drawer: null,
      validCreateWallet: true,
      ColorValidate: "",
      MessageValidate: "",
      data: [],
      e1: 1,
      e2: 1,
      dialog: false,
      dialogSignIn: false,
      FormCargando: false,
      Rules: [
        v => !!v || this.$t("rule"),
      ],
      Rules2: [
        v => !!v || this.$t("rule2"),
      ],
      Rules3: [
        v => !!v || this.$t("rule3"),
      ],
      email: [
        v => !!v || this.$t("ruleEmail1"),
        v => /.+@.+\..+/.test(v) || this.$t("ruleEmail2"),
      ],
      lang: [
        {
          title: "English",
          to: "#",
          value: "US",
        },
        {
          title: "Spanish",
          to: "#",
          value: "ES",
        },
      ],
      defixId: null,
      nearidAvalible: false,
      defixIdError: false,
      defixIdHin: "Example.defix3",
      defixEmail: "",
      EnvioCorreo: null,
      phrase: "",
      phrase1: "",
      copyClipBoard: false,
      copyClipBoardError: false,
      word: null,
      wordInput: "",
      wordInputError: false,
      langList: [
        { key: "ES", name: "Español", alias: "Español" },
        { key: "US", name: "English", alias: "English" },
      ],
      nearId: "",
      accountNear: false,
      accountDefix: false,
      acountFrom: [
        'deFix3',
       // 'NEAR',
        'Metamask'
      ],
      select: '',
      defixIdPhrase: '',
      nearIdPhrase: '',
      importWalletError: false,
      importWalletNearIdError: false,
      importWalletPhraseError: false,
      entorno: process.env.VUE_APP_API_BASE_RED,
    };
  },
  mounted() {
    window.addEventListener('resize', this.Resposive)
    this.Resposive();
  },
  methods: {
    walletImport() {
      if(this.accountNear) {
        this.importWalletNearIdError = false;
        this.importWalletPhraseError = false;
        if (this.nearId.length <= 0 || this.nearIdPhrase.length <= 0) {
          if (this.nearId.length <= 0) {
            this.importWalletNearIdError = this.$t("rule");
          }
          if (this.nearIdPhrase.length <= 0) {
            this.importWalletPhraseError = this.$t("rule3");
          }
        } else {
          if(this.select === "NEAR") {
            console.log("NEAR")
            //this.importWalletNear()
          } else {
            this.importAccountMetamask(this.nearId, this.nearIdPhrase)
          }
        }
      }
      if (this.accountDefix) {
        this.importWalletError = false;
        if (this.defixIdPhrase.length <= 0) {
          this.FormCargando = false;
          this.importWalletError = this.$t("rule3");
        } else {
          this.importWalletDefix(this.defixIdPhrase);
        }
      } else {
        this.FormCargando = false;
      }
    },
    importAccountMetamask(defixId, phrase) {
      defixId = defixId.split(".defix3").join(".defix3");
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "import-from-metamask", {defixId: defixId ,mnemonic: phrase})
        //.post("https://defix3.com:3070/api/v1/import-from-metamask", {defixId: defixId ,mnemonic: phrase})
        .then((response) => {
          if(response.data) {
            localStorage.setItem('dataUser', JSON.stringify(response.data))
            // console.log(JSON.parse(localStorage.getItem('dataUser')))
            this.$router.push('/app')
          }
          this.FormCargando = false;
        })
        .catch((error) => {
          this.FormCargando = false;
          console.log(error);
        });
    },
    closeDialogSignIn() {
      this.dialogSignIn = false;
      this.defixIdPhrase = "";
      this.nearIdPhrase = "";
      this.e2 = 1;
      this.nearId = "";
      this.defixId = "";
      this.accountNear = false
      this.accountDefix = false
      this.select = ''
      this.importWalletNearIdError = false;
      this.importWalletPhraseError = false;
    },
    validateTypeAccount() {
      if(this.select.length > 0) {
        this.e2 = 2;
      }
    },

    changeAccount () {
      if (this.select === 'deFix3') {
        this.accountNear = false
        this.accountDefix = true
      } else {
        this.accountNear = true
        this.accountDefix = false
      }
    },
    Resposive() {
      if (window.innerWidth <= 880) {
        this.responsive = "80%"
      } else {
        this.responsive = "60%"
      }
    },
    generateMnemonic() {
      if (this.$refs.formCreateWallet.validate()) {
        this.FormCargando = true;
        let defixId = { defixId: this.defixId + ".defix3" };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "generate-mnemonic", defixId)
          .then((response) => {
            if(response.data.respuesta == "ok") {
              this.phrase = response.data.mnemonic;
              this.phrase1 = response.data.mnemonic.split(" ");
              this.e1 = 3;
            } else if (response.data.respuesta == "user") {
              this.defixIdError =  this.$t("defixIdError");
            }
            this.FormCargando = false;
          })
          .catch((error) => {
            console.log(error);
            this.FormCargando = false;
          });
        }
    },
    CulminarRegistro(phrase) {
      this.FormCargando = true;
      if (this.wordInput.trim() === this.phrase1[this.word]) {
        this.wordInputError = false;
        this.createWallet(phrase);
      } else {
        this.FormCargando = false;
        this.wordInputError = this.$t("wordInputError");
      }
      if (this.wordInput.length <= 0) {
        this.FormCargando = false;
        this.wordInputError = this.$t("rule");
      }
    },
    createWallet(phrase) {
      if (this.$refs.formCreateWallet.validate()) {
        this.FormCargando = true;
        let email = null;
        if(this.EnvioCorreo) {
          email = this.defixEmail;  
        }
        let defixId = { 
          defixId: this.defixId + ".defix3",
          mnemonic: phrase,
          email: email
        };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "create-wallet", defixId)
          .then((response) => {
            if(response.status === 200) {
              localStorage.removeItem('dataUser');
              let data = response.data;
              data.mnemonic = phrase;
              localStorage.setItem('dataUser', JSON.stringify(data))
              this.$router.push('/app')
              /*this.phrase = response.data.mnemonic;
              this.phrase1 = response.data.mnemonic.split(" ");
              this.e1 = 3;*/
            } else if (response.status === 204) {
              this.defixIdError =  this.$t("defixIdError");
            }
            this.FormCargando = false;
          })
          .catch((error) => {
            console.log(error);
            this.FormCargando = false;
          });
        }
    },
    ValidateNearId(nearId) {
      this.defixId = this.defixId.split(" ").join("");
      let defixId = nearId.split(" ").join("") + ".defix3";

      axios
        .post(process.env.VUE_APP_API_BASE_URL + "validate-defix3", { defixId: defixId})
        .then((response) => {
          console.log(response.data);
          if (this.defixId.length <= 0) {
            this.defixIdHin = "Example.defix3";
            this.MessageValidate = this.$t("rule");
          } else {
            if(response.data == true) {
              this.nearidAvalible = false;
              this.defixIdHin = defixId;
              this.defixIdError = this.$t("defixIdError");
            } else {
              this.nearidAvalible = true;
              this.defixIdHin = defixId;
              this.MessageValidate = defixId;
              this.ColorValidate = "success";
              this.defixIdError = false;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      if (this.defixId.length <= 0) {
        this.defixIdHin = "Example.defix3";
        this.MessageValidate = this.$t("rule");
      }
    },
    copyToClipBoard() {
      navigator.clipboard
        .writeText(this.phrase)
        .then(() => {
          this.copyClipBoard = true;
          this.copyClipBoardError = false;
        })
        /*.catch((err) => {
          console.log("Something went wrong", err);
        });*/
    },
    ValidatecopyToClipBoard () {
      let next1 = false;
      let next2 = false;
      let next3 = false;
      if(this.EnvioCorreo) {
        if(this.$refs.formEnvioCorreo.validate()) {
          next1 = true;
        } else {
          next1 = false;
        }
      }
      
      if (this.copyClipBoard) {
        next2 = true;
      } else {
        next2 = false;
        this.copyClipBoardError = true;
      }

      if(this.EnvioCorreo && next1 && next2) {
        next3 = true;
      } else if(this.EnvioCorreo == null && next2) {
        next3 = true;
      }

      if(next3) {
        let min = 0;
        let max = 11;
        this.word = Math.floor((Math.random() * (max - min + 1)) + min);
        this.e1 = 4;
      }
    },
    importWalletDefix(phrase) {
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "import-wallet", {mnemonic: phrase})
        .then((response) => {
          if(response.data) {
            localStorage.setItem('dataUser', JSON.stringify(response.data))
            // console.log(JSON.parse(localStorage.getItem('dataUser')))
            this.$router.push('/app')
          }
          this.FormCargando = false;
        })
        .catch((error) => {
          this.FormCargando = false;
          console.log(error);
        });
    },
    closeDialog() {
      this.dialog = false;
      this.phrase = "";
      this.phrase1 = "";
      this.e1 = 1;
      this.nearId = "example.defix3";
      this.defixId = "example";
      this.accountNear = false
      this.accountDefix = false
      this.defixIdHin = "Exampe.defix3";
      this.MessageValidate = false;
      this.ColorValidate = "";
      this.defixIdError = false;
      this.copyClipBoard = false,
      this.word = null,
      this.$refs.formCreateWallet.resetValidation()
    },
  },
};
</script>

<style src="./Hero.scss" lang="scss"></style>
<style>
  .hypervinculo {
    text-decoration: none;
    color:rgb(114, 114, 122);
  }
  .hypervinculo:hover {
    color:#6667AB;
    text-decoration: none;
  }
    #buttonStyle:hover {
    color:#6667AB;
    text-decoration: none;
  }
    #buttonStyleCancel:hover {
    color:#6667AB;
    text-decoration: none;
  }
</style>

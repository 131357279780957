<template>
  <v-navigation-drawer
    id="modalQR2"
    :class="{fix: Fix}"
    v-model="ModalQR"
    :absolute="positionModal"
    :fixed="!positionModal"
    temporary
    overlay-color="black"
    overlay-opacity="0.5"
    color="#FFFFFF"
  >
    <v-col cols="10" class="containerLineas margintop paddlateralheight" style="margin-inline: auto; padding-block: 15px">
      <span class="titulo h6-em">{{ $t("CRecibir") }} - QR</span>
    </v-col>
    <v-col cols="12" md="11" class="divcol center margintop" style="margin-inline: auto">
      <span class="h8-em">Token: {{ token }}</span>
      
      <vueLogoQrcode
        ref="qrcode"
        v-if="wallet != null"
        :content="wallet"
        :width="200"
        :autoGen="true"
        :logoSrc="require('@/assets/app/logo/logo-defix.svg')"
        style="margin-block: 12px"
      ></vueLogoQrcode>

      <span class="h8-em" style="text-align: center">{{ $t("Cmodaltexto") }}</span>
      <v-card class="containerModal center">
        <v-btn id="botonCopiar" class="h9-em" @click="copyToClipboard()">
          <span>{{ $t("CCopiar") }}</span>
        </v-btn>
        <span class="h8-em">{{ wallet }}</span>
      </v-card>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
// import VueQr from 'vue-qr'
import vueLogoQrcode from '@njshaoshao/vue-logo-qrcode';
export default {
  name: "ModalQR",
  i18n: require("./i18n"),
  components: {
    // VueQr
    vueLogoQrcode 
  },
  data() {
    return {
      Fix: false,
      ModalQR: false,
      wallet: null,
      token: null,
      positionModal: true
    };
  },
  mounted() {
    if (window.innerWidth <= 880) {
      this.positionModal = false
    } else {
      this.positionModal = true
    }
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.wallet);
    },
  },
};
</script>

<style src="./Center.scss" lang="scss"></style>
<style scoped>
.containerLineas {
  position: relative;
}
.containerLineas::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
}
</style>
<template>
  <v-app class="layout-app pa-6">
    <Header ref="header" />
    <v-main class="content">
      <v-col v-show="entorno == 'testnet'" cols="12" class="pl-12 mb-3" style="background-color: #6667AB; color: #FFFF; font-weight: bold; height: 0.6%; text-align: center;">
        {{ $t('Testnet only') }}
      </v-col>
      <Home ref="home"></Home>
    </v-main>
    <Footer class="eliminarMobile" />
  </v-app>
</template>

<script>
import Header from "@/components/C-app/Header/Header";
import Footer from "@/components/C-app/Footer/Footer";
import Home from "@/pages/app/Home/Home.vue"
import "./Layout.scss";
/*
--Uso del GSAP:

1-npm install gsap

2-
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

3-
mounted() {
  gsap.to(".animation1", {
    scrollTrigger: {
      trigger: ".animation1",
      endTrigger: ".animationSection1",
      start: "bottom 80%",
      end: "top 20%",
      toggleActions: "restart pause reverse"
    },
    x: -400,
    opacity: 0,
    rotate: 80,
    duration: 0.3
  });
}

--uso delvue-animate-onscroll:

1-

npm install vue-animate-onscroll

2-

import VueAnimateOnScroll from 'vue-animate-onscroll'

Vue.use(VueAnimateOnScroll)

3-

class="animStartHero"
v-animate-onscroll.repeat="'animHero'"

4- crear animaciones
*/
export default {
  name: "Layout",
  i18n: require("./i18n"),
  components: { Footer, Header, Home },
  data () {
    return {
      entorno: process.env.VUE_APP_API_BASE_RED,
    }
  },
  // mounted() {
    // window.addEventListener('resize', () => {if (window.innerWidth >= 860 && window.innerWidth <= 900) {this.$router.go()}})
    // window.addEventListener('resize', this.$store.commit("SwitchNavBtn"))
  // },
}
</script>

<style src="./Layout.scss" lang="scss" />

<template>
  <section id="editarPerfil" class="containerCartas">
    <Alert ref="alert"></Alert>
    <ModalDosFa ref="modaldosfa"></ModalDosFa>
    <ModalNotificaciones ref="modalnotificaciones"></ModalNotificaciones>
    <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center" class="containerTitulo">
      <v-col class="paddvertical divcol">
        <span class="titulo h6-em">{{ $t("MiPerfil") }}</span>
        <span class="desc h7-em">{{ $parent.dataUser.defixId }}</span>
      </v-col>

      
      <!-- <div class="center"> -->
        <!--<v-badge
          :content="messages"
          :value="messages"
          color="red"
          overlap
          bordered
        >
          <v-icon color="#6667AB" medium>mdi-bell</v-icon>
        </v-badge>-->
        <!--<img class="user" src="@/assets/app/user.png" alt="token" />-->
      <!-- </div> -->
    </v-col>

    <v-card class="cartaInside cartaIE">
      <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
        <div class="divrow" style="justify-content: space-between; align-items: center">
          <span class="titulo h7-em paddtop paddbottom">{{ $t("Datos") }}</span>
          <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB"
            @click="volverAtras()">
            <v-icon medium>mdi-arrow-left-thin</v-icon>
          </v-btn>
        </div>
        <!--<span class="desc h8-em">{{ this.$parent.dataUser.defixId }}</span>-->
      </v-col>

      <v-col md="12" lg="10" style="margin-inline: auto">
        <!--<v-text-field
          v-model="editedItemPerfil.nombre"
          type="text"
          background-color="transparent"
          solo flat
          class="containerDatosPerfil"
          :style="`--floatingText: '${$t('Nombre')}'`"
          :class="{menosmargin: false}"
        >
          <template v-slot:label>
            <span class="h8-em">{{$t('Nombre')}}</span>
          </template>
        </v-text-field>

        <v-text-field
          v-model="editedItemPerfil.apellido"
          type="text"
          background-color="transparent"
          solo flat
          class="containerDatosPerfil"
          :style="`--floatingText: '${$t('Apellido')}'`"
          :class="{menosmargin: false}"
        >
          <template v-slot:label>
            <span class="h8-em">{{$t('Apellido')}}</span>
          </template>
        </v-text-field>

        <v-text-field
          v-model="editedItemPerfil.documento"
          type="text"
          background-color="transparent"
          solo flat
          class="containerDatosPerfil ID"
          :style="`--floatingText: '${$t('Documento Identidad')}'`"
          :class="{menosmargin: false}"
        >
          <template v-slot:prepend-inner>
            <v-select
              v-model="editedItemPerfil.TypeDocument"
              :items="itemsTypeDocument"
              hide-details
              solo flat chips
            ></v-select>
          </template>

          <template v-slot:label>
            <span class="h8-em">v-00000000 / j-0000000</span>
          </template>
        </v-text-field>-->

        <v-text-field
          v-model="editedItem.email"
          type="text"
          background-color="transparent"
          solo flat required
          :rules="[rules.email]"
          class="containerDatosPerfil"
          :style="`--floatingText: '${$t('Correo')}'`"
          :class="{menosmargin: false}"
        >
          <template v-slot:label>
            <span class="h8-em">{{ $t('EjemploCorreo') }}</span>
          </template>

          <template v-slot:append>
            <span id="botonEditar" class="h9-em">
              <v-icon medium color="#ffff">mdi-email</v-icon>
            </span>
          </template>
        </v-text-field>

        <v-btn
        elevation="2"
        x-small
        class="mt-5 mr-5"
        :loading="loading"
        style="background-color: #6667AB; color: #FFFF; font-weight: bold; float:right;
          font-size:12.5px !important; border-radius: 30px; padding: .8em; text-transform: none !important"
        @click="OpenNotificaciones()"
        >
          <span style="margin: 0">{{$t("Notificaciones")}}</span>
        </v-btn>

        <aside class="botonFinal">
          <v-btn
            class="botonSwap h7-em"
            :loading="FormCargando"
            :disabled="FormCargando"
            @click="guardarEmail()"
          >
            {{ $t("Guardar") }}
          </v-btn>
        </aside>
      </v-col>
    </v-card>
  </section>
</template>

<script>
import Alert from "@/pages/app/Alert/Alert.vue"
import ModalDosFa from "@/pages/app/ModalDosFa/ModalDosFa.vue"
import ModalNotificaciones from "@/pages/app/Left/ModalNotificaciones.vue"
import axios from "axios";

export default {
  name: "Perfil",
  i18n: require("./i18n"),
   components: {
    Alert,
    ModalDosFa,
    ModalNotificaciones,
  },
  data() {
    return {
      messages: 0,
      hideDetails: true,
      margen: true,
      email: null,
      editedItemPerfil: {TypeDocument: 'v'},
      editedItem: {email: null},
      messageError: null,
      FormCargando: false,
      loading: false,
      itemsTypeDocument: ['v', 'j'],
      TypeDocument: 'v',
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t("EmailValido")
        },
      },
    }
  },
  methods: {
    OpenNotificaciones() {
      this.$refs.modalnotificaciones.Fix = true;
      this.$refs.modalnotificaciones.Notificaciones = true;
      setTimeout(() => {
        this.$refs.modalnotificaciones.Fix = false;
      }, 1000);
    },
    OpenModalDosFa(accion) {
      this.$refs.modaldosfa.ModalDosfa = true;
      this.$refs.modaldosfa.Fix = true;
      this.$refs.modaldosfa.accion = accion;
      this.$refs.modaldosfa.code = null;
      setTimeout(() => {
        this.$refs.modaldosfa.Fix = false;
      }, 1000);
    },
    guardarEmail() {
      //let rul_requid = !!this.email || this.$t("EmailRequerido");
      let rul_format = false;
      if(this.editedItem.email == null || this.editedItem.email.trim() == '') {
        this.editedItem = {email: null};
      } else {
        rul_format = /.+@.+\..+/.test(this.editedItem.email) || this.$t("EmailValido");
      }
      //let valid_document = true;
      //if(this.editedItemPerfil.documento.length == 0) this.editedItemPerfil.documento = null;
      /*if(this.editedItemPerfil.documento != null && this.editedItemPerfil.TypeDocument == null) {
        valid_document = false;
        this.$refs.alert.alertWarning = true;
        this.$refs.alert.Titulo = this.$t("Advertencia");
        this.$refs.alert.Alerta = this.$t("debe seleccionar un tipo de documento !!!");

      }*/
      //if(rul_requid == true && rul_format == true) {
      //if((rul_format == true || this.editedItem.email == null) && valid_document == true) {
      if(rul_format == true || this.editedItem.email == null) {
        this.FormCargando = true;
        axios.post(process.env.VUE_APP_API_BASE_URL + "status-2fa", {defixId: this.$parent.dataUser.defixId})
          .then((response) => {
            switch (response.data) {
              case true: {
                this.FormCargando = false;
                this.OpenModalDosFa("guardarPerfil");
              }
                break;
              case false: this.EjecutarguardarEmail();
                break;
              default: {
                this.$refs.alert.alertCancelled = true;
                this.$refs.alert.Titulo = this.$t("Error");
                this.$refs.alert.Alerta = this.$t("Ocurrio un error inesperado por favor vuelva a intentarlo mas tarde !!!");
                this.FormCargando = false;
              }
                break;
            }
          })
      } else {
        //if(rul_requid == true) {
        this.messageError = rul_format;
        /*} else {
          this.messageError = rul_requid;
        }*/
      }
    },
    EjecutarguardarEmail(code) {
      let data = {
        defixId: this.$parent.dataUser.defixId,
        mnemonic: this.$parent.dataUser.mnemonic,
        name: this.editedItemPerfil.nombre ? this.editedItemPerfil.nombre : null,
        last_name: this.editedItemPerfil.apellido ? this.editedItemPerfil.apellido : null,
        legal_document: this.editedItemPerfil.documento ? this.editedItemPerfil.documento : null,
        type_document: this.editedItemPerfil.documento ? this.editedItemPerfil.TypeDocument : null,
        email: this.editedItem.email,
        flag_send: this.editedItem.flag_send ? true : false,
        flag_receive: this.editedItem.flag_receive ? true : false,
        flag_dex: this.editedItem.flag_dex ? true : false,
        flag_fiat: this.editedItem.flag_fiat ? true : false,
        code: code ? code : null,
      }
      this.messageError = null
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "set-email-data", data)
        .then((response) => {
          switch (response.data.respuesta) {
            case "ok": {
              this.cargarPerfil();
              this.$refs.alert.alertSuccess = true;
              this.$refs.alert.Titulo = this.$t("alertTitulo");
              this.$refs.alert.Alerta = this.$t("alertAlerta");
              this.$refs.modaldosfa.ModalDosfa = false;
            }
              break;
            case "code": {
              this.$refs.alert.alertWarning = true;
              this.$refs.alert.Titulo = this.$t("Advertencia");
              this.$refs.alert.Alerta = this.$t("Su codigo 2fa no es valido");
            }
              break;
          } 
          this.FormCargando = false;
          this.$refs.modaldosfa.loading = false;
        })
        .catch(() => {
          this.$refs.alert.alertCancelled = true;
          this.$refs.alert.Titulo = this.$t("alertTitulo2");
          this.$refs.alert.Alerta = this.$t("alertAlerta2");
          this.FormCargando = false;
          this.$refs.modaldosfa.loading = false;
        });       
    },
    cargarPerfil() {
      return axios
        .post(process.env.VUE_APP_API_BASE_URL + "get-email-data", {defixId: JSON.parse(localStorage.getItem('dataUser')).defixId})
        .then((response) => {
          if (response.data) {
            let type_document = 'v';
            if(type_document != null) {
              type_document = response.data.type_document
            }
            this.editedItem.email = this.$parent.$refs.perfil.dataItems.email = response.data.email == null ? "" : response.data.email;
            localStorage.email = this.editedItem.email
            this.editedItemPerfil.nombre = this.$parent.$refs.perfil.dataItems.nombre = response.data.name == null ? "" : response.data.name,
            this.editedItemPerfil.apellido = this.$parent.$refs.perfil.dataItems.apellido = response.data.last_name == null ? "" : response.data.last_name,
            this.$parent.$refs.perfil.dataItems.documento = response.data.legal_document == null ? "" : response.data.type_document + '-' + response.data.legal_document,
            this.editedItemPerfil.documento = response.data.legal_document,
            this.editedItemPerfil.TypeDocument = type_document,
            this.editedItem.flag_send = this.$parent.$refs.perfil.editedItem.flag_send = response.data.flag_send;
            this.editedItem.flag_receive = this.$parent.$refs.perfil.editedItem.flag_receive = response.data.flag_receive;
            this.editedItem.flag_dex = this.$parent.$refs.perfil.editedItem.flag_dex = response.data.flag_dex;
            this.editedItem.flag_fiat = this.$parent.$refs.perfil.editedItem.flag_fiat = response.data.flag_fiat;
            this.$parent.$refs.perfil.dosfa = response.data.dosfa;
           
            this.$parent.$refs.fiat.emaillogin.email  = this.editedItem.email



            return response;
          }
        })
        .catch((error) => {
          console.log(error);
          this.email = null;
          this.editedItem = {};
          return error;
        });
    },
    
    clearCard() {
      this.$parent.Dapps = false;
      this.$parent.Historico = false;
      this.$parent.Fiat = false;
      this.$parent.EstadoTransaccion = false;
      this.$parent.Perfil = false;
      this.$parent.EditarPerfil = false;
      this.$parent.Cartera = false;
      this.$parent.Portafolio = false;
      this.$parent.ExchangeCard = false;
    },
    volverAtras() {
      this.clearCard()
      if (window.innerWidth <= 880) {
        this.$store.commit("ClearNavigation")
        this.$parent.Perfil = true;
        //
        this.$parent.leftCard = true;
        this.$parent.centerCard = false;
        this.$parent.rightCard = false;
      } else {
        this.$parent.Perfil = true;
        //
        this.$parent.leftCard = true;
        this.$parent.centerCard = false;
        this.$parent.rightCard = false;
      }
    },
   
  }
};
</script>

<style src="./Left.scss" lang="scss"></style>
<style scoped>
.containerLineas {
  position: relative;
  padding-block: 20px;
}
.containerLineas::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
}
.containerLineas::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
}
</style>
  <template>
  <div id="chart">
    <div class="toolbar">
      <button
        id="one_day"
        @click="updateData('one_day')"
        :class="{ activeButton: selection === 'one_day' }"
      >
        <span class="h8-em">
          1 {{ $t("Pdia") }}
        </span>
      </button>

      <button
        id="one_week"
        @click="updateData('one_week')"
        :class="{ activeButton: selection === 'one_week' }"
      >
        <span class="h8-em">
          1 {{ $t("PSemana") }}
        </span>
      </button>

      <button
        id="one_month"
        @click="updateData('one_month')"
        :class="{ activeButton: selection === 'one_month' }"
      >
        <span class="h8-em">
          1 {{ $t("PMes") }}
        </span>
      </button>

      <button
        id="one_year"
        @click="updateData('one_year')"
        :class="{ activeButton: selection === 'one_year' }"
      >
        <span class="h8-em">
          1 {{ $t("PAno") }}
        </span>
      </button>
    </div>
    <div id="chart-timeline">
      <apexchart
        id="area-datetime"
        width="100%"
        height="150px"
        type="area"
        ref="chart" 
        :options="options"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Chart",
  i18n: require("./i18n"),
  data() {
    return {
      options: {
        /*chart: {
          id: 'basic-bar'
        },*/
        grid: {
          show: false,
        },
        colors: ["#6667AB", "#147AD6"],
        chart: {
          stacked: false,
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: "zoom",
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
          colors: "#147AD6",
          hover: {
            size: 8,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.8,
            opacityTo: 0.5,
            //stops: [0, 90, 100],
          },
        },
        tooltip: {
          marker: {
            show: false,
          },
          shared: false,
          /*y: {
            formatter: function (val) {
              return (val / 1000000).toFixed(0);
            },
          },*/
        },
        xaxis: {
          type: "datetime",
          // min: Math.floor(new Date("01 Jan 2022").getTime()/1000.0),
          labels: {
            show: false,
          },
          categories: []
        },
        yaxis: {
          labels: {
            show: false,
            /*formatter: function (val) {
              return (val / 1000000).toFixed(0);
            },*/
          },
        },
      },
      series: [{
        name: 'USD',
        data: []
      }],
      selection: "one_day",
      crypto: "",
    };
  },
  mounted() {
    //this.updateChart();
  },
  methods: {
    updateChart() {
      let fecha = new Date();
      let to = Math.floor(fecha.getTime()/1000.0)
      let limit = 0;
      let period = "";
      switch (this.selection) {
        case "one_year": {
            fecha.setDate(fecha.getDate() - 365)
            limit = 365;
            period = "D1";
          }
          break;
        case "one_month": {
            fecha.setDate(fecha.getDate() - 30)
            limit = 720;
            period = "H1";
          }
          break;
        case "one_week": {
            fecha.setDate(fecha.getDate() - 7)
            limit = 192;
            period = "H1";
          }
          break;
        case "one_day": {
            fecha.setDate(fecha.getDate() - 1)
            limit = 96;
            period = "M15";
          }
          break;
      }
      fecha.setDate(fecha.getDate() - 2)
      let from = Math.floor(fecha.getTime()/1000.0)

      let url = "https://ticker-api.cointelegraph.com/historical/?pairs="+this.crypto+"-USD&markets=Index&period="+period+"&limit="+limit+"&time_from="+from+"&time_till="+to+"&data_type=candle&from="+from+"&instruments="+this.crypto+"USD&to="+to+"&sentryTag=charts";
      //let url = "https://ticker-api.cointelegraph.com/historical/?pairs=BTC-USD&markets=Index,Bitfinex,Bitstamp,Coinbase,Hitbtc,Kraken&period=D1&limit=2&time_from=1621373288&time_till=1652932799&data_type=candle&from=1621373288&instruments=BTCUSD&to=1652932799&sentryTag=charts";
      //let url = "https://ticker-api.cointelegraph.com/history/BTC/USD/?coin=BTC&currency=USD&market=Index&period=D1&limit=367&time_from=1621224000&time_till=1652932799&sentryTag=charts";
      // un ano
      //let url = "https://ticker-api.cointelegraph.com/historical/?pairs=BTC-USD&markets=Index&period=D1&limit=365&time_from=1621432611&time_till=1653019199&data_type=candle&from=1621432611&instruments=BTCUSD&to=1653019199&sentryTag=charts";
      // un mes
      //let url = "https://ticker-api.cointelegraph.com/historical/?pairs=BTC-USD&markets=Index&period=H1&limit=720&time_from=1650376481&time_till=1653019199&data_type=candle&from=1650376481&instruments=BTCUSD&to=1653019199&sentryTag=charts";
      // una semana
      //let url = "https://ticker-api.cointelegraph.com/historical/?pairs=BTC-USD&markets=Index&period=H1&limit=192&time_from=1652363005&time_till=1653019199&data_type=candle&instruments=BTCUSD&from=1652363005&to=1653019199&sentryTag=charts";
      // un dia
      //let url = "https://ticker-api.cointelegraph.com/historical/?pairs=BTC-USD&markets=Index&period=M15&limit=96&time_from=1652881979&time_till=1653019199&data_type=candle&from=1652881979&instruments=BTCUSD&to=1653019199&sentryTag=charts";
      
      this.options = {
                xaxis: {
                  categories: []
                }
              };
      this.options.xaxis.categories = [];
            
      axios.get(url)
          .then((response) => {
            if(response.data) {
              const categories = [];
              const data = [];
              response.data.data[this.crypto].USD.INDEX.forEach(item => {
              //response.data.data.forEach(item => {
                categories.push(item.t*1000);
                data.push(item.p);
              });   
              //console.log(Math.floor(new Date("01/01/2022 00:00:00").getTime()/1000.0));
              //console.log(new Date(1553617238*1000));
              // In the same way, update the series option
              this.series = [{
                data: data
              }];
              this.options = {
                xaxis: {
                  categories: categories
                }
              },
              this.options.xaxis.categories = categories
            }
          })
          /*.catch((response) => {
            console.log(response)
          })*/;
    },
    updateData: function (timeline) {
      this.selection = timeline;
      this.updateChart();
    },
  },
};
</script>

<style src="./Left.scss" lang="scss"></style>
